import AvatarComponent from 'components/avatar'
import BackComponent from 'components/back'
import ButtonMobileComponent from 'components/button-mobile'
import HorizontalScrollContainerComponent from 'components/horizontal-scroll-container'
import { ClearIcon, SearchIcon } from 'components/icons'
import InputMobileComponent from 'components/input-mobile'
import WizardComponent from 'components/wizard'
import React, { useEffect, useState } from 'react'
import { RoomType, UserRoomsOutputProps } from 'types/RoomTypes'
import { isWeb, titleCaseSchoolNameForUI } from 'utils/constants'

// Mock Data Types
interface RoomJoinComponentProps {
  rooms: UserRoomsOutputProps[]
  communities: Record<string, { studentId: string; isNeedPMPool: boolean }>
  userId: string
  onAddRoomUsers: (id: string, users: string[]) => Promise<void>
}

interface CardComponentProps {
  avatar?: JSX.Element
  title: string
  subtitle?: string
  body?: string
  data_testid?: string
  footer?: JSX.Element
}

const CardComponent = (props: CardComponentProps): JSX.Element => {
  const { avatar, title, subtitle, body, footer, data_testid } = props ?? {}

  return (
    <div
      className={`flex flex-col items-start gap-3 my-4 self-stretch lg:p-2 lg:border lg:rounded-xl`}
      data-testid={data_testid}
    >
      <div className='flex justify-between items-center self-stretch'>
        <div className='flex items-center gap-2 flex-1'>
          {avatar}
          <div className='flex flex-col'>
            <div className='flex items-center gap-1 max-w-[60vw]'>
              <span className='max-w-[40vw] w-auto text-[#474747] text-sm font-medium overflow-hidden whitespace-nowrap text-ellipsis'>
                {title}
              </span>
              {subtitle && (
                <>
                  <span className='w-[1px] bg-gray-300 h-[20px]' />
                  {/* <div className='text-[12px] bg-light-yellow py-1 px-2 rounded-xl'>{subtitle}</div> */}
                  <span className='flex-1 text-[#797979] text-xs font-medium overflow-hidden whitespace-nowrap text-ellipsis'>
                    {subtitle}
                  </span>
                </>
              )}
            </div>
            <span className='text-[#797979] text-xs font-regular max-w-[60vw] overflow-hidden whitespace-nowrap text-ellipsis'>
              {body}
            </span>
          </div>
        </div>
        {footer}
      </div>
    </div>
  )
}

const RoomJoinComponent: React.FC<RoomJoinComponentProps> = ({ rooms, communities, userId, onAddRoomUsers }) => {
  const filters: string[] = ['All', ...Object.keys(communities)]
  const isShowCommunityName: boolean = Object.keys(communities).length > 1

  // State for selected school and room type filter
  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const [selectedFilter, setSelectedFilter] = useState<string>(filters[0])
  const [filteredRooms, setFilteredRooms] = useState<CardComponentProps[]>([])

  // hooks
  useEffect(() => {
    const cards: CardComponentProps[] = [],
      disabledCards: CardComponentProps[] = []
    rooms.forEach((v: UserRoomsOutputProps) => {
      const {
        roomType,
        roomId,
        roomName,
        roomDescription,
        roomMemberCount,
        roomCommunityId,
        roomCommunityActivity,
        userIsMember,
      } = v
      const isKeywordFound: boolean =
        !searchKeyword ||
        [roomName, roomDescription, roomCommunityId, roomCommunityActivity].some(
          (field) => field && field.toLowerCase().includes(searchKeyword.toLowerCase()),
        )
      const isRoomTypeFound: boolean = roomType === RoomType.PUBLIC || roomType === RoomType.ACTIVITY
      if (!userIsMember && isKeywordFound && isRoomTypeFound && roomCommunityId) {
        const isCommunityTypeFound: boolean = selectedFilter === 'All' || selectedFilter === roomCommunityId
        if (isCommunityTypeFound) {
          const { isNeedPMPool } = communities[roomCommunityId]
          const getJoinNowButton = (isDisabled?: boolean): JSX.Element => (
            <ButtonMobileComponent
              data_testid='join-testid'
              text='Join'
              width='w-[50px]'
              padx='px-[12px]'
              height='h-[30px]'
              className='cursor-pointer'
              disabled={isDisabled}
              onClick={() => onAddRoomUsers(roomId, [userId])}
            />
          )
          if (roomType === RoomType.ACTIVITY && !isNeedPMPool) {
            // activity rooms need pm pool enabled
            disabledCards.push({
              avatar: <AvatarComponent name={roomName} />,
              title: roomName,
              subtitle: roomCommunityId && isShowCommunityName ? titleCaseSchoolNameForUI(roomCommunityId) : undefined,
              body: `${roomMemberCount} members (Set afternoon carpool to join)`,
              footer: getJoinNowButton(true),
            })
          } else {
            cards.push({
              avatar: <AvatarComponent name={roomName} />,
              title: roomName,
              subtitle: roomCommunityId && isShowCommunityName ? titleCaseSchoolNameForUI(roomCommunityId) : undefined,
              body: `${roomMemberCount} members`,
              footer: getJoinNowButton(),
            })
          }
        }
      }
    })
    setFilteredRooms([
      ...cards.sort((a, b) => a.title.localeCompare(b.title)),
      ...disabledCards.sort((a, b) => a.title.localeCompare(b.title)),
    ])
  }, [userId, communities, rooms, isShowCommunityName, searchKeyword, selectedFilter, onAddRoomUsers])

  // body
  const body: JSX.Element = (
    <div className={`flex flex-col flex-1 self-stretch gap-2 ${isWeb ? '' : 'px-5'}`}>
      {/***************** Search Bar *****************/}
      <InputMobileComponent
        type='text'
        placeholder='Search public groups to join...'
        data_testid='input-testid'
        value={searchKeyword ?? ''}
        onChange={(v: string) => setSearchKeyword(v)}
        rightElement={searchKeyword ? <ClearIcon onClick={() => setSearchKeyword('')} /> : <SearchIcon />}
      />
      {/***************** Room Type Selector *****************/}
      {filters.length > 2 && (
        <HorizontalScrollContainerComponent className='my-2'>
          {filters.map((v: string, i: number) => (
            <span
              key={i}
              onClick={() => setSelectedFilter(v)}
              className={`text-xs rounded-xl px-2 py-1 cursor-pointer ${
                selectedFilter === v
                  ? 'bg-custom-yellow text-gray-800'
                  : 'bg-light-yellow border-custom-yellow border text-gray-800'
              }`}
            >
              {titleCaseSchoolNameForUI(v)}
            </span>
          ))}
        </HorizontalScrollContainerComponent>
      )}
      {/* **************** Room List **************** */}
      {filteredRooms.length > 0 ? (
        filteredRooms.map((room: CardComponentProps, index: number) => (
          <CardComponent
            data_testid='card-testid'
            key={index}
            avatar={room.avatar}
            title={room.title}
            subtitle={room.subtitle}
            body={room.body}
            footer={room.footer}
          />
        ))
      ) : (
        <div className='text-center text-gray-400'>
          <p className='text-[18px] font-medium'>No groups are currently available for you to join.</p>
        </div>
      )}
    </div>
  )

  // Filtered Rooms
  return (
    <>
      {/***************** Mobile *****************/}
      {!isWeb && (
        <BackComponent text='Join Public Group'>
          {/* **************** Body **************** */}
          {body}
        </BackComponent>
      )}
      {/***************** Web *****************/}
      {isWeb && <WizardComponent title='Join Public Group'>{body}</WizardComponent>}
    </>
  )
}

export default RoomJoinComponent
