import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import useDeviceSize, { DeviceSize } from 'hooks/useDeviceSize'
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import './index.css'

interface PieChartComponentProps {
  piechartType?: 'pie' | 'donut'
  title?: string
  subtitle?: string
  showToolbar?: boolean
  labels: string[]
  series: number[]
  colors?: string[]
  columnSpans?: Record<DeviceSize, number>
  legendPosition?: 'top' | 'right' | 'bottom' | 'left'
  showCard?: boolean
}

/**
 * PieChartComponent: Renders a pie chart using ReactApexChart.
 * - Responsive layout: Full width on mobile/tablet, half on desktop.
 * - Configurable chart type ('pie' or 'donut'), defaulting to 'pie' on mobile and 'donut' on larger screens.
 * - Configurable column span, defaulting to full width on small screens and half on larger screens.
 * - Configurable legend position, defaulting to 'bottom' on charts with less width and 'right' on wider charts.
 * - Configurable Card inclusion via `showCard` prop.
 * - Hardcoded height (FIXME: make responsive).
 */
const PieChartComponent: React.FC<PieChartComponentProps> = ({
  piechartType,
  title,
  subtitle,
  showToolbar = false,
  labels,
  series,
  colors,
  columnSpans,
  legendPosition,
  showCard = true,
}) => {
  const deviceSize: DeviceSize = useDeviceSize()
  const chartColumnSpans: Record<DeviceSize, number> = columnSpans ?? { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }
  const position = legendPosition ?? (deviceSize === DeviceSize.xs || deviceSize === DeviceSize.md ? 'bottom' : 'right') // Responsive legend position
  const type = piechartType ?? 'donut'

  // Define the configuration options for the pie chart
  const options: ApexCharts.ApexOptions = {
    title: { text: title, align: 'left', style: { fontSize: '16px' } },
    subtitle: { text: subtitle },
    chart: { type, toolbar: { show: showToolbar }, height: 100 },
    labels,
    series,
    colors,
    plotOptions: { pie: { donut: { labels: { show: true, total: { showAlways: false, show: true } } } } },
    legend: { position, horizontalAlign: 'left', onItemClick: { toggleDataSeries: true } }, // Responsive legend position
    tooltip: { enabled: false, theme: 'light', y: { formatter: (val: number) => `${val} families` } },
    dataLabels: { enabled: false, style: { fontSize: '10px' }, formatter: (val: number) => `${val.toFixed(0)} %` },
    states: { hover: { filter: { type: 'lighten' } }, active: { filter: { type: 'none' } } },
  }

  // Render the pie chart within a Material UI Card component
  const chart = <ReactApexChart type={type} options={options} series={series} height={300} />
  return showCard ? (
    <Grid item {...chartColumnSpans}>
      <Card sx={{ borderRadius: '16px' }}>
        <CardContent>{chart}</CardContent>
      </Card>
    </Grid>
  ) : (
    <Grid item {...chartColumnSpans}>
      {chart}
    </Grid>
  )
}

export default PieChartComponent
