import { useState } from 'react'
import { baseURL } from 'utils/constants'
import PlaceholderSchoolImg from '../../images/school_icon.png'

interface SchoolLogoProps {
  schoolName: string
  className?: string // className is now explicitly defined as an optional prop
}

const SchoolLogo: React.FC<SchoolLogoProps> = ({ schoolName, className }) => {
  const [isValidLogo, setIsValidLogo] = useState(true)
  return (
    <img
      src={isValidLogo ? `${baseURL}/media/communities/${schoolName}/photo-96x96.jpg` : PlaceholderSchoolImg}
      alt='School Logo'
      onError={() => setIsValidLogo(false)}
      className={className}
    />
  )
}

export default SchoolLogo
