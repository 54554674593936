import { ClearIcon } from '@mui/x-date-pickers'
import AvatarComponent from 'components/avatar'
import BackComponent from 'components/back'
import ButtonMobileComponent from 'components/button-mobile'
import HorizontalScrollContainerComponent from 'components/horizontal-scroll-container'
import { PlusIcon, SearchIcon } from 'components/icons'
import InputMobileComponent from 'components/input-mobile'
import WizardComponent from 'components/wizard'
import Arrow from 'images/arrow_forward.png'
import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { NotifyTypes, UserNotificationOutputProps } from 'types/UserTypes'
import { isWeb, parseJwt, titleCaseSchoolNameForUI } from 'utils/constants'
import { updateUserNotifications } from 'utils/users'

interface CardComponentProps {
  avatar: JSX.Element
  title: string
  subtitle?: string
  label?: string
  body: string
  date: Date
  action?: JSX.Element
}

const CardComponent = (props: CardComponentProps): JSX.Element => {
  const { avatar, title, subtitle, label, body, date, action } = props ?? {}

  return (
    <div className={`flex flex-col items-start gap-3 my-4 self-stretch lg:p-2 lg:border lg:rounded-xl`}>
      <div className='flex justify-between items-start self-stretch'>
        <div className='flex items-start gap-5 flex-1'>
          {avatar}
          <div className='flex flex-col gap-2'>
            {/***************** Title & Subtitle *****************/}
            <div className='flex items-center gap-1 max-w-[60vw]'>
              {/*  Do not truncate title & wrap it if there is no subtitle to render */}
              <span className={`max-w-[60vw] w-auto text-[#474747] text-sm font-medium`}>
                {title}
                {subtitle && (
                  <>
                    <span className='inline-block w-[1px] h-[20px] bg-gray-300 mx-1 align-middle' />
                    <span className='text-[#797979] text-xs font-medium'>{subtitle}</span>
                  </>
                )}
                {label && (
                  <span className='inline-block max-w-[51px] text-xs rounded-xl mx-1 px-1 bg-gray-100 bg-light-yellow border-custom-yellow border text-gray-800'>
                    {label}
                  </span>
                )}
              </span>
            </div>
            {/***************** Body *****************/}
            <span className='text-[#797979] text-xs font-regular max-w-[60vw]'>{body}</span>
            {/***************** Action *****************/}
            {action}
          </div>
        </div>
        <div className='flex flex-col items-end text-[#797979] text-xs font-regular ml-auto'>
          <div>
            {date.toDateString() === new Date().toDateString()
              ? date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
              : date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
          </div>
        </div>
      </div>
    </div>
  )
}

function Index({
  tokens,
  userNotifications,
  onUpdate,
}: {
  tokens: any
  userNotifications: UserNotificationOutputProps[]
  onUpdate: (cb?: () => void) => void
}): JSX.Element {
  const navigate: NavigateFunction = useNavigate()
  const filters: string[] = ['All', 'Key Reminders', 'Announcements', 'General Updates']

  // INPUTS
  const { IdToken } = tokens
  const phoneNumber: string = parseJwt(IdToken).phone_number

  // STATES
  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const [selectedFilter, setSelectedFilter] = useState<string>(filters[0])
  const [filteredUserNotifications, setFilteredUserNotifications] = useState<CardComponentProps[]>([])

  // HANDLERS

  // HOOKS
  useEffect(() => {
    (async function () {
      await updateUserNotifications(phoneNumber, new Date().toISOString())
    })()
    // cleanup function when notification page unmounts
    // ensures that root component updates state
    return () => onUpdate()
  }, [phoneNumber, onUpdate])
  useEffect(() => {
    const cards: CardComponentProps[] = []
    const lookupAction = (url: string = ''): { text?: string; iconPosition?: 'left' | 'right' | undefined } => {
      let text: string | undefined = undefined,
        iconPosition: 'left' | 'right' | undefined = undefined
      if (url.endsWith('/afternoon')) {
        text = 'Afternoon Schedule'
        iconPosition = 'left'
      }
      return { text, iconPosition }
    }
    userNotifications.forEach((v: UserNotificationOutputProps, idx: number) => {
      const {
        notificationType,
        notificationImageUrl,
        notificationTitle,
        notificationBody,
        notificationActionUrl,
        notificationCommunityId,
        notificationSentBy,
        notificationSentAt,
        notificationIsUnread,
      } = v
      const isKeywordFound: boolean =
        !searchKeyword ||
        [notificationTitle, notificationBody, notificationCommunityId].some(
          (field) => field && field.toLowerCase().includes(searchKeyword.toLowerCase()),
        )
      const isNotificationTypeFound: boolean =
        selectedFilter === 'All' ||
        (selectedFilter === 'Key Reminders' && (notificationType & NotifyTypes.URGENT_REMINDERS) !== 0) ||
        (selectedFilter === 'Announcements' && (notificationType & NotifyTypes.COMMUNITY_ANNOUNCEMENTS) !== 0) ||
        (selectedFilter === 'General Updates' && (notificationType & NotifyTypes.EDITABLE_NOTIFY_TYPES) !== 0)
      if (isKeywordFound && isNotificationTypeFound) {
        const { text, iconPosition } = lookupAction(notificationActionUrl)
        cards.push({
          avatar: (
            <AvatarComponent
              photoLink={notificationImageUrl}
              name={notificationSentBy || notificationCommunityId || 'Carpool.School'}
            />
          ),
          title: notificationTitle,
          subtitle: notificationCommunityId ? titleCaseSchoolNameForUI(notificationCommunityId) : undefined,
          label: notificationIsUnread ? 'New' : undefined,
          body: notificationBody,
          date: new Date(notificationSentAt),
          action:
            notificationActionUrl && text ? (
              <ButtonMobileComponent
                onClick={() => navigate(notificationActionUrl)}
                text={text}
                icon={iconPosition ? iconPosition === 'left' ? <PlusIcon /> : Arrow : undefined}
                iconPosition={iconPosition}
                color='bg-[#F0E977]'
                width='w-[220px]'
                className='text-sm' // Not sure why this works, and it isn't overriiden by text-md in button content
              />
            ) : undefined,
        })
      }
    })
    setFilteredUserNotifications(cards)
  }, [userNotifications, searchKeyword, selectedFilter, navigate])

  // CONSTANTS

  // edit profile - body
  const body: JSX.Element = (
    <div className={`flex flex-col flex-1 self-stretch gap-2 ${isWeb ? '' : 'px-5 mb-[120px]'}`}>
      {/***************** Search Bar *****************/}
      <InputMobileComponent
        type='text'
        placeholder='Search your notifications...'
        data_testid='notification-testid'
        value={searchKeyword ?? ''}
        onChange={(v: string) => setSearchKeyword(v)}
        rightElement={searchKeyword ? <ClearIcon onClick={() => setSearchKeyword('')} /> : <SearchIcon />}
      />

      {/***************** Room Type Selector *****************/}
      {filters.length > 2 && (
        <HorizontalScrollContainerComponent className='my-2'>
          {filters.map((v: string, i: number) => (
            <span
              key={i}
              onClick={() => setSelectedFilter(v)}
              className={`text-xs rounded-xl px-2 py-1 cursor-pointer ${
                selectedFilter === v
                  ? 'bg-custom-yellow text-gray-800'
                  : 'bg-light-yellow border-custom-yellow border text-gray-800'
              }`}
            >
              {v}
            </span>
          ))}
        </HorizontalScrollContainerComponent>
      )}

      {/* **************** Room List **************** */}
      {filteredUserNotifications.map((ntf: CardComponentProps, index: number) => (
        <CardComponent
          key={index}
          avatar={ntf.avatar}
          title={ntf.title}
          subtitle={ntf.subtitle}
          label={ntf.label}
          body={ntf.body}
          date={ntf.date}
          action={ntf.action}
        />
      ))}
    </div>
  )

  return (
    <>
      {/***************** Mobile *****************/}
      {!isWeb && <BackComponent text={'Notifications'}>{body}</BackComponent>}
      {/***************** Web *****************/}
      {isWeb && <WizardComponent title={'Notifications'}>{body}</WizardComponent>}
    </>
  )
}

export default Index
