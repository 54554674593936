import { AwsRum, AwsRumConfig } from 'aws-rum-web'

export let rum: AwsRum | undefined

export const rumClient = () => {
  try {
    const APPLICATION_ID: string = process.env.REACT_APP_AWS_RUM_APPLICATION_ID ?? ''
    const APPLICATION_VERSION: string = '1.0.0'
    const APPLICATION_REGION: string = 'us-east-1'

    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      identityPoolId: `${APPLICATION_REGION}:${process.env.REACT_APP_AWS_RUM_IDENTITY_POOL_ID ?? ''}`,
      endpoint: `https://dataplane.rum.${APPLICATION_REGION}.amazonaws.com`,
      telemetries: [['errors', { stackTraceLength: 10000 }], 'http', 'performance'],
      allowCookies: true,
      enableXRay: false,
      pagesToExclude: [/\/home/],
      // The maximum number of events to record during a single session.
      // If set to 0, the limit is removed and all events in the session will be recorded.
      // By default, the web client limits the number of recorded events to 200 per session.
      sessionEventLimit: 2000,
    }
    rum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config)
    console.log('AWS RUM Initialized')
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
    console.error('AWS RUM initialization error:', error)
  }
}
