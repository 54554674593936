import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import BackComponent from 'components/back'
import ButtonMobileComponent from 'components/button-mobile'
import { BackIcon } from 'components/icons'
import Arrow from 'images/arrow_forward.png'
import { ReactNode } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { isWeb } from 'utils/constants'
import './index.css'

interface WizardComponentProps {
  title: string
  subtitle?: any
  rightElement?: JSX.Element
  children: ReactNode
  disable?: boolean
  btnText?: string
  data_testId?: string
  onBack?: () => void
  onCancel?: () => void
  onClick?: () => Promise<void>
}

const WizardComponent = (props: WizardComponentProps): JSX.Element => {
  const navigate: NavigateFunction = useNavigate()
  const {
    title,
    subtitle,
    rightElement,
    children,
    disable,
    btnText = 'Save',
    data_testId,
    onBack = () => navigate(-1),
    onCancel = () => navigate('/'),
    onClick,
  } = props
  const buttonDisabled = disable

  // discard button element
  const discardElement = (
    <ButtonMobileComponent
      text={'Cancel'}
      onClick={onCancel}
      color='bg-custom-gray'
      width='w-[100px]'
      data_testid='cancel-testid'
    />
  )

  // submit button element
  const submitElement = (
    <ButtonMobileComponent
      iconPosition={'right'}
      text={btnText}
      disabled={buttonDisabled}
      data-testid={data_testId}
      icon={Arrow}
      color={'bg-custom-yellow'}
      width='w-[100px]'
      onClick={async () => {
        if (onClick) await onClick()
      }}
    />
  )

  // mobile includes title, subtitle, children together and onClick Submit element in footer
  const mobileElement: JSX.Element = (
    <>
      {/* back button */}
      {onBack && <BackComponent onClick={onBack} rightElement={rightElement} />}
      <div className='px-6 flex flex-col gap-5'>
        {/* main heading */}
        {typeof title === 'string' ? (
          <div className=' md:text-xl text-2xl font-semibold text-gray-400'>{title}</div>
        ) : (
          title
        )}
        {/* subtitle and children - could be collapsed into one in future */}
        {subtitle && <div className='text-gray-400 md:text-base text-sm'>{subtitle}</div>}
        {children && <div>{children}</div>}
        {/* submit button */}
        {onClick && (
          <div className='fixed bottom-0 left-0 right-0 pt-3'>
            {/* <div className='border-b border-gray-200'></div> */}
            <div className='pt-5 pb-5 px-5'>
              <div className='flex justify-end gap-3'>{submitElement}</div>
            </div>
          </div>
        )}
      </div>
    </>
  )

  // web includes title, subtitle and children in a paper component, followed by Discard and onClick Submit elements in footer
  const webElement: JSX.Element = (
    <div data-testid='message-wizard-testid'>
      {/* back button and title above the paper component */}
      {onBack && typeof title === 'string' && title !== '' && (
        <div className={`flex justify-between pt-4 pr-5 items-center`}>
          <div className='flex py-3 gap-3 items-center' data-testid='back-button-text-testid'>
            <div className='cursor-pointer' onClick={onBack} data-testid='back-button-testid'>
              <BackIcon />
            </div>
            <div className='md:text-xl text-lg font-semibold text-gray-400'>{title}</div>
          </div>
          {rightElement}
        </div>
      )}
      {/* subtitle and children with various elements in paper component with a botton margin to account for the footer height */}
      <Paper sx={{ p: 2, boxShadow: 'none', borderRadius: 3, width: '100%' }} style={{ marginBottom: 100 }}>
        <div className='flex flex-col gap-5 justify-center'>
          {subtitle && <div className='text-gray-400 md:text-base text-sm'>{subtitle}</div>}
          {children}
        </div>
      </Paper>
      {/* submit and discard button in footer */}
      {onClick && (
        <div className='fixed bottom-0 left-0 right-0 bg-[#fff] pt-0'>
          <div className='border-b border-gray-200'></div>
          <div className='pt-5 pb-5 px-5'>
            <div className='flex justify-end gap-3'>
              {discardElement}
              {submitElement}
            </div>
          </div>
        </div>
      )}
    </div>
  )

  // web centers the element, and mobile uses the whole viewport
  return isWeb ? (
    <Grid container alignItems='center' justifyContent='center'>
      <Grid item xs={0} sm={0} md={1} lg={2} xl={3}></Grid>
      <Grid item xs={12} sm={12} md={10} lg={8} xl={6}>
        {webElement}
      </Grid>
      <Grid item xs={0} sm={0} md={1} lg={2} xl={3}></Grid>
    </Grid>
  ) : (
    <div className='flex flex-col gap-5 justify-center md:px-10' data-testid='message-wizard-testid'>
      {mobileElement}
    </div>
  )
}

export default WizardComponent
