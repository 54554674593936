import { EditFilledIcon, SaveIcon } from 'components/icons'
import React, { useCallback, useEffect, useRef, useState } from 'react'

interface InlineEditTextAreaComponentProps {
  defaultValue?: string
  isEditable?: boolean
  placeholder?: string
  validate?: (text: string) => boolean
  onSave: (newValue: string) => void // Notify parent on save
  className?: string
  data_testid?: string
}

const InlineEditTextAreaComponent: React.FC<InlineEditTextAreaComponentProps> = ({
  defaultValue = '',
  isEditable = false,
  data_testid,
  placeholder,
  validate,
  onSave,
  className = '',
}) => {
  const [internalValue, setInternalValue] = useState(defaultValue)
  const [isEditing, setIsEditing] = useState(false)
  const [isError, setIsError] = useState(false)
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const saveButtonWrapperRef = useRef<HTMLButtonElement | null>(null)

  // HANDLERS
  // Triggered when enabling edit
  const handleEdit = () => {
    if (isEditable) setIsEditing(true) // Enter edit mode
  }

  // Handle user input
  const handleInputChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const newValue = e.currentTarget.value
    setInternalValue(newValue)
    if (validate) {
      setIsError(!validate(newValue))
    }
  }

  // Triggered when saving edits
  const handleSave = () => {
    onSave(internalValue) // Send value to parent
    setIsEditing(false) // Exit edit mode
  }

  // Cancel edits and reset to default value
  const handleCancel = useCallback(() => {
    setInternalValue(defaultValue)
    setIsEditing(false)
    setIsError(false)
  }, [defaultValue])

  // Adjust height based on content
  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto' // Reset height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px` // Adjust to fit content
    }
  }

  // Adjust height on mount and when internalValue changes
  useEffect(() => {
    adjustHeight()
  }, [internalValue])

  // Detect outside clicks to cancel editing
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if click target is outside of both the textarea and save button
      if (
        isEditing &&
        textareaRef.current &&
        !textareaRef.current.contains(event.target as Node) &&
        saveButtonWrapperRef.current &&
        !saveButtonWrapperRef.current.contains(event.target as Node)
      ) {
        handleCancel() // Discard changes
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isEditing, handleCancel])

  return (
    <>
      <div className='flex justify-between items-center self-stretch'>
        {/****** Left Element ******/}
        <span className='text-[#474747] text-sm font-medium'>Group Description</span>
        {/****** Right Element ******/}
        {isEditable && !isEditing && (
          <button onClick={handleEdit} data-testid='description-edit-testid'>
            <EditFilledIcon />
          </button>
        )}

        {isEditing && !isError && (
          <button ref={saveButtonWrapperRef} onClick={handleSave} data-testid='description-save-testid'>
            <SaveIcon />
          </button>
        )}
      </div>
      <div className='flex items-center w-full'>
        <textarea
          data-testid={data_testid}
          ref={textareaRef}
          value={internalValue}
          readOnly={!isEditing}
          placeholder={placeholder}
          onChange={handleInputChange}
          onClick={handleEdit} // Enter edit mode on click
          className={`w-full rounded-lg border ${
            isError ? 'border-red-500 bg-red-50' : isEditing ? 'border-custom-yellow' : 'border-gray-300'
          } focus:outline-none px-4 py-2 transition-colors duration-200 font-regular text-xs ${className}`}
          style={{ overflow: 'hidden', minHeight: '48px' }}
        />
      </div>
    </>
  )
}

export default InlineEditTextAreaComponent
