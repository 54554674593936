/* eslint @typescript-eslint/no-unused-vars: "off" */
import LightModeIcon from '@mui/icons-material/LightMode'
import PeopleIcon from '@mui/icons-material/People'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import PieChartComponent from 'components/chart-pie'
import ValueChartComponent from 'components/chart-value'
import { SchoolStatProps } from 'types/SchoolTypes'
import { getChartColors, getPieChartData, PieChartDataType } from 'utils/chart'
import './index.css'

const AfternoonMetricsComponent = ({ schoolStats }: { schoolStats?: SchoolStatProps }) => {
  const { registeredUsers = 0, dropoffUsers = 0, dropoffUsersByCity, dropoffUsersByPostalCode } = schoolStats ?? {}
  const dropoffUserCountAndCity: PieChartDataType[] = getPieChartData(dropoffUsersByCity)
  const dropoffUserCountAndPostalCode: PieChartDataType[] = getPieChartData(dropoffUsersByPostalCode)

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <ValueChartComponent
            title='Registered Families'
            data_testid='total-users-testid'
            value={registeredUsers}
            cardBackground='#D0ECFE'
            textColor='#042174'
            avatar={
              <Avatar sx={{ backgroundColor: '#1877f2' }}>
                <PeopleIcon />
              </Avatar>
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <ValueChartComponent
            title='Active Families'
            value={dropoffUsers}
            cardBackground='#FFE9D5'
            textColor='#7A0916'
            avatar={
              <Avatar sx={{ backgroundColor: '#FF5630' }}>
                <LightModeIcon />
              </Avatar>
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} marginTop={1}>
        {dropoffUserCountAndCity.length > 0 && (
          <PieChartComponent
            title='Families by City'
            labels={dropoffUserCountAndCity.map((item) => item.label)}
            series={dropoffUserCountAndCity.map((item) => item.value)}
            colors={getChartColors(dropoffUserCountAndCity)}
            showCard={false}
          />
        )}
        {dropoffUserCountAndPostalCode.length > 0 && (
          <PieChartComponent
            title='Families by ZipCode'
            labels={dropoffUserCountAndPostalCode.map((item) => item.label)}
            series={dropoffUserCountAndPostalCode.map((item) => item.value)}
            colors={getChartColors(dropoffUserCountAndPostalCode)}
            showCard={false}
          />
        )}
      </Grid>
    </>
  )
}

export default AfternoonMetricsComponent
