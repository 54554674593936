import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import AddressAddComponent from 'components/address-add'
import AddressCardComponent from 'components/address-card'
import BoxMobileComponent from 'components/box-mobile'
import ButtonMobileComponent from 'components/button-mobile'
import CardUserMobileComponent from 'components/card-user-mobile'
import FilterComponent from 'components/filter'
import { FullScreenIcon, MessagingFilledIcon, RightArrowIcon } from 'components/icons'
import MapComponent from 'components/map'
import Menu from 'components/menu'
import NavComponent from 'components/nav'
import RadioMobileComponent from 'components/radio-mobile'
import ToastComponent from 'components/toast'
import { getDistance } from 'geolib'
import { useEffect, useState } from 'react'
import { Link, NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import { UserNotificationOutputProps, UserOutputProps } from 'types/UserTypes'
import {
  METERS_TO_MILES,
  baseURL,
  getFinalTime,
  getLocalDate,
  getTimeString,
  isDateValid,
  titleCase,
  titleCaseSchoolNameForUI,
} from 'utils/constants'
import { getMarker } from 'utils/map'
import { getSchoolPool } from 'utils/schools'
import { updateStudentAfternoonSettings, updateStudentMorningSettings } from 'utils/students'
import {
  LocationProps,
  MarkerProps,
  MessageProps,
  ScheduleGroupProps,
  SchoolPoolProps,
  SchoolPoolStudentProps,
  StudentProps,
} from 'utils/types'
import { Drawer } from 'vaul'
import MapUserIcon from '../../images/user_pin.svg'
import './index.css'
import MorningMetricsComponent from 'components/metrics-morning'
import AfternoonMetricsComponent from 'components/metrics-afternoon'

const DashboardEmptyComponent = ({
  user,
  userPhoto,
  studentCount,
  unreadNotificationCount,
}: {
  user: UserOutputProps
  userPhoto: string
  studentCount: number
  unreadNotificationCount: number
}): JSX.Element => {
  const { phoneNumber, name } = user
  const marker: MarkerProps = getMarker(
    { address: 'Central Park, New York City', lat: 40.785091, lng: -73.968285 },
    'School',
  )
  return (
    <div className='flex flex-col h-screen'>
      <MapComponent centerMarker={marker} markers={[marker]} openFull={true} maxZoom={15} />
      <Menu
        phoneNumber={phoneNumber}
        name={name ?? ''}
        studentCount={studentCount}
        unreadNotificationCount={unreadNotificationCount}
        photoLink={userPhoto}
      />
      <div data-vaul-drawer-wrapper=''>
        <Drawer.Root snapPoints={[0.15]} activeSnapPoint={0.15} open={true} modal={false}>
          <Drawer.Portal>
            <Drawer.Overlay className='fixed inset-0 bg-black/40' />
            <Drawer.Content className='bg-zinc-100 flex flex-col rounded-t-[40px] h-[96%] mt-24 fixed bottom-0 left-0 right-0'>
              <Drawer.Title className='font-medium mb-4'></Drawer.Title>
              <div className='font-figtree px-5 font-medium pt-4'>
                <ButtonMobileComponent
                  text={`New School`}
                  data_testid='New-School-testid'
                  width={'full'}
                  href='/add'
                  icon={
                    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
                      <path
                        d='M16.3334 10.8327H11.3334V15.8327H9.66669V10.8327H4.66669V9.16602H9.66669V4.16602H11.3334V9.16602H16.3334V10.8327Z'
                        fill='#474747'
                      />
                    </svg>
                  }
                />
              </div>
            </Drawer.Content>
          </Drawer.Portal>
        </Drawer.Root>
      </div>
    </div>
  )
}

// No Carpool - Show Configuratons
const DashboardConfigComponent = ({
  user,
  userPhoto,
  studentCount,
  unreadNotificationCount,
  student,
}: {
  user: UserOutputProps
  userPhoto: string
  studentCount: number
  unreadNotificationCount: number
  student: StudentProps
}): JSX.Element => {
  // INPUTS: User Count
  const { phoneNumber, name } = user
  const { schoolName, schoolLocation, schoolStats, userRelationship } = student
  const marker: MarkerProps = getMarker(schoolLocation, 'School')

  // states
  const [snap, setSnap] = useState<string | number | null>(0.59)

  // handlers
  const handleSetSnap = (snapPoint: string | number | null) => {
    setSnap(snapPoint)
  }
  return (
    <div className='flex flex-col h-screen'>
      <MapComponent centerMarker={marker} markers={[marker]} maxZoom={15} />
      <Menu
        phoneNumber={phoneNumber}
        name={name ?? ''}
        studentCount={studentCount}
        unreadNotificationCount={unreadNotificationCount}
        relationship={userRelationship}
        photoLink={userPhoto}
      />
      <div data-vaul-drawer-wrapper=''>
        <Drawer.Root
          snapPoints={[0.59]}
          activeSnapPoint={snap}
          setActiveSnapPoint={handleSetSnap}
          open={true}
          modal={false}
          dismissible={false}
          shouldScaleBackground
        >
          <Drawer.Portal>
            <Drawer.Overlay className='fixed inset-0 bg-black/40' />
            <Drawer.Content className=' mx-auto bg-zinc-100 flex flex-col rounded-t-[20px] h-[96%] mt-24 fixed bottom-0 left-0 right-0 '>
              <div className='rounded-t-[40px] flex flex-col items-center justify-center h-screen bg-white'>
                <div className='p-4 bg-white rounded-t-[40px] content-container' data-testid='wrapper-testid'>
                  <div>
                    <div className='ml-0.5 flex gap-3'>
                      <div className='bg-light-yellow inline-block p-3 rounded-lg'>
                        <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'>
                          <path
                            d='M1.26917 10.116V18.9621H6.57686V14.5391H8.34609V18.9621H13.6538V10.116L7.46147 5.69291L1.26917 10.116ZM11.8845 17.1929H10.1153V12.7698H4.80763V17.1929H3.0384V11.0271L7.46147 7.86906L11.8845 11.0271V17.1929Z'
                            fill='#E5C265'
                          />
                          <path d='M17.1922 6.57752H15.423V8.34675H17.1922V6.57752Z' fill='#E5C265' />
                          <path d='M17.1922 10.116H15.423V11.8852H17.1922V10.116Z' fill='#E5C265' />
                          <path d='M17.1922 13.6544H15.423V15.4237H17.1922V13.6544Z' fill='#E5C265' />
                          <path
                            d='M9.2307 3.03906V4.78175L10.9999 6.04675V4.80829H18.9615V17.1929H15.423V18.9621H20.7307V3.03906H9.2307Z'
                            fill='#E5C265'
                          />
                        </svg>
                      </div>

                      <div className=''>
                        <p className='text-disable'>Welcome to</p>
                        <p className='font-semibold text-headings text-lg'>{titleCaseSchoolNameForUI(schoolName)}</p>
                      </div>
                    </div>

                    <div className='ml-1 mt-2'>
                      <p className='text-yellow-bold text-2xl font-bold mb-0.5'>{schoolStats?.registeredUsers}</p>
                      <p className='text-disable'>Parents are part of your carpooling community</p>
                    </div>
                  </div>
                  <div className=''>
                    <p className='pl-1 pb-3 text-lg font-semibold text-headings'>What are you looking for?</p>

                    <div className='flex gap-2 pb-3'>
                      <Link to='/morning'>
                        <BoxMobileComponent
                          icon={`<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
  <g clip-path="url(#clip0_750_11738)">
    <path d="M14.6666 21.9993C14.6666 23.9443 15.4392 25.8095 16.8145 27.1848C18.1898 28.5601 20.055 29.3327 22 29.3327C23.9449 29.3327 25.8101 28.5601 27.1854 27.1848C28.5607 25.8095 29.3333 23.9443 29.3333 21.9993C29.3333 20.0544 28.5607 18.1892 27.1854 16.8139C25.8101 15.4386 23.9449 14.666 22 14.666C20.055 14.666 18.1898 15.4386 16.8145 16.8139C15.4392 18.1892 14.6666 20.0544 14.6666 21.9993Z" stroke="#E5C265" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.33337 22H7.35171" stroke="#E5C265" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22 7.33398V7.35232" stroke="#E5C265" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M36.6666 22H36.685" stroke="#E5C265" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22 36.666V36.6843" stroke="#E5C265" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.5684 11.5691L11.55 11.5508" stroke="#E5C265" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M32.4683 11.5691L32.45 11.5508" stroke="#E5C265" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M32.45 32.4492L32.4683 32.4675" stroke="#E5C265" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.55 32.4492L11.5684 32.4675" stroke="#E5C265" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_750_11738">
      <rect width="44" height="44" fill="white"/>
    </clipPath>
  </defs>
  </svg>`}
                          bgColor='#FFFCEA'
                          name='Morning Carpool'
                          borderColor='#F2EEA7'
                          tagline='Needs pick up location'
                          data_testid={'morning-box-testid'}
                        />
                      </Link>
                      <Link to='/afternoon'>
                        <BoxMobileComponent
                          data_testid={'evening-box-testid'}
                          icon={`<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
  <g clip-path="url(#clip0_750_11754)">
  <path d="M22 34.834C22.449 34.834 22.8824 34.9989 23.218 35.2973C23.5535 35.5957 23.7679 36.0069 23.8205 36.4528L23.8333 36.6673V38.5007C23.8328 38.9679 23.6538 39.4174 23.3331 39.7572C23.0123 40.0969 22.5739 40.3014 22.1074 40.3288C21.6409 40.3562 21.1816 40.2044 20.8233 39.9045C20.4649 39.6046 20.2346 39.1792 20.1795 38.7152L20.1666 38.5007V36.6673C20.1666 36.1811 20.3598 35.7148 20.7036 35.371C21.0474 35.0271 21.5137 34.834 22 34.834Z" fill="#FB8B00"/>
  <path d="M33.5738 31.0016L33.7462 31.1537L35.0295 32.4371C35.3583 32.767 35.5492 33.2097 35.5634 33.6753C35.5776 34.1408 35.4141 34.5944 35.106 34.9437C34.798 35.2931 34.3685 35.5121 33.9048 35.5563C33.4411 35.6005 32.978 35.4665 32.6095 35.1816L32.4372 35.0294L31.1538 33.7461C30.8375 33.4302 30.6474 33.0097 30.6193 32.5636C30.5913 32.1174 30.7272 31.6764 31.0016 31.3235C31.2759 30.9705 31.6697 30.73 32.109 30.6471C32.5483 30.5641 33.0027 30.6446 33.3868 30.8732L33.5738 31.0016Z" fill="#FB8B00"/>
  <path d="M12.8461 31.154C13.1618 31.4696 13.3514 31.8897 13.3794 32.3352C13.4074 32.7808 13.2719 33.2212 12.9983 33.574L12.8461 33.7463L11.5628 35.0296C11.2329 35.3584 10.7902 35.5493 10.3246 35.5635C9.85902 35.5778 9.40549 35.4142 9.05612 35.1062C8.70675 34.7981 8.48774 34.3686 8.44357 33.9049C8.3994 33.4412 8.53338 32.9781 8.8183 32.6096L8.97047 32.4373L10.2538 31.154C10.5976 30.8103 11.0638 30.6172 11.55 30.6172C12.0361 30.6172 12.5023 30.8103 12.8461 31.154Z" fill="#FB8B00"/>
  <path d="M7.33334 20.166C7.80062 20.1665 8.25006 20.3455 8.58985 20.6662C8.92963 20.987 9.1341 21.4254 9.16149 21.8919C9.18887 22.3584 9.0371 22.8177 8.73718 23.176C8.43727 23.5344 8.01185 23.7647 7.54784 23.8198L7.33334 23.8327H5.5C5.03272 23.8322 4.58328 23.6532 4.24349 23.3325C3.90371 23.0117 3.69924 22.5733 3.67185 22.1068C3.64447 21.6403 3.79624 21.181 4.09616 20.8227C4.39607 20.4643 4.82149 20.234 5.2855 20.1788L5.5 20.166H7.33334Z" fill="#FB8B00"/>
  <path d="M38.5 20.166C38.9672 20.1665 39.4167 20.3455 39.7565 20.6662C40.0963 20.987 40.3007 21.4254 40.3281 21.8919C40.3555 22.3584 40.2037 22.8177 39.9038 23.176C39.6039 23.5344 39.1785 23.7647 38.7145 23.8198L38.5 23.8327H36.6666C36.1993 23.8322 35.7499 23.6532 35.4101 23.3325C35.0703 23.0117 34.8659 22.5733 34.8385 22.1068C34.8111 21.6403 34.9629 21.181 35.2628 20.8227C35.5627 20.4643 35.9881 20.234 36.4521 20.1788L36.6666 20.166H38.5Z" fill="#FB8B00"/>
  <path d="M11.3905 8.8192L11.5628 8.97137L12.8462 10.2547C13.175 10.5846 13.3659 11.0273 13.3801 11.4929C13.3943 11.9585 13.2308 12.412 12.9227 12.7614C12.6146 13.1107 12.1851 13.3298 11.7215 13.3739C11.2578 13.4181 10.7946 13.2841 10.4262 12.9992L10.2538 12.847L8.97049 11.5637C8.65532 11.2478 8.4662 10.8278 8.43858 10.3824C8.41096 9.93697 8.54673 9.49679 8.82045 9.14435C9.09417 8.79191 9.48704 8.5514 9.92541 8.46792C10.3638 8.38444 10.8176 8.4637 11.2017 8.69087L11.3905 8.8192Z" fill="#FB8B00"/>
  <path d="M35.0295 8.97037C35.3451 9.28606 35.5348 9.70607 35.5628 10.1516C35.5908 10.5972 35.4553 11.0376 35.1817 11.3904L35.0295 11.5627L33.7462 12.846C33.4162 13.1748 32.9735 13.3657 32.5079 13.38C32.0424 13.3942 31.5888 13.2306 31.2395 12.9226C30.8901 12.6145 30.6711 12.185 30.6269 11.7213C30.5827 11.2576 30.7167 10.7945 31.0017 10.426L31.1538 10.2537L32.4372 8.97037C32.781 8.62667 33.2472 8.43359 33.7333 8.43359C34.2195 8.43359 34.6857 8.62667 35.0295 8.97037Z" fill="#FB8B00"/>
  <path d="M22 3.66602C22.449 3.66607 22.8824 3.83094 23.218 4.12933C23.5535 4.42772 23.7679 4.83889 23.8205 5.28485L23.8333 5.49935V7.33268C23.8328 7.79996 23.6538 8.24941 23.3331 8.58919C23.0123 8.92897 22.5739 9.13345 22.1074 9.16083C21.6409 9.18822 21.1816 9.03645 20.8233 8.73653C20.4649 8.43661 20.2346 8.01119 20.1795 7.54718L20.1666 7.33268V5.49935C20.1666 5.01312 20.3598 4.5468 20.7036 4.20299C21.0474 3.85917 21.5137 3.66602 22 3.66602Z" fill="#FB8B00"/>
  <path d="M22 12.834C23.7961 12.8339 25.5526 13.3614 27.0515 14.3511C28.5503 15.3407 29.7254 16.7489 30.4307 18.4006C31.1361 20.0524 31.3407 21.875 31.0191 23.6421C30.6975 25.4091 29.8638 27.0427 28.6217 28.3401C27.3796 29.6374 25.7837 30.5412 24.0323 30.9393C22.2809 31.3374 20.4512 31.2122 18.7703 30.5793C17.0895 29.9464 15.6316 28.8336 14.5777 27.3792C13.5239 25.9248 12.9205 24.1929 12.8425 22.3985L12.8334 22.0007L12.8425 21.6028C12.9451 19.2429 13.9547 17.0136 15.6608 15.3799C17.3669 13.7461 19.6379 12.8341 22 12.834Z" fill="#FB8B00"/>
  </g>
  <defs>
  <clipPath id="clip0_750_11754">
  <rect width="44" height="44" fill="white"/>
  </clipPath>
  </defs>
  </svg>`}
                          bgColor='#FEF1DD'
                          name='Afternoon Carpool'
                          borderColor='#FFE0B1'
                          tagline='Needs drop off location'
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='p-4 bg-zinc-100 border-t border-zinc-200 mt-auto'>
                  <div className='flex gap-6 justify-end max-w-md mx-auto'></div>
                </div>
              </div>
            </Drawer.Content>
          </Drawer.Portal>
        </Drawer.Root>
      </div>
    </div>
  )
}

interface DashboardComponentProps {
  user: UserOutputProps
  userPhoto: string
  studentCount: number
  unreadNotificationCount: number
  student: StudentProps
  failedAvatarUrls: Set<string>
  onFailedAvatarUrl: (url: string) => void
  date?: string
}

const DashboardComponent = (props: DashboardComponentProps): JSX.Element => {
  const navigate: NavigateFunction = useNavigate()

  // INPUTS: User, Date, Pool, Callback
  const { user, userPhoto, student, studentCount, unreadNotificationCount, failedAvatarUrls, onFailedAvatarUrl, date } =
    props
  const { phoneNumber, name } = user
  const {
    studentId,
    schoolName,
    isSchoolSubscription,
    isStudentSubscription,
    schoolLocation,
    schoolStats,
    schoolScheduleGroups,
    schoolPoolRadius,
    schoolAdminLink,
    userRelationship,
  } = student
  const schoolActivities = student?.schoolActivities?.sort() ?? []
  const getSchoolEndTime = (sgn: string) =>
    schoolScheduleGroups?.find((v: ScheduleGroupProps) => v?.schoolScheduleGroupName === sgn)
      ?.schoolScheduleGroupEndTime ?? 0

  // states
  const [isAMPool, setIsAMPool] = useState<boolean>(date == null ? true : false) //assume AM if date query parameter absent
  const [schoolPoolStudents, setSchoolPoolStudents] = useState<SchoolPoolStudentProps[]>()
  const [snap, setSnap] = useState<string | number | null>(0.68)
  const [openFullScreen, setOpenFullScreen] = useState<boolean>(false)
  const [centerMarker, setCenterMarker] = useState<MarkerProps>()
  const [markers, setMarkers] = useState<MarkerProps[]>()
  const [body, setBody] = useState<Record<string, any>[]>()
  const [isValidLogo, setIsValidLogo] = useState(true)
  // Only used for afternoons
  const [poolDate, setPoolDate] = useState<string>(date && isDateValid(date) ? date : getLocalDate()) // Only use valid dates
  const [selectedGroupNames, setSelectedGroupNames] = useState<Set<string>>(new Set())
  const [selectedActivities, setSelectedActivities] = useState<Set<string>>(new Set())
  // Generic states
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<string>('')

  // constants
  const amLocation: LocationProps = student?.pickupLocation ?? { address: '' }
  const pmLocation: LocationProps = student?.dropoffLocation ?? { address: '' }
  const isNeedAMPool: boolean = amLocation && amLocation?.lat && amLocation?.lng ? true : false
  const isNeedPMPool: boolean = pmLocation && pmLocation?.lat && pmLocation?.lng ? true : false
  const isEnabledAMPool: boolean = isAMPool && isNeedAMPool
  const isEnabledPMPool: boolean = !isAMPool && isNeedPMPool
  const isNotConfigured: boolean = !isNeedAMPool && !isNeedPMPool
  const isSubscribed: boolean = isSchoolSubscription || isStudentSubscription ? true : false
  const amSeatsAvailable: number | undefined = student?.pickupSeatsAvailable
  const pmSeatsAvailable: number | undefined = student?.dropoffSeatsAvailable
  const pmTime: string = getTimeString(
    getFinalTime(
      getSchoolEndTime(student.scheduleGroupName),
      poolDate ? student?.dropoffTimes?.[poolDate] : undefined,
      student?.dropoffTimeDefault,
    ),
  )
  const pmActivities = student?.studentActivities?.sort() ?? []

  // handlers
  const handleSetSnap = (snapPoint: string | number | null) => {
    setSnap(snapPoint)
  }
  const handleFullScreen = () => {
    setOpenFullScreen((prev) => !prev)
  }
  const handleFilterSave = (
    scheduleSelections?: Set<string>,
    activitySelections?: Set<string>,
    dateSelection?: string,
  ) => {
    if (scheduleSelections) {
      setSelectedGroupNames(scheduleSelections)
    }
    if (activitySelections) {
      setSelectedActivities(activitySelections)
    }
    if (dateSelection) {
      setPoolDate(dateSelection)
    }
  }
  const handleMorningCarpoolDelete = async (): Promise<void> => {
    setIsLoading('Deleting morning carpool request')
    const { msg, error } = await updateStudentMorningSettings(schoolName, studentId, { address: '' })
    if (error) setMsg(msg)
    else navigate(0)
    setIsLoading('')
  }
  const handleAfternoonCarpoolDelete = async (): Promise<void> => {
    setIsLoading('Deleting afternoon carpool request')
    const { msg, error } = await updateStudentAfternoonSettings(schoolName, studentId, { address: '' })
    if (error) setMsg(msg)
    else navigate(0)
    setIsLoading('')
  }

  // Hooks
  useEffect(() => {
    (async () => {
      const { data, error } = await getSchoolPool(schoolName, isAMPool ? undefined : poolDate)
      if (!error && data) setSchoolPoolStudents((data as SchoolPoolProps).students)
    })()
    window.history.replaceState(
      null,
      '',
      isAMPool ? `/students?id=${studentId}` : `/students?id=${studentId}&date=${poolDate}`,
    )
  }, [isAMPool, poolDate]) // eslint-disable-line

  useEffect(() => {
    if (schoolPoolStudents && (isEnabledAMPool || isEnabledPMPool)) {
      // Only build cards if morning or afternoon active
      // Pool - Extract, Filter, Transform and Order
      const homeLocation: LocationProps = isAMPool ? amLocation : pmLocation
      const homeLat: number = homeLocation.lat as number
      const homeLng: number = homeLocation.lng as number
      const rows: any[] = [],
        pins: MarkerProps[] = []
      schoolPoolStudents.forEach((v: SchoolPoolStudentProps, i: number) => {
        const {
          userName,
          userPhoneNumber,
          userRelationship,
          userPhotoLink,
          studentId,
          position,
          dropoffTimeDefault,
          dropoffTime,
          studentActivities,
          seatsAvailable,
          comments,
          scheduleGroupName,
        } = v
        const { lat, lng } = position ?? {}
        const isNotMyStudent: boolean = userPhoneNumber !== phoneNumber
        const time: number = getFinalTime(getSchoolEndTime(scheduleGroupName), dropoffTime, dropoffTimeDefault)
        const isScheduled = isAMPool || time >= 0
        const isMatchesActivityFilter =
          isAMPool || !selectedActivities.size || studentActivities?.find((v) => selectedActivities.has(v))
        const isMatchesScheduleFilter =
          isAMPool || !selectedGroupNames.size || selectedGroupNames.has(scheduleGroupName)

        // Filter out students not looking for carpool, and without matching filters
        if (
          position &&
          lat &&
          lng &&
          isNotMyStudent &&
          isScheduled &&
          isMatchesActivityFilter &&
          isMatchesScheduleFilter
        ) {
          const scheduleName: string | undefined =
            schoolScheduleGroups && schoolScheduleGroups?.length > 1 ? scheduleGroupName : undefined
          const relation: string = ['Parent/Stepparent', 'Mother', 'Father'].includes(userRelationship)
            ? 'Parent'
            : userRelationship
          const distance: number = getDistance({ lat: homeLat, lng: homeLng }, { lat, lng })
          const distanceMiles: string = Math.round(distance * METERS_TO_MILES * 10) / 10 + ' miles'
          const pmPickupTime: string | undefined = !isAMPool ? getTimeString(time) : undefined
          const activities: string[] | undefined =
            !isAMPool && Array.isArray(studentActivities) ? studentActivities : undefined
          let url: string = `/userpool/${schoolName}?studentId=${student.studentId}&userStudentId=${studentId}`
          url += isAMPool ? `&poolDirection=pickup` : `&poolDirection=dropoff&poolDate=${poolDate}`
          const actions: JSX.Element = (
            <button
              className='flex w-10 h-10 justify-center items-center rounded-lg bg-[#F0E977]'
              aria-label='New Group'
              onClick={() => navigate(url, { state: { userPhoneNumber } })}
              style={{ marginLeft: 'auto' }} // Ensures the button is right-aligned
              data-testid='icon-button'
            >
              <RightArrowIcon />
            </button>
          )
          pins.push({
            position,
            iconPath: MapUserIcon,
            iconSize: { width: 45, height: 45 }, // Default size
            infoWindowId: `btn-${i}`,
            infoWindowContent: titleCase(userName),
            draggable: false,
          })
          rows.push({
            userName,
            userPhotoLink,
            distanceMiles,
            distance,
            relation,
            seatsAvailable,
            pmPickupTime,
            actions,
            activities,
            scheduleName,
            comments,
          })
        }
      })
      setCenterMarker(getMarker(homeLocation, 'You', schoolPoolRadius))
      setMarkers([getMarker(schoolLocation, 'School'), ...pins])
      setBody(rows.sort((a, b) => (a.distance < b.distance ? -1 : 1)))
    } else {
      setCenterMarker(getMarker(schoolLocation, 'School'))
      setMarkers([])
      setBody([])
    }
  }, [schoolPoolStudents, selectedActivities, selectedGroupNames]) // eslint-disable-line

  // Inactive user - morning and afternoon carpool disabled OR school & student subscriptions disabled
  if (isNotConfigured) {
    return (
      <DashboardConfigComponent
        user={user}
        userPhoto={userPhoto}
        studentCount={studentCount}
        unreadNotificationCount={unreadNotificationCount}
        student={student}
      />
    )
  } else if (!centerMarker || !body) return <LinearProgress />

  return (
    <>
      {/***************** Display progress and error *****************/}
      {isLoading && <LinearProgress />}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
      {/***************** Map *****************/}
      <MapComponent centerMarker={centerMarker} markers={markers} openFull={openFullScreen} maxZoom={15} />
      <Menu
        phoneNumber={phoneNumber}
        name={name ?? ''}
        studentCount={studentCount}
        unreadNotificationCount={unreadNotificationCount}
        relationship={userRelationship}
        photoLink={userPhoto}
      />
      <button
        style={{
          backgroundColor: 'white',
          border: 'none',
          borderRadius: '50px',
          position: 'fixed',
          top: openFullScreen ? '93%' : '30%',
          right: '4%',
          zIndex: 0,
          padding: '8px',
        }}
        onClick={handleFullScreen}
      >
        <FullScreenIcon />
      </button>
      {/***************** Drawer *****************/}
      {!openFullScreen && (
        <div data-vaul-drawer-wrapper='' style={{ zIndex: 10 }}>
          <Drawer.Root
            snapPoints={isEnabledAMPool || isEnabledPMPool ? [0.68, 0.95] : [0.68]}
            activeSnapPoint={snap}
            setActiveSnapPoint={handleSetSnap}
            open={true}
            modal={false}
            dismissible={false}
            shouldScaleBackground
          >
            <Drawer.Portal>
              <Drawer.Overlay className='fixed inset-0 bg-black/40' />
              <Drawer.Content className='bg-zinc-100 flex flex-col rounded-t-[20px] h-[96%] mt-24 fixed bottom-0 left-0 right-0 '>
                <div className='p-4 bg-white rounded-t-[20px] flex-1'>
                  <div className='mx-auto w-16 h-1.5 flex-shrink-0 rounded-full bg-zinc-300 mb-4' />
                  <div className='max-w-[768px] mx-auto'>
                    <Drawer.Title className='font-medium mb-4'>
                      {/***************** Select Morning vs Afternoon *****************/}
                      <Paper
                        variant='outlined'
                        sx={{
                          my: 1,
                          display: 'flex',
                          border: 'none',
                          gap: '10px',
                          justifyContent: 'space-between',
                          width: '100%',
                          background: '#F9F9F9',
                          padding: '8px',
                          borderRadius: '12px',

                          // Add more media queries as needed for different screen sizes
                        }}
                      >
                        <RadioMobileComponent
                          row={true}
                          radios={[
                            {
                              isChecked: isAMPool,
                              label: <span data-testid='radio-morning-testid'>Morning Carpool</span>,
                              onChange: (v: any) => {
                                setIsAMPool(true)
                              },
                            },
                            {
                              isChecked: !isAMPool,
                              label: <span data-testid='radio-afternoon-testid'>Afternoon Carpool</span>,
                              onChange: (v: any) => {
                                setIsAMPool(false)
                              },
                            },
                          ]}
                        />
                      </Paper>
                      {/***************** Update Morning Configuration *****************/}
                      {isEnabledAMPool && (
                        <AddressCardComponent
                          isAMPool={isAMPool}
                          address={amLocation.address}
                          seatsAvailable={amSeatsAvailable}
                          onEdit={() => navigate(`/morning?id=${studentId}`)}
                          onDelete={handleMorningCarpoolDelete}
                        />
                      )}
                      {/***************** Update Afternoon Configuration *****************/}
                      {isEnabledPMPool && (
                        <AddressCardComponent
                          isAMPool={false}
                          address={pmLocation.address}
                          date={poolDate}
                          seatsAvailable={pmSeatsAvailable}
                          time={pmTime}
                          activities={pmActivities}
                          onEdit={() => navigate(`/afternoon?id=${studentId}`)}
                          onDelete={handleAfternoonCarpoolDelete}
                        />
                      )}
                    </Drawer.Title>
                    {/***************** Show Morning Filter *****************/}
                    {isEnabledAMPool && isSubscribed && (
                      <div className='flex justify-around gap-1 mb-4'>
                        {/***************SCHOOL BANNER/LOGO/GRAPHIC**********/}
                        <div className='flex items-center gap-1'>
                          {isValidLogo && (
                            <a href={schoolAdminLink} target='_blank' rel='noopener noreferrer'>
                              <img
                                src={`${baseURL}/media/communities/${schoolName}/photo-96x96.jpg`}
                                alt='School Logo'
                                className='w-12 h-12 rounded-full shadow-sm object-cover'
                                onError={() => setIsValidLogo(false)} // Mark as invalid if loading fails
                              />
                            </a>
                          )}
                          <div className='flex flex-col flex-grow max-w-full'>
                            <h2 className={`text-md text-gray-800`}>{'Families looking to carpool'}</h2>
                            <p className='text-xs text-gray-600'>
                              <NavComponent href={schoolAdminLink} text='Support School' isOpenNewTab={true} /> |{' '}
                              <NavComponent href={'/messaging'} text='Meet Other Families' />
                            </p>
                          </div>
                        </div>

                        {/***************MESSAGING BUTTON**********/}
                        <button
                          className='flex w-12 h-12 justify-center items-center rounded-lg bg-[#F0E977]'
                          aria-label='New Group'
                          onClick={() => navigate(`/messaging`)}
                          style={{ marginLeft: 'auto' }} // Ensures the button is right-aligned
                        >
                          <MessagingFilledIcon />
                        </button>
                      </div>
                    )}
                    {/***************** Show Afternoon Filter *****************/}
                    {isEnabledPMPool && isSubscribed && (
                      <div className='flex items-center gap-1 mb-4'>
                        <FilterComponent
                          activities={new Set(schoolActivities?.map((v: string) => v) || [])}
                          selectedActivities={selectedActivities}
                          selectedDate={poolDate}
                          onSave={handleFilterSave}
                        />
                      </div>
                    )}
                    {/***************** Show Connections *****************/}
                    {(isEnabledAMPool || isEnabledPMPool) && isSubscribed && (
                      <div className='scrollable-container' data-testid='cards-container-testid'>
                        {body &&
                          body?.length > 0 &&
                          body.map((row, index) => (
                            <CardUserMobileComponent
                              key={index}
                              isAMPool={isAMPool}
                              isLast={index === body.length - 1}
                              userName={row.userName}
                              userRelationship={row.relation}
                              photoLink={row.userPhotoLink}
                              failedPhotoLinks={failedAvatarUrls}
                              onFailedPhotoLink={onFailedAvatarUrl}
                              scheduleName={row.scheduleName}
                              distance={row.distanceMiles}
                              seatsAvailable={row.seatsAvailable}
                              time={row.pmPickupTime}
                              activities={row.activities}
                              action={row.actions}
                            />
                          ))}
                      </div>
                    )}
                    {/***************** Show Stats *****************/}
                    {isEnabledAMPool && !isSubscribed && (
                      <div className='scrollable-container metrics-container'>
                        <MorningMetricsComponent schoolStats={schoolStats} />
                      </div>
                    )}
                    {isEnabledPMPool && !isSubscribed && (
                      <div className='scrollable-container metrics-container'>
                        <AfternoonMetricsComponent schoolStats={schoolStats} />
                      </div>
                    )}
                    {/***************** Add Morning Configuration *****************/}
                    {isAMPool && !isNeedAMPool && (
                      <AddressAddComponent isAMPool={isAMPool} href={`/morning?id=${studentId}`} />
                    )}
                    {/***************** Add Afternoon Configuration *****************/}
                    {!isAMPool && !isNeedPMPool && (
                      <AddressAddComponent isAMPool={false} href={`/afternoon?id=${studentId}`} />
                    )}
                  </div>
                </div>

                <div className='p-4 bg-zinc-100 border-t border-zinc-200 mt-auto'>
                  <div className='flex gap-6 justify-end max-w-md mx-auto'></div>
                </div>
              </Drawer.Content>
            </Drawer.Portal>
          </Drawer.Root>
        </div>
      )}
    </>
  )
}

interface Props {
  tokens: any
  user?: UserOutputProps
  userPhoto: string
  userNotifications: UserNotificationOutputProps[]
  students: StudentProps[]
  failedAvatarUrls: Set<string>
  onFailedAvatarUrl: (url: string) => void
  processQuery: (id: string) => string
}

function Index(props: Props): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams()

  // Inputs: Tokens, Students, Selected Student Id, and Callback
  const { user, userPhoto, userNotifications, students, failedAvatarUrls, onFailedAvatarUrl, processQuery } = props

  // We call this component without student id query search parameters in quite a few places in the code
  const studentIdFromQuery = searchParams.get('id') ?? ''
  const date = searchParams.get('date')
  useEffect(() => {
    setSearchParams({ id: processQuery(studentIdFromQuery), ...(date ? { date } : {}) })
  }, [studentIdFromQuery, date, processQuery, setSearchParams])

  // Build props for child component
  const studentCount: number = students && students?.length > 0 ? students.length : 0
  const unreadNotificationCount: number = userNotifications.filter((v) => v.notificationIsUnread === true).length
  const student: StudentProps | undefined = students?.find((s: StudentProps) => s.studentId === studentIdFromQuery)

  if (user && student) {
    return (
      <DashboardComponent
        user={user}
        userPhoto={userPhoto}
        studentCount={studentCount}
        unreadNotificationCount={unreadNotificationCount}
        student={student}
        failedAvatarUrls={failedAvatarUrls}
        onFailedAvatarUrl={onFailedAvatarUrl}
        date={date ? date : undefined}
      />
    )
  } else if (user) {
    // No matching student
    return (
      <DashboardEmptyComponent
        user={user}
        userPhoto={userPhoto}
        studentCount={studentCount}
        unreadNotificationCount={unreadNotificationCount}
      />
    )
  } else return <LinearProgress />
}

export default Index
