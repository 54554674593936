/* eslint @typescript-eslint/no-unused-vars: "off" */
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import PeopleIcon from '@mui/icons-material/People'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import PieChartComponent from 'components/chart-pie'
import ValueChartComponent from 'components/chart-value'
import { SchoolStatProps } from 'types/SchoolTypes'
import { getChartColors, getPieChartData, PieChartDataType } from 'utils/chart'
import './index.css'

const MorningMetricsComponent = ({ schoolStats }: { schoolStats?: SchoolStatProps }) => {
  const { registeredUsers = 0, pickupUsers = 0, pickupUsersByCity, pickupUsersByPostalCode } = schoolStats ?? {}
  const pickupUserCountAndCity: PieChartDataType[] = getPieChartData(pickupUsersByCity)
  const pickupUserCountAndPostalCode: PieChartDataType[] = getPieChartData(pickupUsersByPostalCode)

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <ValueChartComponent
            title='Registered Families'
            data_testid='total-users-testid'
            value={registeredUsers}
            cardBackground='#D0ECFE'
            textColor='#042174'
            avatar={
              <Avatar sx={{ backgroundColor: '#1877f2' }}>
                <PeopleIcon />
              </Avatar>
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <ValueChartComponent
            title='Active Families'
            value={pickupUsers}
            cardBackground='#FFF5CC'
            textColor='#7A4100'
            avatar={
              <Avatar sx={{ backgroundColor: '#FFAB00' }}>
                <LightModeOutlinedIcon />
              </Avatar>
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} marginTop={1}>
        {pickupUserCountAndCity.length > 0 && (
          <PieChartComponent
            title='Families by City'
            labels={pickupUserCountAndCity.map((item) => item.label)}
            series={pickupUserCountAndCity.map((item) => item.value)}
            colors={getChartColors(pickupUserCountAndCity)}
            showCard={false}
          />
        )}
        {pickupUserCountAndPostalCode.length > 0 && (
          <PieChartComponent
            title='Families by ZipCode'
            labels={pickupUserCountAndPostalCode.map((item) => item.label)}
            series={pickupUserCountAndPostalCode.map((item) => item.value)}
            colors={getChartColors(pickupUserCountAndPostalCode)}
            showCard={false}
          />
        )}
      </Grid>
    </>
  )
}

export default MorningMetricsComponent
