import {
  CreateMessageInputProps,
  CreateMessageOutputProps,
  GetMessagesInputProps,
  GetMessagesOutputProps,
  MessageOutputProps,
  SortDirection,
} from 'types/MessageTypes'
import {
  AddRoomUsersInputProps,
  CreateRoomInputProps,
  RemoveRoomUsersInputProps,
  RoomType,
  UpdateRoomInputProps,
  UpdateRoomUserInputProps,
} from 'types/RoomTypes'
import { connectToWss, httpBearerAuth, processError } from 'utils/requests'
import { ResponseProps } from 'utils/types'

/**
 * Creates a new chat room with specified parameters
 * @param roomType - Type of room (e.g., direct message, group chat)
 * @param roomUsers - Array of user IDs to add to the room
 * @param roomName - Optional name for the room
 * @param roomDescription - Optional description for the room
 * @param roomCommunityId - Optional community ID the room belongs to
 */
export const createRoom = async (
  roomType: RoomType,
  roomUsers: string[],
  roomName?: string,
  roomDescription?: string,
  roomCommunityId?: string,
): Promise<ResponseProps> => {
  try {
    const body: CreateRoomInputProps = { roomType, roomUsers, roomName, roomDescription, roomCommunityId }
    const { data } = await httpBearerAuth('post', `/api/rooms`, '', body as unknown as Record<string, unknown>)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Updates an existing room's properties
 * @param roomId - ID of the room to update
 * @param roomType - Optional new room type
 * @param roomName - Optional new room name
 * @param roomDescription - Optional new room description
 * @param roomAdmins - Optional array of admin user IDs
 */
export const updateRoom = async (
  roomId: string,
  roomType?: RoomType,
  roomName?: string,
  roomDescription?: string,
  roomAdmins?: string[],
): Promise<ResponseProps> => {
  try {
    const body: UpdateRoomInputProps = { roomType, roomName, roomDescription, roomAdmins }
    const { data } = await httpBearerAuth('patch', `/api/rooms/${roomId}`, '', body as Record<string, unknown>)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Deletes a room by its ID
 * @param roomId - ID of the room to delete
 */
export const deleteRoom = async (roomId: string): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('delete', `/api/rooms/${roomId}`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Retrieves a single room by its ID
 * @param roomId - ID of the room to fetch
 */
export const getRoom = async (roomId: string): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/rooms/${roomId}`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Retrieves all rooms the current user has access to
 */
export const getRooms = async (): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/rooms`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Gets all users in a specific room
 * @param roomId - ID of the room to get users from
 */
export const getRoomUsers = async (roomId: string): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/rooms/${roomId}/users`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Adds new users to an existing room
 * @param roomId - ID of the room to add users to
 * @param roomUsers - Array of user IDs to add
 */
export const addRoomUsers = async (roomId: string, roomUsers: string[]): Promise<ResponseProps> => {
  try {
    const body: AddRoomUsersInputProps = { roomUsers }
    const { data } = await httpBearerAuth(
      'post',
      `/api/rooms/${roomId}/users`,
      '',
      body as unknown as Record<string, unknown>,
    )
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Updates a user's last seen timestamp in a room
 * Note: This is a fire-and-forget operation (non-async)
 * @param roomId - ID of the room
 * @param userId - ID of the user
 * @param userLastSeen - Timestamp of when the user was last seen
 */
export const updateRoomUser = (roomId: string, userId: string, userLastSeen: string): void => {
  const body: UpdateRoomUserInputProps = { userLastSeen }
  httpBearerAuth('patch', `/api/rooms/${roomId}/users/${userId}`, '', body as unknown as Record<string, unknown>)
}

/**
 * Removes users from a room
 * @param roomId - ID of the room to remove users from
 * @param roomUsers - Array of user IDs to remove
 */
export const removeRoomUsers = async (roomId: string, roomUsers: string[]): Promise<ResponseProps> => {
  try {
    const body: RemoveRoomUsersInputProps = { roomUsers }
    const { data } = await httpBearerAuth(
      'delete',
      `/api/rooms/${roomId}/users`,
      '',
      body as unknown as Record<string, unknown>,
    )
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Creates a new message in a room using GraphQL mutation
 * @param userId - ID of the user sending the message
 * @param msgRoomId - ID of the room to send the message to
 * @param msgText - Content of the message
 */
export const createRoomMsg = async (
  userId: string,
  msgRoomId: string,
  msgText: string,
): Promise<ResponseProps<{ data: CreateMessageOutputProps }>> => {
  try {
    const query: string =
      'mutation CreateMessage($input: messageInput!) { createMessage(input: $input) { msgRoomId msgText msgSentBy msgSentAt } }'
    const input: CreateMessageInputProps = { msgRoomId, msgText }
    const { data } = await httpBearerAuth('post', `/graphql/api`, '', { query, variables: { input } })
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Retrieves messages from a room using GraphQL query
 * @param userId - ID of the user requesting messages
 * @param msgRoomId - ID of the room to get messages from
 * @param limit - Optional maximum number of messages to retrieve
 * @param nextToken - Optional pagination token
 * @param sortDirection - Optional sort direction for messages
 */
export const getRoomMsgs = async (
  userId: string,
  msgRoomId: string,
  limit?: number,
  nextToken?: string | null,
  sortDirection?: SortDirection,
): Promise<ResponseProps<{ data: GetMessagesOutputProps }>> => {
  try {
    const query: string =
      'query GetMessages($input: getMessagesInput!) { getMessages(input: $input) { items { msgText msgSentBy msgSentAt } nextToken } }'
    const input: GetMessagesInputProps = { msgRoomId, limit, nextToken, sortDirection }
    const { data } = await httpBearerAuth('post', `/graphql/api`, '', { query, variables: { input } })
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Sets up a WebSocket subscription for new messages in a room
 * @param msgRoomId - ID of the room to subscribe to
 * @param subscriberId - ID of the subscribing user
 * @param onCreatedMessage - Callback function to handle new messages
 * @returns Function to unsubscribe from the WebSocket
 */
export const onCreateMessageSubscription = async (
  msgRoomId: string,
  subscriberId: string,
  onCreatedMessage: (message: MessageOutputProps) => void,
): Promise<() => void> => {
  const query: string = `subscription onCreateMessage($msgRoomId: ID!) { onCreateMessage(msgRoomId: $msgRoomId) { msgText msgSentBy msgSentAt } }`
  return await connectToWss({ query, variables: { msgRoomId } }, subscriberId, onCreatedMessage)
}
