import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { DatePicker } from '@mui/x-date-pickers'
import ButtonMobileComponent from 'components/button-mobile'
import dayjs, { Dayjs } from 'dayjs'
import React, { useMemo, useState } from 'react'

export type TimeFrames = '7D' | '1M' | '3M' | '6M' | '12M' | 'YTD' | 'Custom'
export type TimeFrame = {
  timeFrame: TimeFrames
  startDate?: string
  endDate?: string
}

interface TimeFrameSelectorProps {
  allowedTimeFrames?: TimeFrames[]
  initialTimeFrame?: TimeFrames
  onTimeFrameChange: (selection: TimeFrame) => void
}

const TimeFrameButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: '12px',
  padding: theme.spacing(0.5, 1),
  margin: theme.spacing(0, 0.5, 1),
  minWidth: '32px',
  '&.Mui-selected': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    borderColor: theme.palette.secondary.main,
  },
  '&:hover': {
    borderColor: theme.palette.secondary.main,
  },
}))

const TimeFrameSelector: React.FC<TimeFrameSelectorProps> = ({
  allowedTimeFrames = ['7D', '1M', '3M', '6M', '12M', 'YTD', 'Custom'],
  initialTimeFrame = '3M',
  onTimeFrameChange,
}) => {
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<TimeFrames>(initialTimeFrame)
  const [previousTimeFrame, setPreviousTimeFrame] = useState<TimeFrames>(initialTimeFrame)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [customStartDate, setCustomStartDate] = useState<Dayjs | null>(null)
  const [customEndDate, setCustomEndDate] = useState<Dayjs | null>(null)
  const [dateError, setDateError] = useState<string | null>(null)

  const open = Boolean(anchorEl)
  const handleTimeFrameClick = (timeFrame: TimeFrames) => {
    setSelectedTimeFrame(timeFrame)
    if (timeFrame !== 'Custom') {
      onTimeFrameChange({ timeFrame })
    }
    setAnchorEl(null)
    setDateError(null)
  }

  const handleCustomClick = (event: React.MouseEvent<HTMLElement>) => {
    setPreviousTimeFrame(selectedTimeFrame)
    setSelectedTimeFrame('Custom')
    setAnchorEl(event.currentTarget)
    setDateError(null)
  }
  const handleMenuClose = () => {
    setSelectedTimeFrame(previousTimeFrame)
    setAnchorEl(null)
    setDateError(null)
  }
  const handleApplyCustomRange = () => {
    if (customStartDate && customEndDate) {
      if (customStartDate.isAfter(customEndDate)) {
        setDateError('Start date must be before end date.')
        return
      }

      const twelveMonthsAgo = dayjs(customEndDate).subtract(12, 'month')

      if (customStartDate.isBefore(twelveMonthsAgo)) {
        setDateError('Start date cannot be more than 12 months in the past.')
        return
      }

      onTimeFrameChange({
        timeFrame: 'Custom',
        startDate: customStartDate.format('YYYY-MM-DD'),
        endDate: customEndDate.format('YYYY-MM-DD'),
      })
      setAnchorEl(null)
      setDateError(null)
    }
  }

  const minStartDate = useMemo(() => {
    return dayjs().subtract(12, 'month')
  }, [])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {allowedTimeFrames
        .filter((tf) => tf !== 'Custom')
        .map((timeFrame) => (
          <TimeFrameButton
            key={timeFrame}
            onClick={() => handleTimeFrameClick(timeFrame)}
            className={selectedTimeFrame === timeFrame ? 'Mui-selected' : ''}
          >
            {timeFrame}
          </TimeFrameButton>
        ))}
      {allowedTimeFrames.includes('Custom') && (
        <>
          <TimeFrameButton onClick={handleCustomClick} className={selectedTimeFrame === 'Custom' ? 'Mui-selected' : ''}>
            Custom
          </TimeFrameButton>
          <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
            <MenuItem>
              <Typography variant='body2'>Start Date</Typography>
              <DatePicker
                value={customStartDate}
                onChange={(newValue) => setCustomStartDate(newValue)}
                minDate={minStartDate}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!dateError,
                    helperText: dateError,
                  },
                }}
              />
            </MenuItem>
            <MenuItem>
              <Typography variant='body2'>End Date</Typography>
              <DatePicker
                value={customEndDate}
                onChange={(newValue) => setCustomEndDate(newValue)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!dateError,
                    helperText: dateError,
                  },
                }}
              />
            </MenuItem>
            <MenuItem>
              <ButtonMobileComponent
                text='Apply'
                onClick={handleApplyCustomRange}
                disabled={!customStartDate || !customEndDate}
              />
            </MenuItem>
          </Menu>
        </>
      )}
    </Box>
  )
}

export default TimeFrameSelector
