import {
  UpdateUserInputProps,
  UpdateUserNotificationInputProps,
  UserNotificationOutputProps,
  UserOutputProps,
  UserPoolOutputProps,
} from 'types/UserTypes'
import { http, httpBasicAuth, httpBearerAuth, processError } from 'utils/requests'
import { ResponseProps } from 'utils/types'

/**
 * Creates a new user with the provided phone number, password and name
 * @param phoneNumber - Phone number of the user
 * @param password - Password for the user account
 * @param name - Name of the user
 * @returns Promise resolving to ResponseProps
 */
export const createUser = async (phoneNumber: string, password: string, name: string): Promise<ResponseProps> => {
  try {
    await http('post', `/api/users`, '', { phoneNumber, password, name })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Resends verification code to user's phone number
 * @param phoneNumber - Phone number to send code to
 * @returns Promise resolving to ResponseProps
 */
export const resendCode = async (phoneNumber: string): Promise<ResponseProps> => {
  try {
    await http('post', `/api/resendCode`, '', { phoneNumber })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Verifies a user's phone number with provided code
 * @param phoneNumber - Phone number to verify
 * @param code - Verification code
 * @returns Promise resolving to ResponseProps
 */
export const verifyUser = async (phoneNumber: string, code: string): Promise<ResponseProps> => {
  try {
    await http('post', `/api/verifyUser`, '', { phoneNumber, code })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Signs in a user with phone number and password
 * @param phoneNumber - User's phone number
 * @param password - User's password
 * @returns Promise resolving to ResponseProps
 */
export const signInUser = async (phoneNumber: string, password: string): Promise<ResponseProps> => {
  try {
    await httpBasicAuth('post', `/api/signInUser`, { username: phoneNumber, password })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Signs out the current user
 * @returns Promise resolving to ResponseProps
 */
export const signOutUser = async (): Promise<ResponseProps> => {
  try {
    await httpBearerAuth('post', `/api/signOutUser`)
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Updates user's password
 * @param phoneNumber - User's phone number
 * @param accessToken - User's access token
 * @param password - Current password
 * @param newPassword - New password
 * @returns Promise resolving to ResponseProps
 */
export const updateUserPassword = async (
  phoneNumber: string,
  accessToken: string,
  password: string,
  newPassword: string,
): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('post', `/api/updateUserPassword`, '', {
      phoneNumber,
      accessToken,
      password,
      newPassword,
    })
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Initiates forgot password flow
 * @param phoneNumber - User's phone number
 * @returns Promise resolving to ResponseProps
 */
export const forgotUserPassword = async (phoneNumber: string): Promise<ResponseProps> => {
  try {
    await http('post', `/api/forgotUserPassword`, '', { phoneNumber })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Sets a new password using temporary password
 * @param phoneNumber - User's phone number
 * @param tempPassword - Temporary password
 * @param password - New password
 * @returns Promise resolving to ResponseProps
 */
export const setUserPassword = async (
  phoneNumber: string,
  tempPassword: string,
  password: string,
): Promise<ResponseProps> => {
  try {
    await http('post', `/api/setUserPassword`, '', { phoneNumber, tempPassword, password })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Resets user's password with verification code
 * @param phoneNumber - User's phone number
 * @param code - Verification code
 * @param password - New password
 * @returns Promise resolving to ResponseProps
 */
export const resetUserPassword = async (
  phoneNumber: string,
  code: string,
  password: string,
): Promise<ResponseProps> => {
  try {
    await http('post', `/api/resetUserPassword`, '', { phoneNumber, code, password })
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Updates user profile information
 * @param phoneNumber - User's phone number
 * @param userFirstName - Optional first name
 * @param userLastName - Optional last name
 * @param userBio - Optional bio
 * @param userSocialLinks - Optional social media links
 * @param userNotificationTypesDisabled - Optional notification preferences
 * @param userNotificationDevice - Optional notification device
 * @returns Promise resolving to ResponseProps
 */
export const updateUser = async (
  phoneNumber: string,
  userFirstName?: string,
  userLastName?: string,
  userBio?: string,
  userSocialLinks?: Record<string, string>,
  userNotificationTypesDisabled?: number,
  userNotificationDevice?: string,
): Promise<ResponseProps> => {
  try {
    const body: UpdateUserInputProps = {
      userFirstName,
      userLastName,
      userBio,
      userSocialLinks,
      userNotificationTypesDisabled,
      userNotificationDevice,
    }
    const { data } = await httpBearerAuth('patch', `/api/users/${phoneNumber}`, '', body as Record<string, unknown>)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Deletes a user account
 * @param phoneNumber - User's phone number
 * @returns Promise resolving to ResponseProps
 */
export const deleteUser = async (phoneNumber: string): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('delete', `/api/users/${phoneNumber}`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Gets user profile information
 * @param phoneNumber - User's phone number
 * @returns Promise resolving to ResponseProps containing user data
 */
export const getUser = async (
  phoneNumber: string,
): Promise<ResponseProps<{ message: string; user: UserOutputProps }>> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/users/${phoneNumber}`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Updates user notification settings
 * @param userId - User's ID
 * @param userNotificationLastRead - Optional timestamp of last read notification
 * @returns Promise resolving to ResponseProps containing notifications
 */
export const updateUserNotifications = async (
  userId: string,
  userNotificationLastRead?: string,
): Promise<ResponseProps<{ message: string; notifications: UserNotificationOutputProps[] }>> => {
  try {
    const body: UpdateUserNotificationInputProps = {
      userNotificationLastRead,
    }
    const { data } = await httpBearerAuth(
      'patch',
      `/api/users/${userId}/notifications`,
      '',
      body as Record<string, unknown>,
    )
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Gets user notifications
 * @param userId - User's ID
 * @returns Promise resolving to ResponseProps containing notifications
 */
export const getUserNotifications = async (
  userId: string,
): Promise<ResponseProps<{ message: string; notifications: UserNotificationOutputProps[] }>> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/users/${userId}/notifications`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Gets user pool information
 * @param userId - User's ID
 * @param comId - Community ID
 * @param userStudentId - Optional user student ID
 * @param studentId - Optional student ID
 * @returns Promise resolving to ResponseProps containing user pool data
 */
export const getUserPool = async (
  userId: string,
  comId: string,
  userStudentId?: string,
  studentId?: string,
): Promise<ResponseProps<{ message: string; userPool: UserPoolOutputProps }>> => {
  try {
    // student ids are only relevant for the really edge case when a user has more than one students in same community as separate registrations
    const { data } = await httpBearerAuth(
      'get',
      `/api/users/${userId}/pool/${comId.toLowerCase()}?userStudentId=${userStudentId}&studentId=${studentId}`,
    )
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Gets all users
 * @returns Promise resolving to ResponseProps containing users data
 */
export const getUsers = async (): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/users`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Uploads user profile photo
 * @param userId - User's ID
 * @param formData - Form data containing photo
 * @returns Promise resolving to ResponseProps
 */
export const uploadUserPhoto = async (userId: string, formData: FormData): Promise<ResponseProps> => {
  try {
    const { data } = await httpBearerAuth('put', `/api/users/${userId}/photo`, '', formData)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Deletes user profile photo
 * @param userId - User's ID
 * @returns Promise resolving to ResponseProps
 */
export const deleteUserPhoto = async (userId: string): Promise<ResponseProps> => {
  try {
    await httpBearerAuth('delete', `/api/users/${userId}/photo`)
    return {}
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}

/**
 * Gets user profile photo
 * @param userId - User's ID
 * @returns Promise resolving to ResponseProps containing photo data
 */
export const getUserPhoto = async (userId: string): Promise<ResponseProps<{ message: string; userPhoto: string }>> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/users/${userId}/photo`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}
