import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

export enum DeviceSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

const useDeviceSize = (): DeviceSize => {
  const theme = useTheme()
  const { breakpoints } = theme

  const isXs = useMediaQuery(breakpoints.down('sm'))
  const isSm = useMediaQuery(breakpoints.down('md'))
  const isMd = useMediaQuery(breakpoints.down('lg'))
  const isLg = useMediaQuery(breakpoints.down('xl'))

  if (isXs) return DeviceSize.xs
  if (isSm) return DeviceSize.sm
  if (isMd) return DeviceSize.md
  if (isLg) return DeviceSize.lg

  return DeviceSize.xl
}

export default useDeviceSize
