import LinearProgress from '@mui/material/LinearProgress'
import BackComponent from 'components/back'
import ButtonMobileComponent from 'components/button-mobile'
import { CalendarComponent, toCalendarEvents } from 'components/calendar'
// import InputMobileComponent from 'components/input-mobile'
import MapPinMobileComponent from 'components/map-pin-mobile'
import PlacesAutocompleteComponent from 'components/places-autocomplete'
import TextAreaComponent from 'components/textarea'
import ToastComponent from 'components/toast'
import Arrow from 'images/arrow_forward.png'
import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import { getFullDate, getLocalDate, getTimeNumber, getTimeString, afterSchoolDisabled } from 'utils/constants'
import { geocode } from 'utils/map'
import { updateStudentAfternoonScheduleSettings, updateStudentAfternoonSettings } from 'utils/students'
import {
  CalendarEventProps,
  CalendarProps,
  CalendarState,
  DateTimeProps,
  LocationProps,
  MessageProps,
  ScheduleGroupProps,
  StudentProps,
  VehicleProps,
} from 'utils/types'
import { isValidComments } from 'utils/validations'
import UnCheckedIcon from '../../images/afternoon_checkbox.svg'
import CheckedIcon from '../../images/Checkbox.svg'
import './index.css'

interface AfternoonMobileComponentProps {
  student: StudentProps
  onUpdate: (cb?: () => void) => void
}

const AfternoonMobileComponent = (props: AfternoonMobileComponentProps) => {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: School Name, Location, Schedule End Time, Student Drop off Location, Activities, Schedule and onUpdate Callbck
  const { student, onUpdate } = props
  const {
    schoolName,
    schoolLocation,
    schoolScheduleGroups,
    studentId,
    scheduleGroupName,
    dropoffLocation,
    dropoffTimes,
  } = student
  const schoolEndTime: number =
    schoolScheduleGroups?.find((v: ScheduleGroupProps) => v?.schoolScheduleGroupName === scheduleGroupName)
      ?.schoolScheduleGroupEndTime ?? 0 // Initialize selected schedule dismissal time.
  const schoolActivities = student?.schoolActivities?.sort() ?? []
  const { lat, lng } = schoolLocation
  const bounds = lat && lng ? [lng - 0.7, lat - 0.7, lng + 0.7, lat + 0.7] : undefined
  const pmDashboardUrl: string = `/students?id=${studentId}&date=${getLocalDate()}`

  // states
  const [studentDropoffLocation, setStudentDropoffLocation] = useState<LocationProps>({
    address: dropoffLocation?.address || '',
  })
  const [dropoffVehicle, setDropoffVehicle] = useState<VehicleProps>(student?.dropoffVehicle ?? 0)
  const [dropoffSeatsFilled /*, setDropoffSeatsFilled*/] = useState<number | null>(student?.dropoffSeatsFilled ?? null)
  const [dropoffSeatsAvailable /*, setDropoffSeatsAvailable*/] = useState<number | null>(
    student?.dropoffSeatsAvailable ?? null,
  )
  const [dropoffComments, setDropoffComments] = useState<string>(student?.dropoffComments ?? '')
  const [studentActivities, setStudentActivities] = useState<string[]>(
    student?.studentActivities?.filter((v: string) => schoolActivities?.includes(v)) ?? [],
  )
  const [dropoffDateTime, setDropoffDateTime] = useState<DateTimeProps>(dropoffTimes ?? {})
  const [dropoffTimeDefault, setDropoffTimeDefault] = useState<number | null | undefined>(student?.dropoffTimeDefault)
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<string>('')
  const [isFullPage, setIsFullPage] = useState(false)
  const [mapHeight, setMapHeight] = useState(false)
  const [key, setKey] = useState<number>(0)
  const [displayCount, setDisplayCount] = useState<number>(30) // State to manage the number of items to display

  // Handler for "Show more" button
  const handleShowMore = () => {
    setDisplayCount((prevCount) => schoolActivities.length)
  }

  // Handler for activity click
  const handleActivityClick = (activity: string) => {
    setStudentActivities(
      (prevActivities) =>
        prevActivities.includes(activity)
          ? prevActivities.filter((act) => act !== activity) // Remove if already selected
          : [...prevActivities, activity], // Add if not selected
    )
  }

  // Calculate the items to be displayed
  const displayedActivities = schoolActivities.slice(0, displayCount)

  // handlers
  const onSubmit = async (): Promise<any> => {
    setIsLoading('Saving...')
    let dropoffLocation: LocationProps = studentDropoffLocation
    if (dropoffLocation.address && (!dropoffLocation?.lat || !dropoffLocation?.lng)) {
      dropoffLocation = await geocode(dropoffLocation.address)
      if (!dropoffLocation?.lat || !dropoffLocation?.lng) {
        setMsg({
          style: 'error',
          text: 'We could not locate this address. Verify and re-enter address.',
        })
        setIsLoading('')
        return
      }
    }
    const { msg, error } = await updateStudentAfternoonSettings(
      schoolName,
      studentId,
      dropoffLocation,
      dropoffVehicle,
      dropoffSeatsFilled,
      dropoffSeatsAvailable,
      dropoffComments,
      studentActivities,
      dropoffTimeDefault,
    )
    if (error) setMsg(msg)
    else onUpdate(() => navigate(pmDashboardUrl))
    setIsLoading('')
  }
  const events: CalendarEventProps[] = toCalendarEvents(schoolEndTime, dropoffDateTime ?? {}, dropoffTimeDefault)

  // handlers
  const onCancel = () => navigate(pmDashboardUrl)

  const handleToggleFullPage = () => {
    setIsFullPage((prev) => !prev)
    setMapHeight((prev) => !prev)
  }

  const isValid: boolean = isValidComments(dropoffComments) && studentDropoffLocation.address.trim().length > 0

  return (
    <>
      {/***************** Display progress and error *****************/}
      {isLoading && <LinearProgress />}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
      {/***************** Map *****************/}
      {isFullPage && (
        <MapPinMobileComponent
          currentLocation={dropoffLocation?.address ? dropoffLocation : schoolLocation}
          mapHeight={mapHeight}
          onClose={handleToggleFullPage}
          customHeight='90vh' // height of the map in mobile
          onSave={(v: LocationProps) => {
            setStudentDropoffLocation(v)
            setKey((prevKey) => prevKey + 1) // Changing the key forces a re-render
            handleToggleFullPage()
          }}
        />
      )}
      <div className='flex flex-col h-screen'>
        <BackComponent text='Afternoon Carpool' onClick={onCancel} />
        <div className='flex p-4 px-5 flex-col items-start gap-3 flex-[1_0_0] self-stretch'>
          {/* Select address */}
          <PlacesAutocompleteComponent
            key={key}
            label='Select Address / Landmark'
            defaultValue={studentDropoffLocation.address}
            bounds={bounds}
            onChange={(v: string) => setStudentDropoffLocation({ address: v })}
            onSubmit={(v: LocationProps) => setStudentDropoffLocation(v)}
            onMapClick={handleToggleFullPage}
            dataTestid='dropoff-afternoon-testid'
          />
          {/* Select activities */}
          {Array.isArray(displayedActivities) && displayedActivities.length > 0 && (
            <div className='mt-1' data-testid='activities-testid'>
              <label className='text-gray-400 text-sm font-medium leading-[1.42857]'>Activities</label>
              <div className='flex flex-wrap mt-4'>
                {displayedActivities.map((activity: string, index: number) => (
                  <span
                    key={index}
                    onClick={() => handleActivityClick(activity)}
                    className={`inline-block text-xs mb-3 mx-2 rounded-xl px-2 py-1 cursor-pointer ${
                      schoolActivities.includes(activity) && studentActivities.includes(activity)
                        ? 'bg-custom-yellow text-gray-800'
                        : 'bg-yellow-100 text-gray-400'
                    }`}
                  >
                    {activity}
                  </span>
                ))}
                {/* Button to load more items */}
                {displayCount < schoolActivities.length && (
                  <div onClick={handleShowMore} className='text-xs text-gray-400 py-1 cursor-pointer'>
                    +{schoolActivities.length - displayCount}
                  </div>
                )}
              </div>
            </div>
          )}
          {/* Update schedule */}
          {!afterSchoolDisabled.includes(schoolName) && (
            <div className='w-full mt-1'>
              <label className='text-gray-400 text-sm font-medium'>Schedule</label>
              <div className='bg-light-yellow mt-3 mb-3 pl-3 border-l-4 border-custom-yellow mb-1 rounded-tr-xl rounded-br-xl '>
                <div
                  className='flex items-center cursor-pointer'
                  onClick={() => setDropoffTimeDefault(dropoffTimeDefault !== -1 ? -1 : null)}
                  data-testid='CheckBoxOutlineBlankIcon'
                >
                  <img
                    src={dropoffTimeDefault === -1 ? CheckedIcon : UnCheckedIcon}
                    alt={dropoffTimeDefault === -1 ? 'Checked' : 'Unchecked'}
                    className='mr-2'
                  />
                  <span className='text-gray-400 text-sm font-medium py-2'>
                    Opt out of carpool at dismissal (
                    <span style={{ color: 'green' }}>{getTimeString(schoolEndTime)}</span>)
                  </span>
                </div>
                <p className='text-gray-600 text-sm font-medium ml-7 pb-2'>Tap dates to set pickup schedule.</p>
              </div>

              <CalendarComponent
                events={events}
                onChange={async (events: CalendarProps[]) => {
                  const payload = events.reduce((acc: Record<string, any>, curr: CalendarProps) => {
                    const d: string = getFullDate(curr.from)
                    const t: number = curr.userAction === CalendarState.DISABLE ? -1 : getTimeNumber(curr.from)
                    return { ...acc, [d]: t }
                  }, {})
                  const { msg, error } = await updateStudentAfternoonScheduleSettings(schoolName, studentId, payload)
                  if (error) setMsg(msg)
                  else {
                    setDropoffDateTime({ ...dropoffDateTime, ...payload })
                    await onUpdate()
                  }
                }}
              />
            </div>
          )}
          {/* Select vehicle */}
          <div className='w-full mt-1 mb-3' data-testid='vehicle-testid'>
            <label className='text-gray-400 text-sm font-medium'>Vehicle</label>
            <div className='bg-light-yellow mt-3 mb-3 pl-3 border-l-4 border-custom-yellow mb-1 rounded-tr-xl rounded-br-xl '>
              <div
                className='flex items-center cursor-pointer'
                onClick={() => {
                  setDropoffVehicle(dropoffVehicle ^ VehicleProps.HIRE_TRANSPORT_SERVICE)
                }}
                data-testid='CheckBoxOutlineBlankIcon'
              >
                <img
                  src={dropoffVehicle & VehicleProps.HIRE_TRANSPORT_SERVICE ? CheckedIcon : UnCheckedIcon}
                  alt={dropoffVehicle & VehicleProps.HIRE_TRANSPORT_SERVICE ? 'Checked' : 'Unchecked'}
                  className='mr-2'
                />
                <span className='text-gray-400 text-sm font-medium py-2'>
                  Are you interested in hiring paid transport service?
                </span>
              </div>
            </div>
            {/* <div style={{ display: 'flex', gap: '16px' }}>
              <InputMobileComponent
                labeltext='Filled Seats'
                type='number'
                max={7} // Maximum value allowed
                min={1} // Minimum value allowed
                value={dropoffSeatsFilled !== null ? dropoffSeatsFilled : ''}
                onChange={(v: any) => setDropoffSeatsFilled(Number(v))}
              />
              <InputMobileComponent
                labeltext='Available Seats'
                type='number'
                max={7} // Maximum value allowed
                min={1} // Minimum value allowed
                value={dropoffSeatsAvailable !== null ? dropoffSeatsAvailable : ''}
                onChange={(v: any) => setDropoffSeatsAvailable(Number(v))}
              />
            </div> */}
          </div>
          {/* Add comments */}
          <div className='w-full mt-1' style={{ marginBottom: 100 }}>
            <TextAreaComponent
              labeltext='Comments'
              placeholder='Enter details to help with carpooling'
              isRequired={false}
              isError={!isValidComments(dropoffComments)}
              value={dropoffComments}
              onChange={(v: string) => setDropoffComments(v)}
            />
          </div>
        </div>
        <div className='fixed bottom-0 left-0 right-0 bg-[#fff] pt-3'>
          <div className='border-b border-gray-200'></div>
          <div className='pt-5 pb-10 px-5'>
            <ButtonMobileComponent
              id='find-school-button'
              data_testid='modal-dialog-action-submit'
              text='Submit'
              color='bg-custom-yellow'
              width='w-full'
              disabled={!isValid}
              onClick={onSubmit}
              iconPosition='right'
              icon={Arrow}
            />
          </div>
        </div>
      </div>
    </>
  )
}
interface Props {
  students: StudentProps[]
  processQuery: (id: string) => string
  onUpdate: (cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams()

  // Inputs: Selected Student Id, and Callback
  const { students, processQuery, onUpdate } = props

  // We should never call this component without student id query search parameters, but this is just to be safe
  const studentIdFromQuery = searchParams.get('id') ?? ''
  useEffect(() => {
    setSearchParams({ id: processQuery(studentIdFromQuery) })
  }, [studentIdFromQuery, processQuery, setSearchParams])

  // Build props for child component
  const student: StudentProps | undefined = students?.find((s: StudentProps) => s.studentId === studentIdFromQuery)

  if (!student) return <LinearProgress />
  return <AfternoonMobileComponent student={student} onUpdate={onUpdate} />
}

export default Index
