import MailOutlineIcon from '@mui/icons-material/MailOutline'
import Box from '@mui/material/Box'
import AppBarComponent from 'components/appbar-custom'
import ButtonMenuComponent from 'components/button-menu'
import { AdminLogoutIcon, AdminSettingsIcon, AnalyticsIcon, GetHelpIcon, UserIcon } from 'components/icons'
import SchoolLogo from 'components/logo-school'
import MenuButton from 'components/menu-button'
import useClickOutside from 'hooks/useClickOutside'
import useDeviceSize, { DeviceSize } from 'hooks/useDeviceSize'
import useIsPath from 'hooks/useIsPath'
import { useRef, useState } from 'react'
import { NavigateFunction, Outlet, useNavigate } from 'react-router-dom'
import { titleCaseSchoolNameForUI } from 'utils/constants'
import csicon from '../../images/csicon.png'
import cslogo from '../../images/cslogo.png'
import './index.css'

interface AdminNavComponentProps {
  schoolName: string
  schoolAdmin: string
  isActive: boolean
}
type AdminPopupMenuComponentProps = Omit<AdminNavComponentProps, 'isActive'>
type AdminDrawerComponentProps = AdminNavComponentProps & { isOpen: boolean; setIsOpen: (open: boolean) => void }

const AdminPopupMenuComponent: React.FC<AdminPopupMenuComponentProps> = ({ schoolName, schoolAdmin }) => {
  const navigate: NavigateFunction = useNavigate()
  const panelRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  // handlers
  const handleNavigate = (url: string) => {
    setIsOpen(false)
    navigate(url)
  }

  //hooks
  useClickOutside(panelRef, () => setIsOpen(false))

  return (
    <div ref={panelRef} data-testid='nav-icon-testid-right' className='relative'>
      {/* Admin school logo image */}
      <button className='flex items-center mr-6' onClick={isOpen ? () => setIsOpen(false) : () => setIsOpen(true)}>
        <SchoolLogo schoolName={schoolName} className='w-9 h-9 rounded-full shadow-sm object-cover' />
      </button>
      {/* Admin Profile Menu*/}
      {isOpen && (
        <div className='absolute top-full right-0 mt-2 min-w-[200px] flex flex-col items-start pb-2 bg-white rounded-lg shadow-lg border border-gray-300 z-10'>
          <div className='flex flex-col w-full'>
            <ButtonMenuComponent
              name={schoolAdmin}
              leftIcon={<MailOutlineIcon color='info' />}
              onClick={() => handleNavigate('/admin/settings')}
            />
            <hr className='w-full border-t border-gray-300 my-1' />
            <ButtonMenuComponent
              name='Carpool Settings'
              leftIcon={<AdminSettingsIcon />}
              onClick={() => handleNavigate('/admin/settings')}
            />
            {/* Sign out */}
            <div
              data-testid='logout-testid'
              onClick={() => handleNavigate('/admin/signout')}
              className='text-center text-sm bg-red-100 text-red-400 mt-1 py-2 cursor-pointer'
            >
              Sign out
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const AdminDrawerComponent: React.FC<AdminDrawerComponentProps> = ({
  schoolName,
  schoolAdmin,
  isActive,
  isOpen,
  setIsOpen,
}) => {
  const navigate: NavigateFunction = useNavigate()

  // hooks
  // device
  const deviceSize: DeviceSize = useDeviceSize()
  const isMobile = deviceSize === DeviceSize.xs // Mobile: < 600px
  const isTablet = deviceSize === DeviceSize.sm || deviceSize === DeviceSize.md // Tablet: 600px - 1200px
  const isDesktop = deviceSize === DeviceSize.lg || deviceSize === DeviceSize.xl // Desktop: >= 1200px
  // path
  const isDashboardPage = useIsPath(['/admin/dashboard'])
  const isFamiliesPage = useIsPath(['/admin/users'])
  const isSettingsPage = useIsPath(['/admin/settings'])
  // panel
  const panelRef = useRef<HTMLDivElement>(null)
  useClickOutside(panelRef, () => setIsOpen(false))  

  const handleNavigate = (url: string) => {
    setIsOpen(false) // Only applicable for mobile
    if (url.startsWith('http://') || url.startsWith('https://')) {
      window.open(url, '_blank') // Open external URL in a new tab
    } else {
      navigate(url) // Navigate within the SPA
    }
  }

  return (
    <div className='flex'>
      {/*=========== Sidebar ============*/}
      <div
        ref={panelRef}
        data-testid='nav-icon-testid-left'
        className={`bg-white py-4 pl-0 fixed top-0 left-0 h-full z-50
          transition-all duration-300 ${isDesktop ? 'w-64' : isTablet ? 'w-16' : isOpen ? 'w-64' : 'w-0'}
        `}
      >
        {/*==========CARPOOL LOGO========*/}
        <a href='/admin/settings' className='flex items-center no-underline pointer-events-none'>
          <img
            src={isDesktop || isMobile ? cslogo : isTablet ? csicon : undefined}
            alt='Carpool.School Logo and Brand'
            className={`cursor-pointer pointer-events-auto transition-opacity duration-300 hover:opacity-80 ml-3 ${
              isDesktop || isMobile ? 'w-[220px]' : isTablet ? 'w-10 h-10' : ''
            }`}
          />
        </a>
        <div
          className='flex flex-col w-full h-full mt-4'
          style={{ marginTop: '20px', gap: '10px' }}
          data-testid='drawer-testid'
        >
          {isActive && (
            <>
              <ButtonMenuComponent
                data_testid='analytics-testid'
                name={!isTablet && (isOpen || !isMobile) ? 'Analytics' : ''}
                leftIcon={isDesktop || (isMobile && isOpen) || isTablet ? <AnalyticsIcon /> : undefined}
                style={{ backgroundColor: isDashboardPage ? '#FFFEE6' : undefined }}
                onClick={() => handleNavigate('/admin/dashboard')}
              />
              <ButtonMenuComponent
                data_testid='families-testid'
                name={isDesktop || (isMobile && isOpen) ? 'Families' : ''}
                leftIcon={isDesktop || (isMobile && isOpen) || isTablet ? <UserIcon /> : undefined}
                style={{ backgroundColor: isFamiliesPage ? '#FFFEE6' : undefined }}
                onClick={() => handleNavigate('/admin/users')}
              />
            </>
          )}
          <ButtonMenuComponent
            data_testid='settings-testid'
            name={isDesktop || (isMobile && isOpen) ? 'Carpool Settings' : ''}
            leftIcon={isDesktop || (isMobile && isOpen) || isTablet ? <AdminSettingsIcon /> : undefined}
            style={{ backgroundColor: isSettingsPage ? '#FFFEE6' : undefined }}
            onClick={() => handleNavigate('/admin/settings')}
          />
          {/***==============SIGN OUT============***/}
          <div
            className='mt-auto'
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%', // Ensure it spans the width of the sidebar
              paddingBottom: '20px', // Add some padding for visual spacing
            }}
          >
            <ButtonMenuComponent
              name={isDesktop || (isMobile && isOpen) ? 'Get Help' : ''}
              leftIcon={isDesktop || (isMobile && isOpen) || isTablet ? <GetHelpIcon /> : undefined}
              onClick={() => handleNavigate('https://youtube.com/@Carpool.School')}
            />
            <hr className='w-full border-t border-gray-300 my-1' />
            <div className='flex ml-2 mt-3 items-center'>
              {(isDesktop || (isMobile && isOpen)) && (
                <>
                  <SchoolLogo schoolName={schoolName} className='w-9 h-9 rounded-full shadow-sm object-cover' />
                  <div className='ml-2 max-w-[150px] overflow-hidden whitespace-nowrap text-ellipsis'>
                    <div className='text-headings font-bold text-md'>{titleCaseSchoolNameForUI(schoolName)}</div>
                    <div className='text-headings text-sm'>{schoolAdmin}</div>
                  </div>
                  <div className='ml-3 cursor-pointer' onClick={() => handleNavigate('/admin/signout')}>
                    <AdminLogoutIcon />
                  </div>
                </>
              )}
              {isTablet && (
                <div className='ml-3 cursor-pointer' onClick={() => handleNavigate('/admin/signout')}>
                  <AdminLogoutIcon />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div
        className={`flex-1 transition-all duration-300 ${isDesktop ? 'pl-64' : isTablet ? 'pl-16' : 'pl-0'}`}
      ></div>
    </div>
  )
}

export const WithAdminNav: React.FC<AdminNavComponentProps> = ({ schoolName, schoolAdmin, isActive }) => {
  // hooks
  // device
  const deviceSize: DeviceSize = useDeviceSize()
  const isMobile = deviceSize === DeviceSize.xs // Mobile: < 600px
  // path
  const isDashBoardPage = useIsPath(['/admin/dashboard'])
  const isFamiliesPage = useIsPath(['/admin/users'])
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className='flex'>
      {/* Sidebar */}
      {(!isMobile || isOpen) && (
        <AdminDrawerComponent
          schoolName={schoolName}
          schoolAdmin={schoolAdmin}
          isActive={isActive}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}

      {/* Main Content Section */}
      <div className='flex-1'>
        <AppBarComponent>
          {/* Left Aligned Items - Brand Logo and Title */}
          <div className='flex items-center'>
            {/* Brand Logo */}
            {isMobile && (
              <div onClick={() => setIsOpen(!isOpen)}>
                <MenuButton />
              </div>
            )}
            {/* Title */}
            <div style={{ marginLeft: '25px' }}>
              {isDashBoardPage && <div className='text-xl font-medium'>Analytics</div>}
              {isFamiliesPage && <div className='text-xl font-medium'>Families</div>}
            </div>
          </div>

          {/* Right Aligned Items - Admin Menu Actions */}
          <AdminPopupMenuComponent schoolName={schoolName} schoolAdmin={schoolAdmin} />
        </AppBarComponent>

        <Box
          component='main'
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            minHeight: '100vh',
            overflow: 'auto',
            paddingTop: 2,
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          <Outlet />
        </Box>
      </div>
    </div>
  )
}
