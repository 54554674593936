import HorizontalScrollContainerComponent from 'components/horizontal-scroll-container'
import { MessageSendIcon } from 'components/icons'
import React, { useEffect, useRef, useState } from 'react'
import { isValidMessageText } from 'utils/validations'

interface RoomMsgTextAreaComponentProps {
  onSubmit: (newValue: string) => void
  suggestedMessages?: string[] // Optional prop for suggested messages
  placeholder?: string
}

const RoomMsgTextAreaComponent: React.FC<RoomMsgTextAreaComponentProps> = ({
  onSubmit,
  suggestedMessages = [],
  placeholder,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const [isError, setIsError] = useState(false)
  const [value, setValue] = useState<string>('')
  const [selectedSuggestion, setSelectedSuggestion] = useState<string | null>(null) // Track selected message

  useEffect(() => {
    // Initialize height once on mount
    const target = textareaRef.current
    if (target) {
      target.style.height = '48px' // Initial height
    }
  }, [])

  const handleInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const target = e.currentTarget
    target.style.height = '48px' // Reset to initial height to recalculate
    target.style.height = `${Math.max(target.scrollHeight, 48)}px` // Set height based on content
    setIsError(!isValidMessageText(target.value))
    setValue(target.value)
    setSelectedSuggestion(null) // Clear the selected suggestion when typing
  }

  const handleSuggestionClick = (suggestion: string) => {
    setValue(suggestion)
    setSelectedSuggestion(suggestion)
  }

  return (
    <div className='w-full'>
      {/* Suggested Messages */}
      {suggestedMessages.length > 0 && (
        <HorizontalScrollContainerComponent className='mb-4'>
          {suggestedMessages.map((suggestion, index) => (
            <span
              key={index}
              onClick={() => handleSuggestionClick(suggestion)} // Set textarea value and highlight on click
              className={`text-xs rounded-xl px-2 py-1 cursor-pointer ${
                selectedSuggestion === suggestion
                  ? 'bg-custom-yellow text-gray-800'
                  : 'bg-light-yellow border-custom-yellow border text-gray-800'
              }`}
            >
              {suggestion}
            </span>
          ))}
        </HorizontalScrollContainerComponent>
      )}

      {/* Text Area and Send Button */}
      <div className='flex items-center w-full gap-3' data-testid='messaging-testid'>
        <textarea
          ref={textareaRef}
          className={`w-full rounded-lg border focus:outline-none h-36 px-6 py-3 ${isError ? 'border-red-500 bg-red-50' : 'border-gray-300 bg-white focus:border-custom-yellow'}`}
          style={{ overflow: 'auto', resize: 'none', minHeight: '48px', maxHeight: '200px' }}
          onInput={handleInput}
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)} // Update value on change
          data-testid='text-area-testid'
        />
        {value.trim().length >= 0 && !isError && (
          <button
            data-testid='send-message-button-testid'
            onClick={() => {
              // Handle send action
              if (textareaRef.current) {
                onSubmit(value)
                setValue('') // Clear the textarea after submit
                setSelectedSuggestion(null) // Clear selected suggestion after submit
                textareaRef.current.value = ''
                textareaRef.current.style.height = '48px' // Reset to initial height
              }
            }}
            disabled={value.trim().length === 0}
          >
            <MessageSendIcon disabled={value.trim().length === 0} />
          </button>
        )}
      </div>

      {/* Hide scroll bar for Webkit browsers */}
      <style>
        {`
          .overflow-x-auto::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
    </div>
  )
}

export default RoomMsgTextAreaComponent
