import CloseIcon from '@mui/icons-material/Close'
import Alert, { AlertColor } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'
import './index.css'
import { useTheme } from '@mui/material/styles'

const ToastComponent = ({
  duration,
  style,
  heading,
  text,
  onClose,
}: {
  duration?: number
  style?: AlertColor
  heading?: string
  text?: string
  onClose?: () => void
}): JSX.Element => {
  const theme = useTheme() // Access theme colors
  const handleClose = () => typeof onClose === 'function' && onClose()
  const borderColor = style ? theme.palette[style]?.main || 'rgba(0, 0, 0, 0.12)' : 'rgba(0, 0, 0, 0.12)'

  const children: JSX.Element = (
    <Snackbar
      open
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
      autoHideDuration={duration}
      data-testid='toast-message'
      sx={{
        top: `env(safe-area-inset-top, 0px)`, // Apply safe-area-inset-top for top spacing
        transform: 'translateY(16px)', // Add additional margin below the safe area
        '& .MuiPaper-root': {
          borderRadius: '8px',
          border: `1px solid ${borderColor}`,
        },
      }}
      action={<CloseIcon fontSize='small' color='inherit' onClick={handleClose} />}
    >
      <Alert
        severity={style}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& .MuiAlert-icon': {
            marginTop: 0,
            alignSelf: 'center',
          },
          '& .MuiAlert-action': {
            marginTop: 0,
            alignSelf: 'center',
            padding: '0 0 0 16px',
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '0.9375rem',
            lineHeight: 1.5,
            letterSpacing: '0.01em',
            alignSelf: 'center',
          }}
        >
          {heading && text ? `${heading}: ${text}` : heading || text || ''}
        </Typography>
      </Alert>
    </Snackbar>
  )
  return children
}

export default ToastComponent
