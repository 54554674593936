import { DailySchoolUsersProps } from 'types/SchoolTypes'

// Blue (#3182fb): #1877f2 > #6FC0F7 > #D0ECFE
// Purple (#7b39c0): #8E33FF > #C289FF > #EFD6FF
// Yellow (#FFAB00): #FFAB00 > #FFD066 > #FFF5CC
// Red (#FF5630): #FF5630 > #FFA083 > #FFE9D5

/**
 * Color Palette
 */
const colorPalette = [
  '#3182fb',
  '#ffaa00',
  '#7b39c0',
  '#ff5d33',
  '#40E0D0',
  '#FF96C5',
  '#a6cee3',
  '#1f78b4',
  '#b2df8a',
  '#33a02c',
  '#fb9a99',
  '#e31a1c',
  '#ffcc00',
  '#ff66b2',
  '#66c2a5',
  '#c2e699',
  '#e31a4c',
  '#6a3d9a',
  '#b15928',
  '#fdbf6f',
  '#b2df8a',
  '#cab2d6',
  '#ffff99',
  '#1b9e77',
  '#d95f02',
]

const generateRandomColor = (usedColors: Set<string>) => {
  let color
  do {
    color = `#${Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, '0')}`
  } while (usedColors.has(color)) // Ensure distinct colors
  usedColors.add(color)
  return color
}

export const getChartColors = (data: any[], chartIndex: number = 0) => {
  const startIndex = chartIndex % colorPalette.length
  const selectedColors = [...colorPalette.slice(startIndex), ...colorPalette.slice(0, startIndex)]
  const usedColors = new Set(selectedColors)
  return data.map((_, index) =>
    index < selectedColors.length ? selectedColors[index] : generateRandomColor(usedColors),
  )
}

/**
 * Pie Chart Series
 */
export type PieChartDataType = { label: string; value: number }
export const getPieChartData = (o: Record<string, number> = {}): PieChartDataType[] => {
  return Object.keys(o)
    .map((v) => ({ label: v, value: o[v] }))
    .sort((a: PieChartDataType, b: PieChartDataType) => b.value - a.value)
}

/**
 * Stacked Column Chart Series
 */
export type ColumnChartDataType = {
  type: string
  name: string
  data: { label: string; value: number }[]
  color: string
}
export const getRegisteredUsersOverTimeColumnChartData = (a: DailySchoolUsersProps[] = []): ColumnChartDataType[] => {
  if (!a || a.length === 0) return []

  const labels: string[] = a.map(({ date }) => date).sort((a, b) => a.localeCompare(b))

  return [
    {
      type: 'column',
      name: 'Active',
      data: labels.map((label) => ({
        label,
        value: a.find((item) => item.date === label)?.activeUsers ?? 0,
      })),
      color: '#C289FF',
    },
    {
      type: 'column',
      name: 'Inactive',
      data: labels.map((label) => ({
        label,
        value: Math.max(
          0,
          (a.find((item) => item.date === label)?.registeredUsers ?? 0) -
            (a.find((item) => item.date === label)?.activeUsers ?? 0),
        ),
      })),
      color: '#6FC0F7',
    },
  ]
}

export const getUsersByMessageSentCount = (a: number[] = []): ColumnChartDataType[] => {
  if (!a || a.length === 0) {
    return []
  }

  // Determine the maximum message count to calculate bins
  const maxCount = Math.max(...a)

  // Determine bin width based on desired number of bins
  const desiredBins = 8 // Example: Aim for 8 bins (adjust as needed)
  const binWidth = Math.ceil(maxCount / desiredBins)
  const bins: { [key: string]: number } = {}

  // Bin the message counts
  a.forEach((count) => {
    const binStart = Math.floor(count / binWidth) * binWidth
    const binEnd = binStart + binWidth - 1
    const binLabel = `${binStart}-${binEnd}`

    bins[binLabel] = (bins[binLabel] || 0) + 1
  })

  return [
    {
      type: 'column',
      name: 'Families',
      data: Object.entries(bins).map(([label, value]) => ({
        label,
        value,
      })),
      color: '#FFF5CC',
    },
  ]
}
