import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'
import { Button, Dialog, DialogTitle, styled } from '@mui/material'
import Box from '@mui/material/Box'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid'
import ModalConfirmComponent from 'components/modal-confirm'
import ToastComponent from 'components/toast'
import Papa from 'papaparse'
import * as React from 'react'
import { AddUserProps, MessageProps } from 'utils/types'
import AddUsers from './AddUsers'
import CircularProgressWithLabel from './CircularProgressLabel'
import FileUpload from './FileUpload'

export interface CustomToolbarProps extends GridToolbarProps {
  scheduleGroupNames?: string[] // Add your custom props here
  isRowsSelected?: boolean
  onAddSelected?: any
  onUploadSelected?: any
  onDeleteSelected?: any
  onSendSelected?: any
  csvOptions?: any
  printOptions?: any
}
const ToolbarContainer = styled(GridToolbarContainer)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export default function CustomToolbar(props: CustomToolbarProps) {
  const {
    scheduleGroupNames,
    isRowsSelected,
    onAddSelected,
    onUploadSelected,
    onDeleteSelected,
    onSendSelected,
    csvOptions,
    printOptions,
  } = props
  const [delOpen, setDelOpen] = React.useState(false)
  const [sendOpen, setSendOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState<string>('')
  const [msg, setMsg] = React.useState<MessageProps>()

  // Function to open dialog for delete confirmation
  const handleDelClick = () => {
    setDelOpen(true)
  }

  // Function to close delete confirmation dialog
  const handleDelClose = () => {
    setDelOpen(false)
  }

  // Function to handle delete button click
  const handleDelSubmit = async () => {
    setDelOpen(false)
    setIsLoading('Deleting Family Users')
    await onDeleteSelected()
    setIsLoading('')
  }

  // Function to open dialog for send account link confirmation
  const handleSendClick = () => {
    setSendOpen(true)
  }

  // Function to close end account link confirmation dialog
  const handleSendClose = () => {
    setSendOpen(false)
  }

  // Function to handle send account link button click
  const handleSendSubmit = async () => {
    setSendOpen(false)
    setIsLoading('Sending Account Activation Link to New Users')
    await onSendSelected()
    setIsLoading('')
  }

  // Function to handle file upload
  const handleFileUpload = (file: File) => {
    const reader = new FileReader()
    reader.onloadend = async (event) => {
      if (event.target) {
        const csvData = event.target.result as string
        // Parse CSV data using PapaParse
        Papa.parse(csvData, {
          complete: async (result: any) => {
            setIsLoading('Uploading Family Users')
            await onUploadSelected(result.data)
            setIsLoading('')
          },
          header: true, // Assuming the CSV has a header row
        })
      }
    }
    reader.readAsText(file)
  }

  // Function to handle file upload
  const handleAdd = async (data: AddUserProps, sendSMS: boolean) => {
    setIsLoading('Adding Family User')
    await onAddSelected(data, sendSMS)
    setIsLoading('')
  }

  return (
    <ToolbarContainer>
      {/* Loading indicator for backend API calls */}
      {isLoading && (
        <Dialog open={true}>
          <DialogTitle>{isLoading}</DialogTitle>
          <CircularProgressWithLabel />
        </Dialog>
      )}

      {/* Buttons for managing columns, filters, and density */}
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />

      {/* Export button */}
      <GridToolbarExport csvOptions={csvOptions} printOptions={printOptions}></GridToolbarExport>

      {/* Upload component */}
      <FileUpload isDisable={isRowsSelected ?? false} onUpload={handleFileUpload} />
      {/* Add user component */}
      <AddUsers isDisable={isRowsSelected ?? false} scheduleGroupNames={scheduleGroupNames} onAdd={handleAdd} />

      {/* Delete button */}
      <Button
        disabled={!isRowsSelected}
        color='primary'
        onClick={handleDelClick}
        sx={{ fontSize: 13 }}
        data-testid='bulk-deletion-button'
      >
        <DeleteOutlinedIcon sx={{ fontSize: 20 }} />
        Delete
      </Button>
      {/* Delete confirmation dialog box */}
      <ModalConfirmComponent
        isShow={delOpen}
        title={`Remove Users?`}
        body={`Users will lose access to your school and its carpool network`}
        actionIcon={'redInfoIcon'}
        actionText={'Confirm'}
        onActionCancel={handleDelClose}
        onActionSubmit={handleDelSubmit}
      />

      {/* Send activation link button */}
      <Button
        disabled={!isRowsSelected}
        color='primary'
        onClick={handleSendClick}
        sx={{ fontSize: 13 }}
        data-testid='send-link-button'
      >
        <SmsOutlinedIcon sx={{ fontSize: 20 }} />
        Activate
      </Button>
      {/* Send activation link confirmation dialog box */}
      <ModalConfirmComponent
        isShow={sendOpen}
        title={`Send Activation Link?`}
        body={`Sends an SMS with an activation link to selected users who haven’t created an account yet`}
        actionIcon={'yellowSendIcon'}
        actionText={'Send'}
        onActionCancel={handleSendClose}
        onActionSubmit={handleSendSubmit}
      />

      <Box sx={{ flexGrow: 1 }} />

      <GridToolbarQuickFilter />
      {/* ToastComponent */}
      {msg && <ToastComponent style={msg.style} text={msg.text} onClose={() => setMsg(undefined)} />}
    </ToolbarContainer>
  )
}
