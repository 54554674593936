import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import AvatarComponent from 'components/avatar'
import { ActiveIcon, AfternoonIcon, CheckIcon, InactiveIcon, MorningIcon } from 'components/icons'
import TableComponent from 'components/table'
import TextComponent from 'components/text'
import ToastComponent from 'components/toast'
import { parsePhoneNumber } from 'libphonenumber-js/max'
import { useState } from 'react'
import { SchoolUsersStudentProps } from 'types/SchoolTypes'
import { defaultPhoneNumberCountry, titleCase, titleCaseSchoolNameForUI } from 'utils/constants'
import { deleteStudents, updateStudents } from 'utils/students'
import {
  AddUserProps,
  MessageProps,
  ScheduleGroupProps,
  SchoolUsersProps,
  UpdateStudentProps,
  VehicleProps,
} from 'utils/types'
import { isValidName, isValidPhone, isValidScheduleGroup } from 'utils/validations'
import './index.css'

function Index(props: {
  schoolUsers?: SchoolUsersProps | null
  failedAvatarUrls: Set<string>
  onFailedAvatarUrl: (url: string) => void
  onUpdate: (cb?: () => void) => void
}): JSX.Element {
  const { schoolUsers, failedAvatarUrls, onFailedAvatarUrl, onUpdate } = props ?? {}
  const { schoolName = '', schoolScheduleGroups, students } = schoolUsers ?? {}
  const [msg, setMsg] = useState<MessageProps>()
  // Constants to store transformed values
  const fileName = `${titleCaseSchoolNameForUI(schoolName)} Family Users`
  // State to store the array of selected user IDs
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  // Function to add user
  const addUser = async (data: AddUserProps, sendSMS?: boolean) => {
    if (students?.some((v: SchoolUsersStudentProps) => v.userPhoneNumber === data.userPhoneNumber)) {
      setMsg({
        style: 'error',
        heading: 'Add User Failed - Existing User',
        text: `The user you are trying to add already exists in the system.
        If you have any issues or questions, please contact our support team at support@carpool.school.`,
      })
    } else {
      const s: UpdateStudentProps = { ...data, userRelationship: 'Other' }
      const { msg, error } = await updateStudents([s], sendSMS ? 'SEND' : 'SUPPRESS')
      if (error) setMsg(msg)
      else {
        // Trigger onUpdate callback to update UI
        await onUpdate()
        // If update is successful, display success message and refresh the user table
        setMsg({ style: 'success', text: `User has been added successfully.` })
      }
    }
  }

  // Function to upload selected users
  const uploadUsers = async (data: any) => {
    const erroredRows: number[] = [] // Array to hold validation results
    const students: UpdateStudentProps[] = [] // Array to hold payload
    for (let i = 0; i < data.length; i++) {
      const { Phone, Name, Schedule } = data[i]
      const scheduleGroupName: string =
        schoolScheduleGroups?.length === 1 ? schoolScheduleGroups[0].schoolScheduleGroupName : Schedule
      if (!Phone) continue
      // Validate userPhoneNumber and userName
      if (
        !isValidPhone(Phone, true) ||
        !isValidName(Name, true) ||
        !isValidScheduleGroup(
          schoolScheduleGroups?.map((v: ScheduleGroupProps) => v.schoolScheduleGroupName) ?? [],
          scheduleGroupName,
          true,
        )
      )
        erroredRows.push(i + 2)
      else {
        students.push({
          userPhoneNumber: parsePhoneNumber(Phone, defaultPhoneNumberCountry).format('E.164'),
          userName: Name,
          scheduleGroupName,
          userRelationship: 'Other',
        })
      }
    }
    // If there are validation errors, display them and do not call backend
    if (erroredRows.length > 0) {
      let text: string
      if (erroredRows.length > 7)
        text = `Your CSV file contains invalid entries in ${erroredRows.length} rows (${erroredRows.slice(0, 7).join(', ')} and more). For help, contact support@carpool.school.`
      else if (erroredRows.length > 1)
        text = `Invalid entries detected in rows: ${erroredRows.join(', ')}. For help, contact support@carpool.school.`
      else
        text = `Invalid entry detected in row ${erroredRows[0]} of your CSV file. For assistance, contact support@carpool.school.`
      setMsg({
        style: 'error',
        heading: 'Upload Failed - Invalid File',
        text,
      })
    } else if (!students?.length) {
      setMsg({
        style: 'error',
        heading: 'Upload Failed - Invalid File',
        text: `Your CSV file appears empty or invalid. For help, contact support@carpool.school.`,
      })
    } else if (students?.length <= 100) {
      // If all rows pass validations,call updateStudents API to add or update users
      const { msg, error } = await updateStudents(students, 'SUPPRESS')
      if (error) {
        // If update is errored, display error message
        setMsg(msg)
      } else {
        // Trigger onUpdate callback to update UI
        await onUpdate()
        // If update is successful, display success message and refresh the user table
        setMsg({
          style: 'success',
          heading: `Upload Complete`,
          text: `${students.length} users have been added or updated.`,
        })
      }
    } else {
      setMsg({
        style: 'error',
        heading: 'Upload Failed - Exceeded Maximum Rows',
        text: `Your CSV file exceeds the maximum limit of 100 rows. Please upload a file with 100 or fewer rows. For further assistance, contact support@carpool.school.`,
      })
    }
  }

  // Function to delete selected users
  const deleteUsers = async () => {
    // Call deleteStudents API to delete selected users
    const { msg, error } = await deleteStudents(selectedIds)

    // Handle response from the API
    if (error) {
      setMsg(msg)
    } else {
      // Trigger onUpdate callback to update UI
      await onUpdate()
      // If deletion is successful, set success message
      setMsg({
        style: 'success',
        text: 'Users deleted.',
      })
    }
  }

  // Function to send account link to selected users
  const sendLinkToUsers = async () => {
    // Call updateStudents API to send account activation link to users
    const selectedStudents: UpdateStudentProps[] =
      students
        ?.filter((v: SchoolUsersStudentProps) => selectedIds.includes(v.studentId))
        .map((v: SchoolUsersStudentProps) => {
          const { userPhoneNumber, userName, scheduleGroupName } = v
          return { userPhoneNumber, userName, scheduleGroupName }
        }) ?? []
    const { msg, error } = await updateStudents(selectedStudents, 'RESEND')
    if (error) {
      // If send is errored, display error message
      setMsg(msg)
    } else {
      // Clear selectedIds after successful deletion
      await setSelectedIds([])
      // If send is successful, display success message and refresh the user table
      setMsg({
        style: 'warning',
        text: `Activation link sent to users without existing accounts.`,
      })
    }
  }

  // Function to handle selection change in the data grid
  const handleSelectionChange = (selection: string[]) => {
    setSelectedIds(selection)
  }

  const header: (GridColDef & { visible: boolean })[] = [
    {
      field: 'avatar',
      headerName: 'Avatar',
      headerAlign: 'center',
      type: 'string',
      sortable: false,
      editable: false,
      filterable: false,
      hideable: true,
      visible: true,
      groupable: false,
      disableExport: true,
      width: 100,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <AvatarComponent
            name={params.row.name}
            photoLink={params.row.photoLink}
            failedPhotoLinks={failedAvatarUrls}
            onFailedPhotoLink={onFailedAvatarUrl}
          />
        </div>
      ),
    },
    {
      field: 'phone',
      headerName: 'Phone',
      headerAlign: 'left',
      type: 'string',
      sortable: true,
      sortComparator: (v1, v2) => v1.localeCompare(v2), // String comparator for phone numbers
      editable: false,
      filterable: false,
      hideable: true,
      visible: false,
      groupable: false,
      disableExport: false,
      width: 150,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <TextComponent size='p'>{params.row.phone}</TextComponent>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      type: 'string',
      sortable: true,
      sortComparator: (v1, v2) => v1.localeCompare(v2), // String comparator for names
      editable: false,
      filterable: false,
      hideable: true,
      visible: true,
      groupable: false,
      disableExport: false,
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <TextComponent size='p'>{titleCase(params.row.name)}</TextComponent>
      ),
    },
    {
      field: 'isVerified',
      headerName: 'Registered',
      headerAlign: 'center',
      type: 'boolean',
      sortComparator: (v1, v2) => {
        const weight = (v: any) => {
          if (v) return 1
          return 0
        }
        return weight(v1) - weight(v2)
      },
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      visible: true,
      groupable: false,
      disableExport: false,
      width: 120,
      renderCell: (params: GridRenderCellParams<any, boolean>) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          {params.row.isVerified ? <CheckIcon /> : '-'}
        </div>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      type: 'string',
      sortable: true,
      sortComparator: (v1, v2) => {
        const weight = (v: any) => {
          if (v === 'Active') return 2
          if (v === 'Inactive') return 1
          return 0
        }
        return weight(v1) - weight(v2)
      },
      editable: false,
      filterable: false,
      hideable: true,
      visible: true,
      groupable: false,
      disableExport: false,
      width: 120,
      align: 'center',
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          {params.row.status === 'Active' ? <ActiveIcon /> : params.row.status === 'Inactive' ? <InactiveIcon /> : '-'}
        </div>
      ),
    },
    {
      field: 'messagesSent',
      headerName: 'Messages Sent',
      headerAlign: 'center',
      type: 'string',
      sortable: true,
      sortComparator: (v1, v2) => v1-v2, // String comparator for names
      editable: false,
      filterable: false,
      hideable: true,
      visible: true,
      groupable: false,
      disableExport: false,
      width: 120,
      align: 'center',
      renderHeader: (params) => (
        <span style={{ whiteSpace: 'normal', lineHeight: '1.2', textAlign: 'center', display: 'block' }}>
          {params.colDef.headerName}
        </span>
      ),
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          {params.row.messagesSent}
        </div>
      ),
    },
    {
      field: 'paidTransport',
      headerName: 'Needs Paid Transport?',
      headerAlign: 'center',
      valueGetter: (v: { isPickup: boolean; isDropoff: boolean }) => {
        if (v.isPickup && v.isDropoff) return 'Pickup & Dropoff'
        if (v.isPickup) return 'Pickup Only'
        if (v.isDropoff) return 'Dropoff Only'
        return 'None'
      },
      sortComparator: (v1: string, v2: string) => {
        const weight = (status: string) => {
          if (status === 'Pickup & Dropoff') return 2
          if (status === 'Pickup Only' || status === 'Dropoff Only') return 1
          return 0
        }
        return weight(v1) - weight(v2)
      },
      sortable: true,
      editable: false,
      filterable: false,
      hideable: true,
      visible: true,
      groupable: false,
      disableExport: false,
      width: 120,
      renderHeader: (params) => (
        <span style={{ whiteSpace: 'normal', lineHeight: '1.2', textAlign: 'center', display: 'block' }}>
          {params.colDef.headerName}
        </span>
      ),
      renderCell: (params: GridRenderCellParams<any, boolean>) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', gap: '8px' }}>
          <MorningIcon hidden={!params.row.paidTransport.isPickup} />
          <AfternoonIcon hidden={!params.row.paidTransport.isDropoff} />
        </div>
      ),
    },
  ]
  if (schoolScheduleGroups && schoolScheduleGroups?.length > 1) {
    header.splice(3, 0, {
      field: 'schedule',
      headerName: 'Schedule',
      type: 'string',
      sortable: true,
      sortComparator: (v1, v2) => v1.localeCompare(v2), // String comparator for schedule group names
      editable: false,
      filterable: false,
      hideable: true,
      visible: true,
      groupable: false,
      disableExport: false,
      width: 120,
    })
  }
  const body: GridRowsProp =
    students
      ?.filter((v: SchoolUsersStudentProps) => v.userRelationship !== 'SchoolAdmin')
      .map((v: SchoolUsersStudentProps, i: number) => {
        const {
          studentId,
          userPhoneNumber,
          userName = '',
          userPhotoLink,
          userSentMessages,
          scheduleGroupName,
          pickupVehicle,
          dropoffVehicle,
          isVerified,
          isPickup,
          isDropoff,
        } = v
        const phone: string = parsePhoneNumber(userPhoneNumber).formatNational()
        const paidTransport = {
          isPickup: pickupVehicle === VehicleProps.HIRE_TRANSPORT_SERVICE,
          isDropoff: dropoffVehicle === VehicleProps.HIRE_TRANSPORT_SERVICE,
        }
        const status: string = isVerified ? (!isPickup && !isDropoff ? 'Inactive' : 'Active') : '' // No status for users who have never signed in
        return {
          id: studentId,
          phone,
          name: userName,
          photoLink: userPhotoLink,
          messagesSent: userSentMessages,
          schedule: scheduleGroupName,
          paidTransport,
          isVerified,
          status,
        }
      }) ?? []

  if (!schoolUsers) return <LinearProgress />

  return (
    <Box px={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }} py={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}>
      {/***************** Show school name in Title *****************/}
      <div className='mb-12'></div>
      {/***************** Display messages in Toast *****************/}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
      {/***************** List users in Table *****************/}
      {body && (
        <TableComponent
          header={header}
          body={body}
          isExpandableRow={false}
          selectedIds={selectedIds}
          onSelectionChange={handleSelectionChange}
          scheduleGroupNames={schoolScheduleGroups?.map((v: ScheduleGroupProps) => v.schoolScheduleGroupName)}
          onAddSelected={addUser}
          onUploadSelected={uploadUsers}
          onDeleteSelected={deleteUsers}
          onSendSelected={sendLinkToUsers}
          csvOptions={{ allColumns: true, fileName }}
          printOptions={{ disableToolbarButton: true }}
        />
      )}
    </Box>
  )
}

export default Index
