import axios, { AxiosBasicCredentials } from 'axios'
import { baseURL, getItem, isProd, isWeb, setItem } from 'utils/constants'
import { MessageProps } from 'utils/types'
import { rum } from './rum'

const axiosInstance1 = axios.create()
const axiosInstance2 = axios.create()
const timeout = 5000
const validateStatus = (status: number): boolean => status < 300

export const http = async (
  method: string,
  url: string,
  schoolName?: string,
  body?: Record<string, unknown>,
): Promise<any> => {
  const res = await axiosInstance1({
    method,
    baseURL,
    url: encodeURI(url),
    timeout,
    validateStatus,
    ...(schoolName ? { headers: { 'x-pool-school': schoolName } } : {}),
    ...(body ? { data: body } : {}),
  })
  if (!isProd()) console.log(res)
  return res
}

export const httpBasicAuth = async (
  method: string,
  url: string,
  auth: AxiosBasicCredentials,
  schoolName?: string,
  body?: Record<string, unknown>,
): Promise<any> => {
  const res = await axiosInstance1({
    method,
    baseURL,
    url: encodeURI(url),
    timeout,
    validateStatus,
    auth,
    ...(schoolName ? { headers: { 'x-pool-school': schoolName } } : {}),
    ...(body ? { data: body } : {}),
  })
  if (!isProd()) console.log(res)
  setItem('tokens', res.data.tokens)
  return res
}

axiosInstance2.interceptors.request.use(
  async (config: any) => {
    if (!config.headers) config.headers = {}
    config.headers['Authorization'] = `Bearer ${getItem('tokens')?.IdToken}`
    return config
  },
  (error) => Promise.reject(error),
)
axiosInstance2.interceptors.response.use(
  (res) => res,
  async (error) => {
    const { config } = error
    const status = error?.response?.status
    const isTimeout = error.code === 'ECONNABORTED'
    const isNetworkError = error.message.includes('Network Error')
    const maxRetries = 3
    const baseDelay = 1000 // 1 second base delay for exponential backoff

    if (!config._retry) {
      config._retry = true
      let retryCount = 0

      while (retryCount < maxRetries) {
        try {
          if (status === 401) {
            // Token refresh logic
            const res = await http('post', `/api/refreshUserToken`)
            setItem('tokens', res.data.tokens)
            config.headers['Authorization'] = `Bearer ${getItem('tokens')?.IdToken}`
            return axios(config)
          }

          if ((status >= 500 && status < 600) || isTimeout || isNetworkError) {
            // Retry for 5xx, timeouts, and network errors
            retryCount++
            return axios(config)
          }

          // Non-retryable errors
          break
        } catch (retryError) {
          retryCount++
          if (retryCount >= maxRetries) {
            if (status === 401) {
              // Redirect to sign-in for max retries on token refresh
              setItem('tokens', null)
              window.location.replace('/signin')
            }
            return Promise.reject(retryError)
          }
          // Exponential backoff
          const delay = baseDelay * Math.pow(2, retryCount) // 1s, 2s, 4s, ...
          await new Promise((resolve) => setTimeout(resolve, delay))
        }
      }
    }

    // Return non-retryable error
    return Promise.reject(error)
  },
)

export const httpBearerAuth = async (
  method: string,
  url: string,
  schoolName?: string,
  body?: Record<string, unknown> | FormData,
): Promise<any> => {
  if (body instanceof FormData && !isWeb) {
    const res = await fetch(`${baseURL}${encodeURI(url)}`, {
      method,
      headers: {
        Authorization: `Bearer ${getItem('tokens')?.IdToken}`,
      },
      body, // Send FormData directly
    })
    const data = await res.json()
    if (!isProd()) console.log(data)
    if (!res.ok) {
      const error = new Error(data.message || 'Server Error')
      error.name = data?.error?.name || error.name
      error.stack = data?.error?.stack || error.stack
      throw error
    }
    return { data }
  } else {
    const res = await axiosInstance2({
      method,
      baseURL,
      url: encodeURI(url),
      timeout,
      validateStatus,
      ...(schoolName ? { headers: { 'x-pool-school': schoolName } } : {}),
      ...(body ? { data: body } : {}),
    })
    if (!isProd()) console.log(res)
    return res
  }
}

export const httpCodeAuth = async (
  method: string,
  url: string,
  body?: Record<string, unknown>,
  to?: number,
): Promise<any> => {
  const res = await axiosInstance1({
    method,
    baseURL,
    url: encodeURI(url),
    timeout: to || timeout,
    validateStatus,
    headers: {
      'x-pool-school': getItem('x-pool-sn'),
      'x-pool-school-admin-email': getItem('x-pool-sae'),
      'x-pool-school-admin-code': getItem('x-pool-sac'),
    },
    ...(body ? { data: body } : {}),
  })
  if (!isProd()) console.log(res)
  return res
}

export const processError = (error: any): MessageProps => {
  if (!isProd()) console.log(error)

  if (error?.response?.data?.reason) {
    const msg: MessageProps = {
      style: 'error',
      text: error.response.data.reason,
    }
    return msg
  } else {
    // JavaScript’s Error objects store the stack as a non-enumerable property, which won’t be included in the spread operation.
    // To preserve the stack property and other non-enumerable properties while augmenting the message,
    // you can manually set the stack in the new object or use Object.assign() instead of the spread operator.
    const e = Object.assign({}, error, {
      message: `${error?.response?.data?.message || error?.message}`,
      stack: error.stack, // Manually include the stack property
    })
    if (rum) rum.recordError(e)
    else console.error('AWS RUM is not initialized. Error:', e)

    // Return generic error message
    const msg: MessageProps = {
      style: 'error',
      text: `Try again. If this keeps happening, contact support.`,
    }
    return msg
  }
}

export const connectToWss = async (
  body: Record<string, unknown>,
  subscribedBy: string,
  onMessage: (message: any) => void,
): Promise<() => void> => {
  const hostId: string = process.env.REACT_APP_AWS_APPSYNC_ENDPOINT_ID ?? ''
  // Initialize WebSocket
  const authorization = {
    Authorization: `${getItem('tokens')?.IdToken}`,
    host: `${hostId}.appsync-api.us-east-1.amazonaws.com`,
  }
  const payload = {}
  const base64_api_header = btoa(JSON.stringify(authorization))
  const base64_payload = btoa(JSON.stringify(payload))
  const appsync_url = `wss://${hostId}.appsync-realtime-api.us-east-1.amazonaws.com/graphql?header=${base64_api_header}&payload=${base64_payload}`
  const socket = new WebSocket(appsync_url, ['graphql-ws'])

  // When the WebSocket connection opens
  socket.onopen = () => {
    console.log(`WebSocket connection opened`)
    // Step 1: Send connection initialization message
    const initMessage = {
      type: 'connection_init',
    }

    socket.send(JSON.stringify(initMessage))

    // Step 2: Send the subscription start message
    const subscribeMessage = {
      id: subscribedBy,
      type: 'start',
      payload: {
        data: JSON.stringify(body),
        extensions: { authorization },
      },
    }
    socket.send(JSON.stringify(subscribeMessage))
  }

  // Handle incoming messages
  socket.onmessage = (event) => {
    const messageData = JSON.parse(event.data)

    if (messageData.type === 'data') {
      const newMessage = messageData.payload.data.onCreateMessage
      // return message in callback if it was not sent by subscribing user
      if (newMessage.msgSentBy !== subscribedBy) onMessage(newMessage)
    }
  }

  // Handle WebSocket closure
  socket.onclose = (event) => {
    console.log(`WebSocket connection closed. Code: ${event.code}, Reason: ${event.reason}`)
  }

  // Handle WebSocket errors
  socket.onerror = (error) => {
    console.error('WebSocket error:', error)
  }

  // Return a cleanup function to close the WebSocket
  return () => {
    socket.close()
  }
}
