import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import { TimeFrame } from 'components/timeframe'
import dayjs from 'dayjs'
import { DeviceSize } from 'hooks/useDeviceSize'
import React, { useMemo } from 'react'
import ReactApexChart from 'react-apexcharts'
import './index.css'

interface ColumnChartComponentProps {
  title?: string
  subtitle?: string
  xaxisTitle?: string
  yaxisTitle?: string
  xaxisType?: 'datetime' | 'category'
  stackBar?: boolean
  showToolbar?: boolean
  labels: string[]
  series: { type: string; name: string; data: number[] }[]
  colors: string[]
  timeFrame?: TimeFrame
  columnSpans?: Record<DeviceSize, number>
  legendPosition?: 'top' | 'right' | 'bottom' | 'left'
  showCard?: boolean
}

/**
 * ColumnChartComponent: Renders a column chart using ReactApexChart.
 * - Configurable time frame via `timeFrame` prop.
 * - Configurable legend position ('top' | 'right' | 'bottom' | 'left'), defaulting to 'top'
 * - Configurable Card inclusion via `showCard` prop.
 * - Hardcoded height (FIXME: make responsive).
 */
const ColumnChartComponent: React.FC<ColumnChartComponentProps> = ({
  title,
  subtitle,
  xaxisTitle,
  yaxisTitle,
  xaxisType = 'category',
  stackBar = false,
  showToolbar = false,
  labels,
  series,
  colors,
  timeFrame,
  columnSpans,
  legendPosition,
  showCard = true,
}) => {
  const chartColumnSpans: Record<DeviceSize, number> = columnSpans ?? { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }
  const position = legendPosition ?? 'top'

  const { filteredLabels, filteredSeries } = useMemo(() => {
    if (!timeFrame) return { filteredLabels: labels, filteredSeries: series }

    let startDate

    if (timeFrame.timeFrame === '7D') {
      startDate = dayjs().subtract(7, 'day').toDate()
    } else if (timeFrame.timeFrame === '1M') {
      startDate = dayjs().subtract(1, 'month').toDate()
    } else if (timeFrame.timeFrame === '3M') {
      startDate = dayjs().subtract(3, 'month').toDate()
    } else if (timeFrame.timeFrame === '6M') {
      startDate = dayjs().subtract(6, 'month').toDate()
    } else if (timeFrame.timeFrame === '12M') {
      startDate = dayjs().subtract(12, 'month').toDate()
    } else if (timeFrame.timeFrame === 'YTD') {
      startDate = dayjs().startOf('year').toDate()
    } else if (timeFrame.timeFrame === 'Custom' && timeFrame.startDate) {
      startDate = dayjs(timeFrame.startDate).toDate()
    } else {
      // Default to 3 months if no valid time frame is selected
      startDate = dayjs().subtract(3, 'month').toDate()
    }

    const filteredLabels = labels.filter((label: string) => {
      const date = new Date(label)
      return date >= startDate
    })

    const filteredSeries = series.map((s) => ({
      ...s,
      data: s.data.filter((_, index) => labels[index] && new Date(labels[index]) >= startDate),
    }))

    return { filteredLabels, filteredSeries }
  }, [labels, series, timeFrame])

  // Define the configuration options for the column chart
  const options: ApexCharts.ApexOptions = {
    title: { text: title, align: 'left', style: { fontSize: '16px' } },
    subtitle: { text: subtitle },
    chart: { type: 'bar', stacked: stackBar, toolbar: { show: showToolbar }, zoom: { enabled: false } },
    stroke: { width: 4, curve: 'smooth' },
    labels: filteredLabels,
    series: filteredSeries,
    colors,
    xaxis: {
      title: { text: xaxisTitle },
      type: xaxisType,
      labels: { datetimeFormatter: { year: 'yyyy', month: 'MMM', day: 'dd' } },
    },
    yaxis: { title: { text: yaxisTitle } },
    legend: { position, horizontalAlign: 'right', onItemClick: { toggleDataSeries: true } },
    tooltip: { enabled: true, theme: 'light' },
    dataLabels: { enabled: false },
    states: { hover: { filter: { type: 'lighten' } }, active: { filter: { type: 'none' } } },
  }

  // Render the column chart within a Material UI Card component
  const chart = <ReactApexChart type={'bar'} options={options} series={filteredSeries} height={300} />
  return showCard ? (
    <Grid item {...chartColumnSpans}>
      <Card sx={{ borderRadius: '16px' }}>
        <CardContent>{chart}</CardContent>
      </Card>
    </Grid>
  ) : (
    <Grid item {...chartColumnSpans}>
      {chart}
    </Grid>
  )
}

export default ColumnChartComponent
