import { LinearProgress } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import AvatarComponent from 'components/avatar'
import ButtonMenuComponent from 'components/button-menu'
import {
  ArrowIcon,
  ChangePasswordIcon,
  ChangeSchoolIcon,
  DefaultProfileIcon,
  DeleteAccountIcon,
  GreenDotIcon,
  LogOutIcon,
  ManageNotificationsIcon,
  MessagingIcon,
  MoreVerticalDotOnlyIcon,
  NotificationIcon,
  SettingsIcon,
} from 'components/icons'
import ModalConfirmComponent from 'components/modal-confirm'
import ToastComponent from 'components/toast'
import { useEffect, useRef, useState } from 'react'
import { NavigateFunction, Outlet, useNavigate } from 'react-router-dom'
import { UserNotificationOutputProps, UserOutputProps } from 'types/UserTypes'
import { setItem } from 'utils/constants'
import { MessageProps, StudentProps } from 'utils/types'
import { deleteUser } from 'utils/users'
import Logo from '../../images/cslogo.png'
import './index.css'

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}))

export const AppBarMenu: React.FC<{
  phoneNumber: string
  name: string
  studentCount: number
  unreadNotificationCount: number
  photoLink?: string
  data_testid?: string
}> = ({ phoneNumber, name, studentCount, unreadNotificationCount, photoLink, data_testid }) => {
  const navigate: NavigateFunction = useNavigate()
  const panelRef = useRef<HTMLDivElement>(null)

  // states
  const [isOpen, setIsOpen] = useState(false)
  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false)
  const [delOpen, setDelOpen] = useState<boolean>(false)
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<string>('')

  // handlers
  const handleNavigate = (url: string) => {
    setIsOpen(false)
    navigate(url)
  }
  const handleToggleSettings = () => setIsSettingsOpen(!isSettingsOpen)
  const handleDelClick = () => setDelOpen(true)
  const handleDelClose = () => setDelOpen(false)
  const handleDelSubmit = async () => {
    setDelOpen(false)
    setIsLoading('Deleting Family Users Account')
    const { msg, error } = await deleteUser(phoneNumber ?? '')
    setIsLoading('')
    if (error) setMsg(msg)
    // even if API resturned an error response- backend may have purged user
    // so clear session and go to homepage
    setItem('tokens', null)
    handleNavigate('/')
  }

  //hooks
  useEffect(() => {
    if (isOpen) {
      const handleOutsideInteraction = (event: MouseEvent | TouchEvent) => {
        const target = event.target as Node
        if (panelRef.current && !panelRef.current.contains(target) && !delOpen) {
          setIsOpen(false)
        }
      }
      document.addEventListener('mousedown', handleOutsideInteraction)
      document.addEventListener('touchstart', handleOutsideInteraction)
      return () => {
        document.removeEventListener('mousedown', handleOutsideInteraction)
        document.removeEventListener('touchstart', handleOutsideInteraction)
      }
    }
  }, [isOpen, delOpen, panelRef])

  return (
    <div ref={panelRef} data-testid={data_testid} className='relative'>
      <button className='flex items-center' onClick={isOpen ? () => setIsOpen(false) : () => setIsOpen(true)}>
        <AvatarComponent photoLink={photoLink} defaultElement={<DefaultProfileIcon width={9} height={9} />} />
        <span className='text-headings font-semibold text-sm mx-2.5 truncate overflow-hidden whitespace-nowrap max-w-[60px] sm:max-w-[200px]'>
          {name}
        </span>
        <MoreVerticalDotOnlyIcon />
      </button>
      {/* App Bar Menu Button */}
      {isOpen && (
        <div className='absolute top-full right-0 mt-2 min-w-[200px] flex flex-col items-start  pb-2 overflow-y-auto bg-white rounded-lg shadow-lg border border-gray-300 z-10'>
          {/***************** Display progress and error *****************/}
          {isLoading && <LinearProgress />}
          {msg && (
            <ToastComponent
              style={msg?.style}
              heading={msg?.heading}
              text={msg?.text}
              onClose={() => setMsg(undefined)}
            />
          )}
          {/***************** Menu *****************/}
          <ButtonMenuComponent
            name='Edit Profile'
            data_testid='edit-profile-testid'
            leftIcon={<DefaultProfileIcon width={9} height={9} />}
            onClick={() => handleNavigate('/profile')}
          />
          <div className='h-0.5 w-[90%] bg-custom-gray mx-auto'></div>
          <ButtonMenuComponent
            name='Messaging'
            leftIcon={<MessagingIcon />}
            onClick={() => handleNavigate('/messaging')}
            data_testid={'messaging-testid'}
          />
          <ButtonMenuComponent
            name='Notifications'
            leftIcon={
              <div className='relative w-fit'>
                <NotificationIcon />
                {unreadNotificationCount > 0 && <GreenDotIcon />}
              </div>
            }
            onClick={() => handleNavigate('/notifications')}
            data_testid={'notifications-testid'}
          />
          <ButtonMenuComponent
            name='Change School'
            leftIcon={<ChangeSchoolIcon />}
            onClick={() => handleNavigate('/change')}
            data_testid={'change-school-testid'}
          />
          <ButtonMenuComponent
            name='Settings'
            style={{
              backgroundColor: '#FFFDF6',
            }}
            leftIcon={<SettingsIcon />}
            rightIcon={<ArrowIcon isOpen={isSettingsOpen} />}
            onClick={handleToggleSettings}
          />
          {isSettingsOpen && (
            <div
              className={`w-full pl-6  bg-warm-white overflow-hidden transition-all duration-300 ease-in-out max-h-60`}
            >
              <ButtonMenuComponent
                name='Change Password'
                leftIcon={<ChangePasswordIcon />}
                onClick={() => handleNavigate('/settings')}
              />
              <ButtonMenuComponent
                name='Manage Notifications'
                onClick={() => handleNavigate('/settings/notifications')}
                leftIcon={<ManageNotificationsIcon />}
              />
              <ButtonMenuComponent name='Delete Account' leftIcon={<DeleteAccountIcon />} onClick={handleDelClick} />
            </div>
          )}
          <ButtonMenuComponent
            name='Sign out'
            leftIcon={<LogOutIcon />}
            onClick={() => handleNavigate('/signout')}
            data_testid={'logout-testid'}
          />
          {/***************** Delete Modal *****************/}
          <ModalConfirmComponent
            isShow={delOpen}
            title={studentCount ? 'Account Deletion Blocked' : 'Delete Account?'}
            body={
              studentCount
                ? "You can't delete your account while registered with schools. Please remove school affiliations to delete your account. For help, contact support@carpool.school."
                : 'You will lose access to this account. This action cannot be undone.'
            }
            actionIcon={studentCount ? 'redBlockIcon' : 'redInfoIcon'}
            onClose={studentCount ? handleDelClose : undefined}
            onActionCancel={studentCount ? undefined : handleDelClose}
            onActionSubmit={studentCount ? undefined : handleDelSubmit}
          />
        </div>
      )}
    </div>
  )
}

export const WithFamilyNav = ({
  students,
  user,
  userPhoto,
  userNotifications,
}: {
  students: StudentProps[]
  user?: UserOutputProps
  userPhoto: string
  userNotifications: UserNotificationOutputProps[]
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')) // for mobile screens

  // inputs
  const studentCount: number = students && students?.length > 0 ? students.length : 0
  const unreadNotificationCount: number = userNotifications.filter((v) => v.notificationIsUnread === true).length

  return (
    <div className='flex'>
      {/* AppBar Section */}
      <AppBar
        position='absolute'
        sx={{ display: 'block', background: '#ffffff', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}
      >
        <Toolbar>
          {/* Brand Logo */}
          <Typography
            component='a'
            variant='h6'
            href='/'
            color='inherit'
            noWrap
            sx={{
              display: 'flex',
              flexGrow: 1,
              alignItems: 'center',
              textDecoration: 'none',
              cursor: 'none', // Disable the cursor
              pointerEvents: 'none', // Disable clicking
            }}
          >
            <img
              src={Logo}
              width='200'
              height='40'
              style={{
                cursor: 'pointer', // Enable the cursor on the image
                pointerEvents: 'auto', // Allow click events on the image
                transition: 'opacity 0.3s', // Smooth transition for hover effect
              }}
              alt='Carpool.School Logo and Brand'
              onMouseOver={(e) => (e.currentTarget.style.opacity = '0.8')} // Change opacity on hover
              onMouseOut={(e) => (e.currentTarget.style.opacity = '1')} // Reset opacity
            />
          </Typography>
          {/* AppBarMenu */}
          {user?.name && (
            <AppBarMenu
              phoneNumber={user.phoneNumber}
              name={user?.name?.split(' ')[0]}
              studentCount={studentCount}
              unreadNotificationCount={unreadNotificationCount}
              photoLink={userPhoto}
              data_testid='nav-icon-testid'
            />
          )}
        </Toolbar>
      </AppBar>

      {/* Main Content Section */}
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          paddingTop: 'env(safe-area-inset-top)',
          paddingBottom: 'env(safe-area-inset-bottom)',
        }}
      >
        <Box
          sx={{
            // Provide a top margin to account for appbar height, and nominal bottom margin
            // There is no side panel or drawer on the side. So, provide a nominal padding
            mt: isMobile ? 8 : 9,
            mb: 2,
            px: 2,
            py: 0,
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </div>
  )
}
