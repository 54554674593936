import '@fontsource/figtree/300.css'
import '@fontsource/figtree/400.css'
import '@fontsource/figtree/500.css'
import '@fontsource/figtree/700.css'
import CssBaseline from '@mui/material/CssBaseline'
import LinearProgress from '@mui/material/LinearProgress'
import { extendTheme, ThemeProvider } from '@mui/material/styles'
import type {} from '@mui/x-data-grid/themeAugmentation'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { WithNav } from 'components/appbar'
import { WithAdminNav } from 'components/appbar-drawer-admin'
import { WithFamilyNav } from 'components/appbar-drawer-family'
import ErrorBoundary from 'components/error'
import { Suspense, useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'
import Add from 'routes/add'
import AddSchool from 'routes/add-school'
import AddSchoolSchedule from 'routes/add-school-schedule'
import AddVerifySchool from 'routes/add-verifyschool'
import SchoolDashboard from 'routes/admin-school-dashboard'
import SchoolSettings from 'routes/admin-school-settings'
import SchoolUsers from 'routes/admin-school-users'
import AdminSignIn from 'routes/admin-signin'
import AdminSignOut from 'routes/admin-signout'
import Afternoon from 'routes/afternoon'
import AfternoonMobile from 'routes/afternoon-mobile'
import Change from 'routes/change'
import ChangeMobile from 'routes/change-mobile'
import Dashboard from 'routes/dashboard'
import DashboardMobile from 'routes/dashboard-mobile'
import Home from 'routes/home'
import HomeMobile from 'routes/home-mobile'
import Messaging from 'routes/messaging'
import Morning from 'routes/morning'
import MorningMobile from 'routes/morning-mobile'
import Notification from 'routes/notification'
import Profile from 'routes/profile'
import Reset from 'routes/reset'
import SetPassword from 'routes/set'
import Settings from 'routes/settings'
import SettingsMobile from 'routes/settings-mobile'
import SettingsNotifications from 'routes/settings-notifications'
import SignIn from 'routes/signin'
import SignOut from 'routes/signout'
import SignUp from 'routes/signup'
import SignUpCreateUser from 'routes/signup-createuser'
import SignUpSchoolSchedule from 'routes/signup-school-schedule'
import SignUpVerifySchool from 'routes/signup-verifyschool'
import SignUpVerifyUser from 'routes/signup-verifyuser'
import User from 'routes/user'
import { UserNotificationOutputProps, UserOutputProps } from 'types/UserTypes'
import {
  clearItems,
  getItem,
  getSessionItem,
  ifAdminSignedIn,
  ifLocalhost,
  ifSignedIn,
  isWeb,
  parseJwt,
  setItem,
  setSessionItem,
} from 'utils/constants'
import { registerApp, registerNotifications } from 'utils/notifications'
import { getSchoolUsers } from 'utils/schools'
import { getStudents } from 'utils/students'
import { SchoolUsersProps, StudentProps } from 'utils/types'
import { getUser, getUserNotifications, getUserPhoto } from 'utils/users'
import './index.css'
import { rumClient } from 'utils/rum'

// Styles
const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: { main: '#010101' },
        secondary: { main: '#eed75a' },
        warning: { main: '#8f8136' },
        success: { main: '#EBD76F' },
        info: { main: '#A3A3A3' },
      },
    },
  },
  typography: {
    fontFamily: 'Figtree',
    fontSize: 14, // Default font size
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {},
      },
    },
    MuiContainer: {
      defaultProps: { maxWidth: 'xl' },
    },
  },
})

// Route protection components
//   Protected routes - require authentication
//     Admin routes redirect to admin signin
//     User routes redirect to user signin
const ProtectedAdminRoute = ({ outlet }: { outlet: JSX.Element }): JSX.Element => {
  if (ifAdminSignedIn()) return outlet
  return <Navigate to={{ pathname: '/admin/signin' }} />
}

const ProtectedRoute = ({ outlet }: { outlet: JSX.Element }): JSX.Element => {
  if (ifSignedIn()) return outlet
  return <Navigate to={{ pathname: '/signin' }} />
}

//   Unprotected routes - require no authentication
//     Regular routes redirect to student dashboard if signed in
//     Home route has special localhost handling
const UnprotectedRoute = ({ outlet }: { outlet: JSX.Element }): JSX.Element => {
  if (!ifSignedIn()) return outlet
  return <Navigate to='/students' />
}

const UnprotectedHomeRoute = ({ outlet }: { outlet: JSX.Element }): JSX.Element => {
  if (!ifSignedIn()) {
    if (ifLocalhost()) return outlet
    return <Navigate to={{ pathname: '/home' }} />
  }
  return <Navigate to='/students' />
}

//   Layout routes
//     Used by mobile and some web builds
//     Provides safe area padding without navigation elements
const WithoutNav = ({ minHeight }: { minHeight?: string }): JSX.Element => {
  return (
    <div
      style={{
        paddingTop: 'env(safe-area-inset-top)',
        paddingBottom: 'env(safe-area-inset-bottom)',
        minHeight,
      }}
    >
      <Outlet />
    </div>
  )
}

function App(): JSX.Element {
  // State store for signed in user
  const [isAdminSignedIn, setIsAdminSignedIn] = useState<boolean>()
  const [isSignedIn, setIsSignedIn] = useState<boolean>()
  const [schoolUsers, setSchoolUsers] = useState<SchoolUsersProps | null>()
  const [students, setStudents] = useState<StudentProps[] | null>()
  const [user, setUser] = useState<UserOutputProps>()
  const [userPhoto, setUserPhoto] = useState<string>('')
  const [userNotifications, setUserNotifications] = useState<UserNotificationOutputProps[]>([])
  const [userStudentRelationship, setUserStudentRelationship] = useState<string>()
  const [failedAvatarUrls, setFailedAvatarUrls] = useState<Set<string>>(new Set<string>())
  // cannot use state for the currentStudentId because it needs to persist between refresh
  const currentStudentId: string = getSessionItem('x-pool-csi') || ''
  // Handle user state changes
  // Admin route core handlers
  const handleAdminSignOut = () => {
    clearItems()
    setIsAdminSignedIn(false)
    setSchoolUsers(null)
  }
  const handleAdminSchoolUpdate = async (cb?: () => void) => {
    (async () => {
      const schoolName = getItem('x-pool-sn') ?? ''
      const { data, error } = await getSchoolUsers(schoolName)
      if (error) handleAdminSignOut()
      else {
        setSchoolUsers({ ...data, schoolName } as SchoolUsersProps)
        if (cb) cb()
      }
    })()
  }
  const handleAdminSignIn = async () => {
    setSchoolUsers(undefined)
    await handleAdminSchoolUpdate()
    setIsAdminSignedIn(true)
  }
  // Family route core handlers
  const handleSignOut = () => {
    setItem('tokens', null)
    setIsSignedIn(false)
    setStudents(null)
    setUser(undefined)
    setUserPhoto('')
    setUserNotifications([])
    setUserStudentRelationship('')
    setFailedAvatarUrls(new Set<string>())
  }
  const handleFamilyUserUpdate = async (
    cb?: () => void,
    isUpdateStudents?: boolean,
    isUpdateUserProfile?: boolean,
    isUpdateNotification?: boolean,
    isUpdateLastSeen?: boolean,
  ) => {
    try {
      const { IdToken } = getItem('tokens')
      const phoneNumber: string = parseJwt(IdToken).phone_number

      // Run API calls in parallel based on configuration
      const [res1, userAndPhoto, res2] = await Promise.all([
        isUpdateStudents ? getStudents() : Promise.resolve(null),
        isUpdateUserProfile
          ? Promise.all([getUser(phoneNumber), getUserPhoto(phoneNumber)])
          : Promise.resolve([null, null]),
        isUpdateNotification ? getUserNotifications(phoneNumber) : Promise.resolve(null),
      ])

      const [userRes, photoRes] = userAndPhoto ?? [null, null]

      // Update last-seen timestamp if applicable
      if (isUpdateLastSeen) {
        // API call to update the user's last-seen timestamp
        await getUser(phoneNumber) // Replace with an appropriate API to update the timestamp
      }

      if (
        (isUpdateStudents && res1?.error) ||
        (isUpdateUserProfile && (userRes?.error || photoRes?.error)) ||
        (isUpdateNotification && res2?.error)
      ) {
        handleSignOut()
        return
      }

      // Process students if applicable
      if (isUpdateStudents && res1) {
        const students: StudentProps[] = (res1?.data?.students as StudentProps[]) ?? []
        setStudents(students.filter((student) => student.userRelationship !== 'SchoolAdmin'))

        // Determine user relationship after processing students
        const relationships = students.map((student) => student.userRelationship)
        const relation = ['Parent/Stepparent', 'Mother', 'Father'].includes(relationships[0])
          ? 'Parent'
          : relationships[0] || ''
        setUserStudentRelationship(relation)
      }

      // Process notifications if applicable
      if (isUpdateNotification && res2) {
        setUserNotifications(res2?.data?.notifications ?? [])
      }

      // Update user and photo if applicable
      if (isUpdateUserProfile) {
        if (userRes) {
          setUser(userRes.data?.user)
        }
        if (photoRes) {
          setUserPhoto(photoRes?.data?.userPhoto ?? '')
        }
      }

      // Callback execution
      if (cb) cb()
    } catch (error) {
      console.error('Error in handleFamilyUserUpdate:', error)
      handleSignOut()
    }
  }
  const handleStudentUpdate = async (cb?: () => void) => {
    await handleFamilyUserUpdate(cb, true)
  }
  const handleUserProfileUpdate = async (cb?: () => void) => {
    await handleFamilyUserUpdate(cb, false, true)
  }
  const handleUserNotificationUpdate = async (cb?: () => void) => {
    await handleFamilyUserUpdate(cb, false, false, true)
  }
  const handleStudentAndUserProfileUpdate = async (cb?: () => void) => {
    await handleFamilyUserUpdate(cb, true, true)
  }
  const handleAppLoad = async (cb?: () => void) => {
    await handleFamilyUserUpdate(cb, true, true, true)
  }
  const handleFailedAvatarUrls = (url: string) => {
    setFailedAvatarUrls((prev) => new Set(prev).add(url)) // Add failed URL
  }
  const handleSignIn = async (cb?: () => void) => {
    // reset states
    setStudents(null)
    setUser(undefined)
    setUserPhoto('')
    setUserNotifications([])
    setUserStudentRelationship('')
    setFailedAvatarUrls(new Set<string>())

    // register the user app & device
    const { IdToken } = getItem('tokens')
    const userId: string = parseJwt(IdToken).phone_number
    await registerApp(handleAppLoad)
    await registerNotifications(userId)
    setIsSignedIn(true)

    if (cb) cb()
  }
  // Family route derived handlers
  const handleStudentRemove = (si: string, cb?: () => void) => {
    handleStudentUpdate(() => {
      if (currentStudentId === si) setSessionItem('x-pool-csi', '') // if current school was deleted, remove it
      if (cb) cb()
    })
  }
  const handleStudentSelect = (si: string, cb?: () => void) => {
    handleStudentUpdate(() => {
      setSessionItem('x-pool-csi', si) // selected school becomes current
      if (cb) cb()
    })
  }
  const handleStudentAdd = (si: string, cb?: () => void) => {
    handleStudentUpdate(() => {
      setSessionItem('x-pool-csi', si) // added school becomes current
      if (cb) cb()
    })
  }
  const handleSignUp = async (cb?: () => void) => {
    await handleSignIn(cb)
  }
  // Family route helper handlers
  const handleStudentIdQuery = (studentIdFromQuery: string): string => {
    // If non empty student ID is not present in the query parameters, use the local state
    let csi: string = studentIdFromQuery || currentStudentId
    // If student ID is not valid, use the first student id from remote server
    const studentIdsFromRemoteServer = students?.map((s: StudentProps) => s.studentId) ?? []
    if (!csi || !studentIdsFromRemoteServer.includes(csi)) csi = studentIdsFromRemoteServer[0] || '' // session state needs a string, undefined does not work
    // Update session state with finalized active student id
    setSessionItem('x-pool-csi', csi)
    return csi
  }

  // App initialization
  useEffect(() => {
    if (ifAdminSignedIn()) handleAdminSignIn()
    else handleAdminSignOut()
    if (ifSignedIn()) handleSignIn()
    else handleSignOut()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (isAdminSignedIn === undefined || isSignedIn === undefined || students === undefined || schoolUsers === undefined)
    return <LinearProgress />

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <BrowserRouter>
            <Suspense fallback={<LinearProgress />}>
              <Routes>
                {/* Admin Routes - Single web and mobile responsive page, with Admin NavBar */}
                <Route
                  element={
                    <WithAdminNav
                      isActive={!!schoolUsers?.isCarpoolActive}
                      schoolName={getItem('x-pool-sn') ?? ''}
                      schoolAdmin={getItem('x-pool-sae') ?? ''}
                    />
                  }
                >
                  <Route
                    path='/admin/dashboard'
                    element={<ProtectedAdminRoute outlet={<SchoolDashboard schoolUsers={schoolUsers} />} />}
                  />
                  <Route
                    path='/admin/settings'
                    element={
                      <ProtectedAdminRoute
                        outlet={<SchoolSettings schoolUsers={schoolUsers} onUpdate={handleAdminSchoolUpdate} />}
                      />
                    }
                  />
                  <Route
                    path='/admin/users'
                    element={
                      <ProtectedAdminRoute
                        outlet={
                          <SchoolUsers
                            schoolUsers={schoolUsers}
                            failedAvatarUrls={failedAvatarUrls}
                            onFailedAvatarUrl={handleFailedAvatarUrls}
                            onUpdate={handleAdminSchoolUpdate}
                          />
                        }
                      />
                    }
                  />
                  <Route
                    path='/admin/signout'
                    element={<ProtectedAdminRoute outlet={<AdminSignOut onSignOut={handleAdminSignOut} />} />}
                  />
                  <Route
                    path='/admin/*'
                    element={
                      <ProtectedAdminRoute
                        outlet={<SchoolSettings schoolUsers={schoolUsers} onUpdate={handleAdminSchoolUpdate} />}
                      />
                    }
                  />
                </Route>
                {/* App Route - Single web and mobile responsive page, without NavBar */}
                <Route element={<WithoutNav minHeight='100vh' />}>
                  {/* Messaging Chat Room Route - Single web and mobile responsive page, with Top AppBar */}
                  <Route
                    path='/messaging/:roomId'
                    element={
                      <ProtectedRoute
                        outlet={
                          <Messaging
                            tokens={getItem('tokens')}
                            students={students}
                            onUpdate={handleStudentUpdate}
                            failedAvatarUrls={failedAvatarUrls}
                            onFailedAvatarUrl={handleFailedAvatarUrls}
                          />
                        }
                      />
                    }
                  />
                  <Route path='/home' element={<Home />} />
                  <Route path='/' element={<UnprotectedHomeRoute outlet={<HomeMobile />} />} />
                </Route>
                {/* App Route - Single web and mobile responsive page, with NavBar */}
                <Route element={isWeb ? <WithNav /> : <WithoutNav />}>
                  {/* Sign Up Workflow */}
                  <Route path='/set/:pn' element={<UnprotectedRoute outlet={<SetPassword />} />} />
                  <Route path='/reset' element={<UnprotectedRoute outlet={<Reset />} />} />
                  <Route path='/signup' element={<UnprotectedRoute outlet={<SignUp />} />} />
                  <Route path='/signup/verifySchool' element={<UnprotectedRoute outlet={<SignUpVerifySchool />} />} />
                  <Route
                    path='/signup/school/schedule'
                    element={<UnprotectedRoute outlet={<SignUpSchoolSchedule />} />}
                  />
                  <Route path='/signup/createUser' element={<UnprotectedRoute outlet={<SignUpCreateUser />} />} />
                  <Route path='/signup/verifyUser' element={<SignUpVerifyUser onSignUp={handleSignUp} />} />
                  {/* New School Workflow */}
                  <Route path='/add' element={<ProtectedRoute outlet={<Add />} />} />
                  <Route
                    path='/add/verifySchool'
                    element={
                      <ProtectedRoute
                        outlet={
                          <AddVerifySchool userStudentRelationship={userStudentRelationship} onAdd={handleStudentAdd} />
                        }
                      />
                    }
                  />
                  <Route
                    path='/add/school/schedule'
                    element={
                      <ProtectedRoute
                        outlet={
                          <AddSchoolSchedule
                            userStudentRelationship={userStudentRelationship}
                            onAdd={handleStudentAdd}
                          />
                        }
                      />
                    }
                  />
                  <Route
                    path='/add/school'
                    element={<ProtectedRoute outlet={<AddSchool onAdd={handleStudentAdd} />} />}
                  />
                  {/* Sign In */}
                  <Route path='/admin/signin' element={<AdminSignIn onSignIn={handleAdminSignIn} />} />
                  <Route path='/signin' element={<UnprotectedRoute outlet={<SignIn onSignIn={handleSignIn} />} />} />
                  {/* Sign Out */}
                  <Route path='/signout' element={<ProtectedRoute outlet={<SignOut onSignOut={handleSignOut} />} />} />
                </Route>
                {/* App Route - Single web and mobile responsive page, with Family NavBar */}
                <Route
                  element={
                    isWeb ? (
                      <WithFamilyNav
                        user={user}
                        userPhoto={userPhoto}
                        userNotifications={userNotifications}
                        students={students ?? []}
                      />
                    ) : (
                      <WithoutNav />
                    )
                  }
                >
                  {/* User Profile */}
                  <Route
                    path='/profile'
                    element={
                      <ProtectedRoute
                        outlet={
                          <Profile
                            tokens={getItem('tokens')}
                            user={user}
                            userPhoto={userPhoto}
                            userStudentRelationship={userStudentRelationship}
                            students={students ?? []}
                            onUpdate={handleStudentAndUserProfileUpdate}
                          />
                        }
                      />
                    }
                  />
                  {/* User Detail */}
                  <Route
                    path='/userpool/:comId'
                    element={
                      <ProtectedRoute outlet={<User tokens={getItem('tokens')} onUpdate={handleStudentUpdate} />} />
                    }
                  />
                  {/* Manage Notification Settings */}
                  <Route
                    path='/settings/notifications'
                    element={
                      <ProtectedRoute
                        outlet={
                          <SettingsNotifications
                            tokens={getItem('tokens')}
                            user={user}
                            onUpdate={handleUserProfileUpdate}
                          />
                        }
                      />
                    }
                  />
                  {/* User Notification */}
                  <Route
                    path='/notifications'
                    element={
                      <ProtectedRoute
                        outlet={
                          <Notification
                            tokens={getItem('tokens')}
                            userNotifications={userNotifications}
                            onUpdate={handleUserNotificationUpdate}
                          />
                        }
                      />
                    }
                  />
                  {/* Messaging */}
                  <Route
                    path='/messaging'
                    element={
                      <ProtectedRoute
                        outlet={
                          <Messaging
                            tokens={getItem('tokens')}
                            students={students}
                            onUpdate={handleStudentUpdate}
                            failedAvatarUrls={failedAvatarUrls}
                            onFailedAvatarUrl={handleFailedAvatarUrls}
                          />
                        }
                      />
                    }
                  />
                </Route>
                {/* App Route - Separate web and mobile pages, with Family NavBar */}
                <Route
                  element={
                    isWeb ? (
                      <WithFamilyNav
                        user={user}
                        userPhoto={userPhoto}
                        userNotifications={userNotifications}
                        students={students ?? []}
                      />
                    ) : (
                      <WithoutNav />
                    )
                  }
                >
                  {isWeb ? (
                    <>
                      {/* Dashboard */}
                      <Route
                        path='/students'
                        element={
                          <ProtectedRoute
                            outlet={
                              <Dashboard
                                tokens={getItem('tokens')}
                                user={user}
                                students={students ?? []}
                                processQuery={handleStudentIdQuery}
                                failedAvatarUrls={failedAvatarUrls}
                                onFailedAvatarUrl={handleFailedAvatarUrls}
                              />
                            }
                          />
                        }
                      />
                      <Route
                        path='/morning'
                        element={
                          <ProtectedRoute
                            outlet={
                              <Morning
                                students={students ?? []}
                                processQuery={handleStudentIdQuery}
                                onUpdate={handleStudentUpdate}
                              />
                            }
                          />
                        }
                      />
                      <Route
                        path='/afternoon'
                        element={
                          <ProtectedRoute
                            outlet={
                              <Afternoon
                                students={students ?? []}
                                processQuery={handleStudentIdQuery}
                                onUpdate={handleStudentUpdate}
                              />
                            }
                          />
                        }
                      />
                      {/* Change School */}
                      <Route
                        path='/change'
                        element={
                          <ProtectedRoute
                            outlet={
                              <Change
                                students={students ?? []}
                                processQuery={handleStudentIdQuery}
                                onUpdate={handleStudentUpdate}
                                onSelect={handleStudentSelect}
                                onRemove={handleStudentRemove}
                              />
                            }
                          />
                        }
                      />
                      {/* User Settings */}
                      <Route
                        path='/settings'
                        element={
                          <ProtectedRoute outlet={<Settings tokens={getItem('tokens')} students={students ?? []} />} />
                        }
                      />
                    </>
                  ) : (
                    <>
                      {/* Dashboard */}
                      <Route
                        path='/students'
                        element={
                          <ProtectedRoute
                            outlet={
                              <DashboardMobile
                                tokens={getItem('tokens')}
                                user={user}
                                userPhoto={userPhoto}
                                userNotifications={userNotifications}
                                students={students ?? []}
                                processQuery={handleStudentIdQuery}
                                failedAvatarUrls={failedAvatarUrls}
                                onFailedAvatarUrl={handleFailedAvatarUrls}
                              />
                            }
                          />
                        }
                      />
                      <Route
                        path='/morning'
                        element={
                          <ProtectedRoute
                            outlet={
                              <MorningMobile
                                students={students ?? []}
                                processQuery={handleStudentIdQuery}
                                onUpdate={handleStudentUpdate}
                              />
                            }
                          />
                        }
                      />
                      <Route
                        path='/afternoon'
                        element={
                          <ProtectedRoute
                            outlet={
                              <AfternoonMobile
                                students={students ?? []}
                                processQuery={handleStudentIdQuery}
                                onUpdate={handleStudentUpdate}
                              />
                            }
                          />
                        }
                      />
                      {/* Change School */}
                      <Route
                        path='/change'
                        element={
                          <ProtectedRoute
                            outlet={
                              <ChangeMobile
                                students={students ?? []}
                                processQuery={handleStudentIdQuery}
                                onUpdate={handleStudentUpdate}
                                onSelect={handleStudentSelect}
                                onRemove={handleStudentRemove}
                              />
                            }
                          />
                        }
                      />
                      {/* User Settings */}
                      <Route
                        path='/settings'
                        element={
                          <ProtectedRoute
                            outlet={<SettingsMobile tokens={getItem('tokens')} students={students ?? []} />}
                          />
                        }
                      />
                    </>
                  )}
                </Route>
                {/* All Other Routes */}
                <Route path='*' element={<Navigate to='/home' />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </LocalizationProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

if (!ifLocalhost() && isWeb) rumClient()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
