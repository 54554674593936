import ButtonMobileComponent from 'components/button-mobile'
import { Component, PropsWithChildren } from 'react'
import { rum } from 'utils/rum'

export default class ErrorBoundary extends Component<
  PropsWithChildren,
  {
    error: Error | null
    errorInfo: React.ErrorInfo | null
  }
> {
  constructor(props: PropsWithChildren) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null,
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    })

    // Log the error to AWS RUM
    if (rum) rum.recordError(error)
    else console.error('AWS RUM is not initialized. Error:', error)
  }

  handleReload = () => {
    window.location.reload()
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className='flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6'>
          <div className='bg-white shadow-lg rounded-lg p-8 max-w-md text-center'>
            <h1 className='text-2xl font-bold text-gray-800 mb-4'>An Unexpected Error Occurred</h1>
            <p className='text-gray-600 mb-6'>
              We encountered an issue while loading the application. Please refresh the page. If the issue persists,
              contact our support team for assistance.
            </p>
            <ButtonMobileComponent onClick={this.handleReload} text='Refresh Page' />
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
