import React, { useEffect, useState } from 'react'
import './index.css'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

interface AppBarComponentProps {
  children: React.ReactNode
}

const AppBarComponent: React.FC<AppBarComponentProps> = ({ children }) => {
  const [scrolled, setScrolled] = useState(false)
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg')) // Tablet: 600px - 1024px
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg')) // Desktop: >= 1024px

  /********NAVBAR ON SCROLL*********/
  const handleScroll = () => {
    setScrolled(window.scrollY > 0) // Update state if the user has scrolled down
  }

  useEffect(() => {
    // Add event listener
    window.addEventListener('scroll', handleScroll)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []) // Empty dependency array ensures the effect runs once
  return (
    <div
      className={`fixed top-0 right-0 z-40 transition-all duration-300 py-5 
        ${scrolled ? 'nav-bg' : 'nav-bg-scroll'} 
        ${isDesktop ? 'w-[calc(100%-16rem)]' : isTablet ? 'w-[calc(100%-4rem)]' : 'w-full'}
      `}
    >
      <div className='flex items-center justify-between px-4'>
        {children} {/* CONTENT GOES HERE  */}
      </div>
    </div>
  )
}

export default AppBarComponent
