import { LinearProgress, Radio } from '@mui/material'
import { styled } from '@mui/material/styles'
import BackComponent from 'components/back'
import ButtonMobileComponent from 'components/button-mobile'
import ModalConfirmRemoveComponent from 'components/modal-confirm-remove'
import SelectMobileComponent from 'components/select-mobile'
import ToastComponent from 'components/toast'
import React, { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import { titleCaseSchoolNameForUI } from 'utils/constants'
import { deleteStudent, updateStudentSettings } from 'utils/students'
import { MessageProps, StudentProps } from 'utils/types'
import Arrow from '../../images/arrow_forward.png'
import './index.css'
import { DeleteIcon } from 'components/icons'

const CustomRadio = styled(Radio)(({ theme }) => ({
  marginLeft: '4px',
  color: '#A3A3A3', // Default color when not checked
  width: '16px',
  '&.Mui-checked': {
    color: '#E5C265', // Color when checked
  },
}))

interface ChangeMobileComponentProps {
  studentIdFromQuery: string
  students: StudentProps[]
  onSelect: (ssi: string, cb?: () => void) => void
  onRemove: (ssi: string, cb?: () => void) => void
}

function ChangeMobileComponent(props: ChangeMobileComponentProps): JSX.Element {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: Students, Selected Student Id and Callback
  const { studentIdFromQuery, students, onSelect, onRemove } = props ?? {}

  // states
  const [selectedStudentId, setSelectedStudentId] = useState<string>()
  const [selectedSchoolName, setSelectedSchoolName] = useState<string>()
  const [selectedSchedule, setSelectedSchedule] = useState<string>()
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = React.useState<string>('')

  // hooks
  useEffect(() => {
    const student: StudentProps | undefined = students?.find((s: StudentProps) => s.studentId === studentIdFromQuery)
    setSelectedStudentId(studentIdFromQuery)
    setSelectedSchoolName(student?.schoolName ?? '')
    setSelectedSchedule(student?.scheduleGroupName ?? '')
  }, [studentIdFromQuery, students])

  // handlers
  // Handle change school
  const handleSelectSchool = async (sn: string, sid: string, sgn: string) => {
    setSelectedSchoolName(sn)
    setSelectedStudentId(sid)
    setSelectedSchedule(sgn)
  }
  // Handle delete school
  const handleRemoveSchool = async (schoolName: string, studentId: string) => {
    setIsLoading('Removing school...')
    const { msg, error } = await deleteStudent(schoolName, studentId)
    if (error) setMsg(msg)
    else {
      // change the selected school if it was deleted
      if (studentId === studentIdFromQuery) await onRemove(studentId, () => navigate(`/change`))
      else await onRemove(studentId)
    }
    setIsLoading('')
  }
  // Cancel button handler
  const handleCancel = async () => navigate(`/students?id=${studentIdFromQuery}`)
  // Save button handler
  const handleSave = async () => {
    if (selectedStudentId && selectedSchoolName && selectedSchedule) {
      setIsLoading('Saving...')
      const { msg, error } = await updateStudentSettings(
        selectedSchoolName,
        selectedStudentId,
        undefined,
        undefined,
        undefined,
        selectedSchedule,
      )
      if (error) setMsg(msg)
      else {
        await onSelect(selectedStudentId, () => navigate(`/students?id=${selectedStudentId}`))
      }
      setIsLoading('')
    }
  }

  return (
    <>
      {/***************** Display progress and error *****************/}
      {isLoading && <LinearProgress />}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
      {/***************** Display back *****************/}
      <BackComponent text='Change School' onClick={handleCancel} />
      {/***************** Change School *****************/}
      <div className='font-figtree px-5 font-medium pt-4'>
        <ButtonMobileComponent
          text={`New School`}
          width={'full'}
          href='/add'
          icon={
            <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
              <path
                d='M16.3334 10.8327H11.3334V15.8327H9.66669V10.8327H4.66669V9.16602H9.66669V4.16602H11.3334V9.16602H16.3334V10.8327Z'
                fill='#474747'
              />
            </svg>
          }
        />
      </div>
      <div className='font-figtree px-5 pt-6' data-testid='school-testid'>
        {students && students.length > 0 && (
          <>
            <p className='text-gray-400 text-lg font-semibold pb-3'>Select your school</p>
            {students.map((s: StudentProps, i: number) => {
              const { schoolName, schoolStats, schoolScheduleGroups, studentId, scheduleGroupName } = s
              const schoolScheduleGroupNames: string[] =
                schoolScheduleGroups?.map((group: any) => group.schoolScheduleGroupName) ?? []
              const isSelected: boolean = selectedStudentId === studentId

              return (
                <div
                  key={i}
                  className={`border ${
                    isSelected ? 'border-custom-yellow' : 'border-gray-200'
                  } p-2 rounded-xl w-full mb-4`}
                >
                  <div className='flex items-center justify-between w-full'>
                    <div className='flex items-center mr-1'>
                      <CustomRadio
                        size='small'
                        checked={isSelected}
                        onChange={() => handleSelectSchool(schoolName, studentId, scheduleGroupName)}
                        value={studentId}
                      />
                      <div className={`${isSelected ? 'text-headings' : 'text-gray-400'} ml-2 text-base font-medium`}>
                        {titleCaseSchoolNameForUI(schoolName)}
                      </div>
                    </div>
                    <div className='flex items-center gap-2'>
                      <div className='text-[12px] bg-light-yellow py-1 px-2 rounded-xl'>
                        {schoolStats?.registeredUsers} Families
                      </div>
                      <ModalConfirmRemoveComponent
                        data_testid='DeleteOutlinedIcon'
                        icon={<DeleteIcon />}
                        title='Delete Registered School?'
                        body={`You will no longer see ${titleCaseSchoolNameForUI(schoolName)}`}
                        actionText='Delete now'
                        onClick={() => handleRemoveSchool(schoolName, studentId)}
                      />
                    </div>
                  </div>
                  {isSelected && schoolScheduleGroupNames.length > 1 && (
                    <div className='pt-2.5'>
                      <SelectMobileComponent
                        key='1c'
                        label='Select School Schedule'
                        isRequired={true}
                        value={selectedSchedule} // Use the state value
                        options={schoolScheduleGroupNames} // Options from the fetched data
                        onChange={setSelectedSchedule} // Update state when changed
                      />
                    </div>
                  )}
                </div>
              )
            })}
            <div className='fixed bottom-0 left-0 right-0 bg-white pt-3'>
              <div className='border-b border-gray-200'></div>
              <div className='pt-4 pb-7 px-5'>
                <div className='flex justify-around gap-3'>
                  <ButtonMobileComponent color='bg-btn-grey' text='Cancel' onClick={handleCancel} />
                  <ButtonMobileComponent
                    iconPosition='right'
                    icon={Arrow}
                    onClick={handleSave}
                    text={'Save'}
                    // className='w-full px-4 py-1.5 bg-custom-yellow text-headings rounded-lg flex items-center justify-center'
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

interface Props {
  students: StudentProps[]
  processQuery: (id: string) => string
  onUpdate: (cb?: () => void) => void
  onSelect: (ssi: string, cb?: () => void) => void
  onRemove: (ssi: string, cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams()

  // Inputs: Selected Student Id, and Callback
  const { students, processQuery, onSelect, onRemove } = props

  // We should never call this component without student id query search parameters, but this is just to be safe
  const studentIdFromQuery = searchParams.get('id') ?? ''
  useEffect(() => {
    setSearchParams({ id: processQuery(studentIdFromQuery) })
  }, [studentIdFromQuery, processQuery, setSearchParams])

  // Check if queried student exists
  const student: StudentProps | undefined = students?.find((s: StudentProps) => s.studentId === studentIdFromQuery)

  if (!student && students && students?.length > 0) return <LinearProgress />
  else
    return (
      <ChangeMobileComponent
        studentIdFromQuery={studentIdFromQuery}
        students={students}
        onSelect={onSelect}
        onRemove={onRemove}
      />
    )
}

export default Index
