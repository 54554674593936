import { BackIcon } from 'components/icons'
import React from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'

interface BackComponentProps {
  text?: string
  onClick?: () => void
  rightElement?: JSX.Element
  children?: React.ReactNode
}

const BackComponent = (props: BackComponentProps): JSX.Element => {
  const navigate: NavigateFunction = useNavigate()
  const { text, onClick, rightElement, children } = props ?? {}

  const handleBackClick = () => {
    if (onClick) onClick()
    else navigate(-1)
  }

  return (
    <>
      <div className={`flex justify-between pt-4 pb-4 pl-5 pr-5 items-center`}>
        <div className='flex items-center gap-2.5' data-testid='back-button-text-testid'>
          <div className='cursor-pointer' onClick={handleBackClick} data-testid='back-button-testid'>
            <BackIcon />
          </div>
          <span className='text-[color:var(--Color-Tokens-Text-Modes-Base-Second,#68727D)] text-xl font-semibold leading-[24px]'>
            {text}
          </span>
        </div>
        {rightElement}
      </div>
      {children}
    </>
  )
}

export default BackComponent
