import Box from '@mui/material/Box'
import AdminMetricsComponent from 'components/metrics-admin'
import { SchoolUsersProps } from 'utils/types'

function Index({ schoolUsers }: { schoolUsers?: SchoolUsersProps | null }): JSX.Element {
  const { schoolStats, students = [] } = schoolUsers ?? {}
  return (
    <Box px={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }} py={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}>
      {/***************** Show school name in Title *****************/}
      <div className='mb-12'></div>

      {/***************** Show analytics in Charts *****************/}
      <AdminMetricsComponent schoolStats={schoolStats} students={students} />
    </Box>
  )
}

export default Index
