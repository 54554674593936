/* eslint @typescript-eslint/no-unused-vars: "off" */
import Diversity3Icon from '@mui/icons-material/Diversity3'
import ForumIcon from '@mui/icons-material/Forum'
import LightModeIcon from '@mui/icons-material/LightMode'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import PeopleIcon from '@mui/icons-material/People'
import SendIcon from '@mui/icons-material/Send'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import ColumnChartComponent from 'components/chart-column'
import PieChartComponent from 'components/chart-pie'
import PaperComponent from 'components/paper'
import TimeFrameSelector, { TimeFrame, TimeFrames } from 'components/timeframe'
import ValueChartComponent from 'components/chart-value'
import { useState } from 'react'
import { SchoolStatProps, SchoolUsersStudentProps } from 'types/SchoolTypes'
import {
  ColumnChartDataType,
  getChartColors,
  getPieChartData,
  getRegisteredUsersOverTimeColumnChartData,
  getUsersByMessageSentCount,
  PieChartDataType,
} from 'utils/chart'
import './index.css'

const AdminMetricsComponent = ({
  schoolStats,
  students,
}: {
  schoolStats?: SchoolStatProps
  students?: SchoolUsersStudentProps[]
}) => {
  // User Adoption
  const {
    registeredUsers = 0,
    pickupUsers = 0,
    dropoffUsers = 0,
    inactiveUsers = 0,
    pickupUsersByCity,
    pickupUsersByPostalCode,
    dropoffUsersByCity,
    dropoffUsersByPostalCode,
    dailySchoolUsers = [],
  } = schoolStats ?? {}

  // User Engagement
  const messagingStudents: SchoolUsersStudentProps[] = students?.filter((student) => student.userSentMessages > 0) ?? []
  const usersMessaging: number = messagingStudents.length ?? 0
  const percentUsersMessaging: number = registeredUsers ? (usersMessaging / registeredUsers) * 100 : 0
  const messagesSent: number = messagingStudents.reduce((total, student) => total + student.userSentMessages, 0)
  const averageMessages: number = usersMessaging ? (messagesSent / usersMessaging) : 0

  // User Adoption Trend
  const initialTimeFrame: TimeFrames = '3M'
  const allowedTimeFrames: TimeFrames[] = ['7D', '1M', '3M', '6M', '12M', 'YTD']
  const [timeFrame, setTimeFrame] = useState<TimeFrame>({ timeFrame: initialTimeFrame })
  const dailyUsers: ColumnChartDataType[] = getRegisteredUsersOverTimeColumnChartData(dailySchoolUsers)

  // Engagement Patterns
  const messageSentDistribution: ColumnChartDataType[] = getUsersByMessageSentCount(
    messagingStudents.map((student) => student.userSentMessages),
  )

  // Geographic Distribution
  const pickupUserCountAndCity: PieChartDataType[] = getPieChartData(pickupUsersByCity)
  const pickupUserCountAndPostalCode: PieChartDataType[] = getPieChartData(pickupUsersByPostalCode)
  const dropoffUserCountAndCity: PieChartDataType[] = getPieChartData(dropoffUsersByCity)
  const dropoffUserCountAndPostalCode: PieChartDataType[] = getPieChartData(dropoffUsersByPostalCode)

  return (
    <>
      <PaperComponent title={'User Adoption'} subtitle='How many families have registered for carpool?'>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
            <ValueChartComponent
              title='Total Families'
              data_testid='total-users-testid'
              value={registeredUsers}
              cardBackground='#D0ECFE'
              textColor='#042174'
              avatar={
                <Avatar sx={{ backgroundColor: '#1877f2' }}>
                  <PeopleIcon />
                </Avatar>
              }
            />
          </Grid>
          <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
            <ValueChartComponent
              title='Carpool Enabled'
              value={registeredUsers && inactiveUsers ? registeredUsers - inactiveUsers : registeredUsers}
              cardBackground='#EFD6FF'
              textColor='#27097A'
              avatar={
                <Avatar sx={{ backgroundColor: '#8E33FF' }}>
                  <PeopleIcon />
                </Avatar>
              }
            />
          </Grid>
          <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
            <ValueChartComponent
              title='Morning Carpools'
              value={pickupUsers}
              cardBackground='#FFF5CC'
              textColor='#7A4100'
              avatar={
                <Avatar sx={{ backgroundColor: '#FFAB00' }}>
                  <LightModeOutlinedIcon />
                </Avatar>
              }
            />
          </Grid>
          <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
            <ValueChartComponent
              title='Afternoon Carpools'
              value={dropoffUsers}
              cardBackground='#FFE9D5'
              textColor='#7A0916'
              avatar={
                <Avatar sx={{ backgroundColor: '#FF5630' }}>
                  <LightModeIcon />
                </Avatar>
              }
            />
          </Grid>
        </Grid>
      </PaperComponent>
      <PaperComponent title={'User Engagement'} marginTop={4} subtitle='How are families coordinating carpools?'>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
            <ValueChartComponent
              title='Active Families'
              data_testid='messaging-users-testid'
              value={usersMessaging}
              cardBackground='#D0ECFE'
              textColor='#042174'
              avatar={
                <Avatar sx={{ backgroundColor: '#1877f2' }}>
                  <Diversity3Icon />
                </Avatar>
              }
            />
          </Grid>
          <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
            <ValueChartComponent
              title='Engagement Rate'
              value={percentUsersMessaging.toFixed(0)}
              units='%'
              cardBackground='#EFD6FF'
              textColor='#27097A'
              avatar={
                <Avatar sx={{ backgroundColor: '#8E33FF' }}>
                  <TrendingUpIcon />
                </Avatar>
              }
            />
          </Grid>
          <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
            <ValueChartComponent
              title='Total Messages Sent'
              value={messagesSent}
              cardBackground='#FFF5CC'
              textColor='#7A4100'
              avatar={
                <Avatar sx={{ backgroundColor: '#FFAB00' }}>
                  <SendIcon />
                </Avatar>
              }
            />
          </Grid>
          <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
            <ValueChartComponent
              title='Messages Sent Per Family'
              value={averageMessages.toFixed(0)}
              cardBackground='#FFE9D5'
              textColor='#7A0916'
              avatar={
                <Avatar sx={{ backgroundColor: '#FF5630' }}>
                  <ForumIcon />
                </Avatar>
              }
            />
          </Grid>
        </Grid>
      </PaperComponent>
      {dailyUsers.length > 0 && (
        <PaperComponent
          title={'User Adoption Trend'}
          subtitle='How have registered families evolved over time?'
          marginTop={4}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TimeFrameSelector
                allowedTimeFrames={allowedTimeFrames}
                initialTimeFrame={initialTimeFrame}
                onTimeFrameChange={(v) => setTimeFrame(v)}
              />
              <ColumnChartComponent
                xaxisTitle='Date'
                yaxisTitle='Number of Families'
                xaxisType='datetime'
                stackBar={true}
                labels={dailyUsers[0].data.map((item) => item.label)}
                series={dailyUsers.map(({ type, name, data }) => ({
                  type,
                  name,
                  data: data.map(({ value }) => value),
                }))}
                colors={dailyUsers.map(({ color }) => color)}
                timeFrame={timeFrame}
              />
            </Grid>
          </Grid>
        </PaperComponent>
      )}
      {messageSentDistribution.length > 0 && (
        <PaperComponent
            title={'Engagement Distribution'}
            subtitle='How are families communicating?'
            marginTop={4}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <ColumnChartComponent
                xaxisTitle='Message Count Ranges'
                yaxisTitle='Number of Families'
                labels={messageSentDistribution[0].data.map((item) => item.label)}
                series={messageSentDistribution.map(({ type, name, data }) => ({
                  type,
                  name,
                  data: data.map(({ value }) => value),
                }))}
                colors={messageSentDistribution.map(({ color }) => color)}
              />
            </Grid>
        </PaperComponent>
      )}
      {(pickupUserCountAndCity.length > 0 ||
        dropoffUserCountAndCity.length > 0 ||
        pickupUserCountAndPostalCode.length > 0 ||
        dropoffUserCountAndPostalCode.length > 0) && (
        <PaperComponent
          title={'Geographic Distribution'}
          subtitle='Where are carpool requests concentrated?'
          marginTop={4}
        >
          <Grid container spacing={2}>
            {pickupUserCountAndCity.length > 0 && (
              <PieChartComponent
                title='Morning by City'
                labels={pickupUserCountAndCity.map((item) => item.label)}
                series={pickupUserCountAndCity.map((item) => item.value)}
                colors={getChartColors(pickupUserCountAndCity)}
              />
            )}
            {dropoffUserCountAndCity.length > 0 && (
              <PieChartComponent
                title='Afternoon by City'
                labels={dropoffUserCountAndCity.map((item) => item.label)}
                series={dropoffUserCountAndCity.map((item) => item.value)}
                colors={getChartColors(dropoffUserCountAndCity)}
              />
            )}
            {pickupUserCountAndPostalCode.length > 0 && (
              <PieChartComponent
                title='Morning by ZipCode'
                labels={pickupUserCountAndPostalCode.map((item) => item.label)}
                series={pickupUserCountAndPostalCode.map((item) => item.value)}
                colors={getChartColors(pickupUserCountAndPostalCode)}
              />
            )}
            {dropoffUserCountAndPostalCode.length > 0 && (
              <PieChartComponent
                title='Afternoon by ZipCode'
                labels={dropoffUserCountAndPostalCode.map((item) => item.label)}
                series={dropoffUserCountAndPostalCode.map((item) => item.value)}
                colors={getChartColors(dropoffUserCountAndPostalCode)}
              />
            )}
          </Grid>
        </PaperComponent>
      )}
    </>
  )
}

export default AdminMetricsComponent
