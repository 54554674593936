import { useMemo } from "react";
import { useLocation } from "react-router-dom";

// Custom hook to determine if the current path matches specific routes
const useIsPath = (paths: string[]): boolean => {
  const location = useLocation();
  return useMemo(() => paths.includes(location.pathname), [location.pathname, paths]);
};

export default useIsPath;
