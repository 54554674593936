import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import './index.css'

interface ValueChartComponentProps {
  title: string
  value: number | string
  units?: string // Optional units prop
  avatar?: React.ReactNode
  data_testid?: string
  cardBackground?: string
  textColor?: string
}

/**
 * ValueChartComponent: Renders a card displaying a key value and title.
 * - Displays a title and a value, with optional units and avatar.
 * - Configurable card background and avatar.
 * - Value is hardcoded to #272D37, a dark gray.
 * - Title and value are arranged with flexbox for consistent layout.
 * - Optional units are displayed next to the value.
 * - Optional avatar is displayed in the top-right corner.
 * - Card dimensions are fixed at 200x120 pixels.
 * - Spacing between title and avatar, and title and value is configurable.
 */
const ValueChartComponent: React.FC<ValueChartComponentProps> = ({
  title,
  value,
  units,
  avatar,
  data_testid,
  cardBackground,
  textColor,
}) => {
  return (
    <Card
      sx={{
        borderRadius: '16px',
        boxShadow: 'none',
        backgroundColor: cardBackground,
        paddingTop: '5px',
        paddingBottom: '5px',
        // The height: '100%' style in the Card component sets the height of the card to be 100% of its parent container's height.
        // This means that the card will stretch to fill the entire height of the grid item (<Grid item> in this case) that contains it.
        height: '100%',
      }}
    >
      <CardContent>
        <Stack alignItems='flex-start' direction='row' justifyContent='space-between'>
          <Stack>
            <Typography sx={{ color: textColor, pr: 2 }}>{title}</Typography>
            <Typography variant='h4' data-testid={data_testid} sx={{ color: textColor }}>
              {value}
              {units && (
                <Typography component='span' sx={{ fontSize: '1rem', marginLeft: '4px', whiteSpace: 'nowrap' }}>
                  {units}
                </Typography>
              )}
            </Typography>
          </Stack>
          {avatar}
        </Stack>
      </CardContent>
    </Card>
  )
}

export default ValueChartComponent
